import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addBMLS, updateBMLS, loadPriceBMLS, getConfigMoDong, getID } from '../../../actions/buffmatlivestreamActions';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class taotientrinh extends Component {
	static propTypes =  {
		addBMLS: PropTypes.func.isRequired,
		updateBMLS: PropTypes.func.isRequired,
		getConfigMoDong: PropTypes.func.isRequired,
		getID: PropTypes.func.isRequired,
	};
	
	componentDidMount() {
		// this.props.loadPriceBMLS();
        this.props.getConfigMoDong('buffmatlivestream');
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	option_slmct = () => {
		let ar_option_slmct = [];

		for (let i = 50; i <= 3000; i += 50) {
			ar_option_slmct.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_slmct;
	}
	option_ctgdtmtvtt = (lsct) => {
		let ar_option_ctgdtmtvtt = [];

		// if (lsct.toString() === "1") {
			var i = 30;
			for (i; i < 60; i += 15) {
				ar_option_ctgdtmtvtt.push(
					<option value={i} key={i}>
						{i} phút
					</option>
				)
			}
			for (i = 60; i <= 300; i += 30) {
				ar_option_ctgdtmtvtt.push(
					<option value={i} key={i}>
						{i} phút
					</option>
				)
			}
		// } else {
			// ar_option_ctgdtmtvtt.push(
				// <option value="40" key="40">
					// 40 phút
				// </option>
			// )
		// }
		return ar_option_ctgdtmtvtt;
	}
	
    async getIDFacebook(t) {
        let e = null;
        if (t) {
            if (t.indexOf("facebook.com") < 0 && t.indexOf("fb.watch") < 0 && t.indexOf("fb.gg") < 0) e = t;
            else {
                var s = t.match(/live\/\?v=([0-9]+)/),
                    a = t.match(/videos\/([0-9]+)/),
                    i = t.match(/watch_permalink&v=([0-9]+)/),
                    n = t.match(/ref=sharing&v=([0-9]+)/),
                    o = t.match(/(.*)\/video.php\?v=([0-9]{8,})/),
                    r = t.match(/(.*)\/watch\/\?v=([0-9]{8,})/),
                    l = t.match(/(.*)\/watch\/live\/\?v=([0-9]{8,})/),
                    c = t.match(/(.*)?v=([0-9]{8,})/);
                if (s !== null) {
                    return s[1];
                } else if (a !== null) {
                    return a[1];
                } else if (i !== null) {
                    return i[1];
                } else if (n !== null) {
                    return n[1];
                } else if (o !== null) {
                    return o[2];
                } else if (r !== null) {
                    return r[2];
                } else if (l !== null) {
                    return l[2];
                } else if (c !== null) {
                    return c[2];
                } else {
					return await this.props.getID(t);
				}
            }
            return e
        }
    }
	
	onChange = async (e) => {
		var { taotientrinh } = this.props.buffmatlivestream;
		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: e.target.value
		};
		if (e.target.name === 'lvctm') {
			// var matches_idvideo = e.target.value.match(/videos\/[0-9]+/);
			// // console.log(matches_idvideo);
			// if (matches_idvideo != null) {
				// var new_taotientrinh = {
					// ...taotientrinh,
					// "lvctm": matches_idvideo[0].replace('videos/', '')
				// };
			// } else {
				// matches_idvideo = e.target.value.match(/v=[0-9]+/);
				// // console.log(matches_idvideo);
				// if (matches_idvideo != null) {
					// var new_taotientrinh = {
						// ...taotientrinh,
						// "lvctm": matches_idvideo[0].replace('v=', '')
					// };
				// }
			// }
			var new_taotientrinh = {
				...taotientrinh,
				"lvctm": await this.getIDFacebook(e.target.value)
			};
		} else if (e.target.name === "lsct") {
			if (e.target.value === "1") {
				var value = this.props.buffmatlivestream.price.buffmatlivestream.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
					// "slmct": "50",
					"tgdtm": "30"
				};
			}
			if (e.target.value === "2") {
				var value = this.props.buffmatlivestream.price.buffmatlivestream_sv2.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
					// "slmct": "50",
					"tgdtm": "30"
				};
			}
			if (e.target.value === "3") {
				var value = this.props.buffmatlivestream.price.buffmatlivestream_sv3.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
					// "slmct": "30",
					"tgdtm": "30"
				};
			}
			if (e.target.value === "4") {
				var value = this.props.buffmatlivestream.price.buffmatlivestream_sv4.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
					// "slmct": "30",
					"tgdtm": "30"
				};
			}
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
			};
		}
		this.props.updateBMLS(new_taotientrinh);
	}
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.buffmatlivestream;
		const id_user = this.props.auth.user.id;
		if (taotientrinh.lvctm === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'ID không được để trống',
			});
			return false;
		}
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user,
			"type_api": "Buff_view_livestrean"
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn buff "+taotientrinh.slmct+" Mắt trong "+taotientrinh.tgdtm+" Phút với giá "+taotientrinh.gtmtt+" Coin / Mắt? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addBMLS(new_taotientrinh);
			}
		})
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { lvctm, lsct, slmct, tgdtm, gc, gtmtt } = this.props.buffmatlivestream.taotientrinh;
		const { buffmatlivestream_sv1_mo_dong, buffmatlivestream_sv2_mo_dong, buffmatlivestream_sv3_mo_dong, buffmatlivestream_sv4_mo_dong } = this.props.buffmatlivestream;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_matlivestream_sv1 = '';
		var html_matlivestream_sv2 = '';
		var html_matlivestream_sv3 = '';
		var html_matlivestream_sv4 = '';
		if (typeof(listDataServicesInfo.facebook_buff_matlivestream_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_matlivestream_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_matlivestream_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_matlivestream_sv1) !== 'undefined') {
			html_matlivestream_sv1 = parse(listDataServicesInfo.facebook_buff_matlivestream_sv1.ghichu_sv);
			if (lsct.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_matlivestream_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_matlivestream_sv2) !== 'undefined') {
			html_matlivestream_sv2 = parse(listDataServicesInfo.facebook_buff_matlivestream_sv2.ghichu_sv);
			if (lsct.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_matlivestream_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_matlivestream_sv3) !== 'undefined') {
			html_matlivestream_sv3 = parse(listDataServicesInfo.facebook_buff_matlivestream_sv3.ghichu_sv);
			if (lsct.toString() === "3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_matlivestream_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_matlivestream_sv4) !== 'undefined') {
			html_matlivestream_sv4 = parse(listDataServicesInfo.facebook_buff_matlivestream_sv4.ghichu_sv);
			if (lsct.toString() === "4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_matlivestream_sv4.ghichu);
			}
		}
		
		var price = Math.round((slmct * tgdtm * gtmtt + Number.EPSILON) * 100) / 100;
		if (slmct == 30) {
			price = Math.round((50 * tgdtm * gtmtt + Number.EPSILON) * 100) / 100;
		}
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link video hoặc id_video cần tăng mắt (hệ thống tự động get IDVIDEO):</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lvctm" name="lvctm" placeholder="" onChange={this.onChange} value={lvctm} />
						<div className="alert alert-danger" style={{"marginTop": "5px"}}>
							<i className="fa fa-exclamation-triangle"></i> <strong>Lưu ý:</strong> Hiện tại dịch vụ lên ổn định theo từng máy chủ, giờ cao điểm có thể lên chậm!<br/>
							Do đó, bạn nên chọn các loại máy chủ phù hợp với mình để sử dụng!
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Chọn Máy Chủ cần tăng:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffmatlivestream.price.buffmatlivestream.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="loaiseeding_1" name="lsct" checked={lsct.toString() === "1"} disabled={this.props.buffmatlivestream.price.buffmatlivestream.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_1" >SV1 {html_matlivestream_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffmatlivestream.price.buffmatlivestream.prices_web} coin/mắt/phút</span> {buffmatlivestream_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffmatlivestream.price.buffmatlivestream_sv2.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="loaiseeding_2" name="lsct" checked={lsct.toString() === "2"} disabled={this.props.buffmatlivestream.price.buffmatlivestream_sv2.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_2" >SV2 {html_matlivestream_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffmatlivestream.price.buffmatlivestream_sv2.prices_web} coin/mắt/phút</span> {buffmatlivestream_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffmatlivestream.price.buffmatlivestream_sv3.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="3" type="radio" className="custom-control-input" id="loaiseeding_3" name="lsct" checked={lsct.toString() === "3"} disabled={this.props.buffmatlivestream.price.buffmatlivestream_sv3.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_3" >SV3 {html_matlivestream_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffmatlivestream.price.buffmatlivestream_sv3.prices_web} coin/mắt/phút</span> {buffmatlivestream_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffmatlivestream.price.buffmatlivestream_sv4.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="4" type="radio" className="custom-control-input" id="loaiseeding_4" name="lsct" checked={lsct.toString() === "4"} disabled={this.props.buffmatlivestream.price.buffmatlivestream_sv4.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_4" >SV4 {html_matlivestream_sv4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffmatlivestream.price.buffmatlivestream_sv4.prices_web} coin/mắt/phút</span> {buffmatlivestream_sv4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Số lượng mắt cần tăng:</label>
					<div className="col-sm-8">
						<select className="form-control" id="slmct" name="slmct" onChange={this.onChange} value={slmct} >
							<option value="30">20-30</option>
							{this.option_slmct()}
						</select>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Thời gian duy trì mắt:</label>
					<div className="col-sm-8">
						<select className="form-control" id="tgdtm" name="tgdtm" onChange={this.onChange} value={tgdtm} >
							{this.option_ctgdtmtvtt(lsct.toString())}
						</select>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea type="text" className="form-control" id="gc" name="gc" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" onChange={this.onChange} value={gc} ></textarea>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-12">
						<div className="card card-orange text-center">
							<div className="card-body">
								<p className="text-danger mt-2 mb-0"><b className="font-bold">Tổng tiền = (Số lượng mắt) x (Số phút) x (Giá tiền order mỗi mắt)</b></p>
							</div>
						</div>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="facebook_buff_matlivestream" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{slmct == 30 ? "20-30": slmct} Mắt</span> trong <span className="green bold">{tgdtm} Phút</span> với giá <span className="green bold">{gtmtt}</span> Coin / Mắt</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffmatlivestream: state.buffmatlivestream,
});
const mapDispatchToProps = { addBMLS, updateBMLS, loadPriceBMLS, getConfigMoDong, getID }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);