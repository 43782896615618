import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addBSR, updateBSR, getID, loadPriceBSR, getConfigMoDong } from '../../../actions/buffseedingreviewActions';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class taotientrinh extends Component {
	static propTypes =  {
		addBSR: PropTypes.func.isRequired,
		updateBSR: PropTypes.func.isRequired,
		getID: PropTypes.func.isRequired,
		loadPriceBSR: PropTypes.func.isRequired,
	};
	
	state = {
		"price_diff": '',
		showDescription: isMobile? false : true
	};
	
	componentDidMount() {
		// this.props.loadPriceBSR();
        this.props.getConfigMoDong();
	};
	
	handleChangeDateStart = date => {
		const { taotientrinh } = this.props.buffseedingreview;
		var new_taotientrinh = {
			...taotientrinh,
			EndDatebh: this.addDays(date, 7),
			startDatebh: date,
		};
		
		this.props.updateBSR(new_taotientrinh);
	}

	handleChangeDateEnd = date => {
		const { taotientrinh } = this.props.buffseedingreview;
		var new_taotientrinh = {
			...taotientrinh,
			EndDatebh: date
		};
		
		this.props.updateBSR(new_taotientrinh);
	}
	
	addDays = (date, days) => {
	  	var result = new Date(date);
	  	result.setDate(result.getDate() + days);
	  	return result;
	}
	
	option_lnc_dotuoi = () => {
		let ar_option_lnc_dotuoi = [];

		for (let i = 13; i <= 65; i++) {
			ar_option_lnc_dotuoi.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_lnc_dotuoi;
	}
	
	option_lnc_sobanbe = () => {
		let ar_option_lnc_sobanbe = [];

		for (let i = 100; i <= 5000; i+=100) {
			ar_option_lnc_sobanbe.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_lnc_sobanbe;
	}
	
	onChange = (e) => {
		if ((e.target.name === "lhibv") && (e.target.value.indexOf("facebook.com") > -1)) {
			if (e.target.value !== "") {
				const { taotientrinh } = this.props.buffseedingreview;
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: e.target.value
				};
				this.props.getID(new_taotientrinh);
			}
		} else if (e.target.name === "lsct") {
			const { taotientrinh } = this.props.buffseedingreview;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
				baohanh: 0,
				slct: 0
			};
			if (e.target.value == 'review_sv1') {
				new_taotientrinh = {
					...new_taotientrinh,
					gtmtt: this.props.buffseedingreview.price.review_sv1.prices_web
				};
			} else if (e.target.value == 'review') {
				new_taotientrinh = {
					...new_taotientrinh,
					gtmtt: this.props.buffseedingreview.price.review.prices_web
				};
			} else if (e.target.value == 'review_sv3') {
				new_taotientrinh = {
					...new_taotientrinh,
					gtmtt: this.props.buffseedingreview.price.review_sv3.prices_web
				};
			} else if (e.target.value == 'review_sv4') {
				new_taotientrinh = {
					...new_taotientrinh,
					gtmtt: this.props.buffseedingreview.price.review_sv4.prices_web
				};
			} else if (e.target.value == 'review_sv5') {
				new_taotientrinh = {
					...new_taotientrinh,
					gtmtt: this.props.buffseedingreview.price.review_sv5.prices_web
				};
			}
		} else if (e.target.name === "baohanh") {
			const { taotientrinh } = this.props.buffseedingreview;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			if (e.target.value == 1) {
				if (taotientrinh.lsct == 'review_sv1') {
					new_taotientrinh = {
						...new_taotientrinh,
						gtmtt: this.props.buffseedingreview.price.review_sv1.min_bh_web
					};
				} else if (taotientrinh.lsct == 'review') {
					new_taotientrinh = {
						...new_taotientrinh,
						gtmtt: this.props.buffseedingreview.price.review.min_bh_web
					};
				} else if (taotientrinh.lsct == 'review_sv3') {
					new_taotientrinh = {
						...new_taotientrinh,
						gtmtt: this.props.buffseedingreview.price.review_sv3.min_bh_web
					};
				} else if (taotientrinh.lsct == 'review_sv4') {
					new_taotientrinh = {
						...new_taotientrinh,
						gtmtt: this.props.buffseedingreview.price.review_sv4.min_bh_web
					};
				} else if (taotientrinh.lsct == 'review_sv5') {
					new_taotientrinh = {
						...new_taotientrinh,
						gtmtt: this.props.buffseedingreview.price.review_sv5.min_bh_web
					};
				}
			} else {
				if (taotientrinh.lsct == 'review_sv1') {
					new_taotientrinh = {
						...new_taotientrinh,
						gtmtt: this.props.buffseedingreview.price.review_sv1.prices_web
					};
				} else if (taotientrinh.lsct == 'review') {
					new_taotientrinh = {
						...new_taotientrinh,
						gtmtt: this.props.buffseedingreview.price.review.prices_web
					};
				} else if (taotientrinh.lsct == 'review_sv3') {
					new_taotientrinh = {
						...new_taotientrinh,
						gtmtt: this.props.buffseedingreview.price.review_sv3.prices_web
					};
				} else if (taotientrinh.lsct == 'review_sv4') {
					new_taotientrinh = {
						...new_taotientrinh,
						gtmtt: this.props.buffseedingreview.price.review_sv4.prices_web
					};
				} else if (taotientrinh.lsct == 'review_sv5') {
					new_taotientrinh = {
						...new_taotientrinh,
						gtmtt: this.props.buffseedingreview.price.review_sv5.prices_web
					};
				}
			}
		} else {
			const { taotientrinh } = this.props.buffseedingreview;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateBSR(new_taotientrinh);
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.buffseedingreview;
		const { money } = this.props.auth.user;
		var prices_web = this.props.buffseedingreview.price.prices_web;
		if (taotientrinh.baohanh == 1) {
			prices_web = this.props.buffseedingreview.price.min_bh_web;
		}
		
		if (taotientrinh.gtmtt === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chọn giá tiền mỗi tương tác',
			});
			return false;
		}
		if (taotientrinh.gtmtt < prices_web) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Giá tiền mỗi tương tác thấp nhất: '+prices_web+' Coin',
			});
			return false;
		}
		
		var review = [];
		var check_reviewlength = 0;
		if (taotientrinh.ndr.trim() !== "") {
			// var res1 = taotientrinh.ndr.trim().match(/[!@$%^&*;':\\/|?~"]/g);
			// var res2 = taotientrinh.ndr.trim().toLowerCase().match(/dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g);
			// if (res1 !== null) {
				// if (res1.length > 0) {
					// Swal.fire({
						// icon: 'error',
						// title: 'Lỗi',
						// html: 'Nội dung Review thứ 1 không được chứa ký tự đặc biệt: <span class="text-danger font-bold">' + res1[0] + '</span>',
					// });
					// return false;
				// }
			// }
			// if (res2 !== null) {
				// if (res2.length > 0) {
					// Swal.fire({
						// icon: 'error',
						// title: 'Lỗi',
						// html: 'Nội dung Review thứ 1 không được chứa ký tự đặc biệt: <span class="text-danger font-bold">' + res2[0] + '</span>',
					// });
					// return false;
				// }
			// }
			review = taotientrinh.ndr.trim().split('\n');
			// review.forEach(function(v, i){
				// if (v.length < 25) {
					// check_reviewlength = 1;
				// }
			// });
		}
		if (check_reviewlength === 1) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				html: 'Nội dung của một review tối thiểu phải 25 ký tự',
			});
			return false;
		}
		var additional_fee = 0;
		if (taotientrinh.lnc_battat === "1" && taotientrinh.lnc_namnu !== "3") {
			additional_fee += 20;
		}
		if (taotientrinh.lnc_battat === "1" && taotientrinh.lnc_dotuoi_tu !== "") {
			additional_fee += 20;
		}
		if (taotientrinh.lnc_battat === "1" && taotientrinh.lnc_sobanbe_tu !== "") {
			additional_fee += 20;
		}
		var final_price = Math.ceil((taotientrinh.gtmtt * parseInt(review.length)) + ((taotientrinh.gtmtt * parseInt(review.length)) * (additional_fee / 100)));
		var count_review = parseInt(review.length);
		if (taotientrinh.lsct == 'review_sv3' || taotientrinh.lsct == 'review_sv4') {
			count_review = taotientrinh.slct;
		}
		var final_price = Math.ceil((taotientrinh.gtmtt * count_review) + ((taotientrinh.gtmtt * count_review) * (additional_fee / 100)));
		
		if (money < final_price) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Không đủ tiền trong tài khoản',
			});
			return false;
		}
		if (taotientrinh.lnc_dotuoi_tu !== "" && parseInt(taotientrinh.lnc_dotuoi_tu) > parseInt(taotientrinh.lnc_dotuoi_den)) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Số tuổi bắt đầu phải nhỏ hơn số tuổi kết thúc',
			});
			return false;
		}
		if (taotientrinh.lnc_sobanbe_tu !== "" && parseInt(taotientrinh.lnc_sobanbe_tu) > parseInt(taotientrinh.lnc_sobanbe_den)) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Số bạn bè bắt đầu phải nhỏ hơn số bạn bè kết thúc',
			});
			return false;
		}
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user,
			"type_api": "review_fanpage"
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn buff "+count_review+" review với giá: "+taotientrinh.gtmtt+" Coin/1 review? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addBSR(new_taotientrinh);
			}
		})
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { lhibv, slct, baohanh, lnc_battat, lnc_namnu, lnc_dotuoi_tu, lnc_sobanbe_tu, gtmtt, ndr, lsct, startDatebh, EndDatebh } = this.props.buffseedingreview.taotientrinh;
		const { facebookbuffreviewcheckinfanpage_sv1_mo_dong, facebookbuffreviewcheckinfanpage_sv2_mo_dong, facebookbuffreviewcheckinfanpage_sv3_mo_dong, facebookbuffreviewcheckinfanpage_sv4_mo_dong, facebookbuffreviewcheckinfanpage_sv5_mo_dong } = this.props.buffseedingreview;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_danhgiacheckinpage_sv1 = '';
		var html_danhgiacheckinpage_sv2 = '';
		var html_danhgiacheckinpage_sv3 = '';
		var html_danhgiacheckinpage_sv4 = '';
		var html_danhgiacheckinpage_sv5 = '';
		if (typeof(listDataServicesInfo.facebook_buff_danhgiacheckinpage_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv1) !== 'undefined') {
			html_danhgiacheckinpage_sv1 = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv1.ghichu_sv);
			if (lsct.toString() === "review_sv1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv2) !== 'undefined') {
			html_danhgiacheckinpage_sv2 = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv2.ghichu_sv);
			if (lsct.toString() === "review") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv3) !== 'undefined') {
			html_danhgiacheckinpage_sv3 = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv3.ghichu_sv);
			if (lsct.toString() === "review_sv3") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv3.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv4) !== 'undefined') {
			html_danhgiacheckinpage_sv4 = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv4.ghichu_sv);
			if (lsct.toString() === "review_sv4") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv4.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv5) !== 'undefined') {
			html_danhgiacheckinpage_sv5 = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv5.ghichu_sv);
			if (lsct.toString() === "review_sv5") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_danhgiacheckinpage_sv5.ghichu);
			}
		}
		
		var prices_web = this.props.buffseedingreview.price.prices_web;
		if (baohanh == 1) {
			prices_web = this.props.buffseedingreview.price.min_bh_web;
		}
		
		var count_review = 0;
		if (ndr.trim() !== "") {
			count_review = ndr.trim().split('\n').length;
		}
		if (lsct == 'review_sv3' || lsct == 'review_sv4') {
			count_review = slct;
		}
		var additional_fee = 0;
		if (lnc_battat === "1" && lnc_namnu !== "3") {
			additional_fee += 20;
		}
		if (lnc_battat === "1" && lnc_dotuoi_tu !== "") {
			additional_fee += 20;
		}
		if (lnc_battat === "1" && lnc_sobanbe_tu !== "") {
			additional_fee += 20;
		}
		var price = Math.round(((gtmtt * count_review) + ((gtmtt * count_review) * (additional_fee / 100)) + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
    			<style>
					{"\
			        	.react-datepicker-wrapper{\
			          		width: 100%;\
			        	}\
			      	"}
		      	</style>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID Fanpage:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhibv" name="lhibv" placeholder="" onChange={this.onChange} value={lhibv} />
						<div className="alert alert-danger" style={{"marginTop": "5px"}}>
							{
								lsct.toString() === "review_sv1" || lsct.toString() === "review" || lsct.toString() === "review_sv5"?
									<Fragment>
										Hướng dẫn mở đánh giá (review page): <a href="https://i.imgur.com/IHP9Btg.png" target="_blank">Tại đây</a>
									</Fragment>
								: ""
							}
							{
								lsct.toString() === "review_sv3" || lsct.toString() === "review_sv4"?
									<Fragment>
										Muốn chạy checkin bạn chỉ cần thêm vị trí cho page là được, có thể lấy 1 tài khoản khác vào trang page thấy có mục tạo bài viết như hình ảnh (<a href="https://i.imgur.com/chemu8W.png" target="_blank">Tại đây</a>) là được
									</Fragment>
								: ""
							}
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại Seeding cần tăng:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffseedingreview.price.review_sv1.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="review_sv1" type="radio" className="custom-control-input" id="lsct_1" name="lsct" checked={lsct.toString() === "review_sv1"} disabled={this.props.buffseedingreview.price.review_sv1.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_1" > Tăng SV1 - <span style={{"color": "#ff8d00"}}>Đánh Giá Fanpage</span> {html_danhgiacheckinpage_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffseedingreview.price.review_sv1.prices_web} coin</span> {facebookbuffreviewcheckinfanpage_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffseedingreview.price.review.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="review" type="radio" className="custom-control-input" id="lsct_2" name="lsct" checked={lsct.toString() === "review"} disabled={this.props.buffseedingreview.price.review.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_2" > Tăng SV2 - <span style={{"color": "#ff8d00"}}>Đánh Giá Fanpage</span> {html_danhgiacheckinpage_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffseedingreview.price.review.prices_web} coin</span> {facebookbuffreviewcheckinfanpage_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffseedingreview.price.review_sv3.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="review_sv3" type="radio" className="custom-control-input" id="lsct_3" name="lsct" checked={lsct.toString() === "review_sv3"} disabled={this.props.buffseedingreview.price.review_sv3.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_3" > Tăng SV3 - <span style={{"color": "#ff8d00"}}>Checkin Fanpage</span> {html_danhgiacheckinpage_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffseedingreview.price.review_sv3.prices_web} coin</span> {facebookbuffreviewcheckinfanpage_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffseedingreview.price.review_sv4.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="review_sv4" type="radio" className="custom-control-input" id="lsct_4" name="lsct" checked={lsct.toString() === "review_sv4"} disabled={this.props.buffseedingreview.price.review_sv4.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_4" > Tăng SV4 - <span style={{"color": "#ff8d00"}}>Checkin Fanpage</span> {html_danhgiacheckinpage_sv4} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffseedingreview.price.review_sv4.prices_web} coin</span> {facebookbuffreviewcheckinfanpage_sv4_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffseedingreview.price.review_sv5.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="review_sv5" type="radio" className="custom-control-input" id="lsct_5" name="lsct" checked={lsct.toString() === "review_sv5"} disabled={this.props.buffseedingreview.price.review_sv5.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_5" > Tăng SV5 - <span style={{"color": "#ff8d00"}}>Đánh Giá Fanpage</span> {html_danhgiacheckinpage_sv5} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffseedingreview.price.review_sv5.prices_web} coin</span> {facebookbuffreviewcheckinfanpage_sv5_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Chế độ bảo hành:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<select onChange={this.onChange} value={baohanh.toString()} name="baohanh" className="form-control">
								<option value="0">Không bảo hành</option>
								<option value="1">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
							</select>
						</div>
						
						{ baohanh == 1 ?
							<div className="form-group">
								<div className="row">
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày bắt đầu</p>
										<DatePicker className="form-control w-100"
										showTimeSelect minDate={new Date()}
										selected={startDatebh}
										onChange={this.handleChangeDateStart} />
									</div>
									<div className="col-md-6">
										<p className="mb-0">Chọn ngày kết thúc</p>
										<DatePicker className="form-control"
										showTimeSelect minDate={startDatebh}
										maxDate={this.addDays(startDatebh, 7)}
										selected={EndDatebh}
										onChange={this.handleChangeDateEnd} />
									</div>
								</div>
							</div>
							: ''
						}
					</div>
				</div>
				{/* <div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Lọc nâng cao:</label>
					<div className="col-sm-8">
						<div className="row">
							<div className="col-md-6 mt-2">
								<button type="button" name="lnc_battat" value="1" className={"btn btn-block btn-rounded  " + (lnc_battat === "1" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.onChange} >Bật</button>
							</div>
							<div className="col-md-6 mt-2">
								<button type="button" name="lnc_battat" value="2" className={"btn btn-block btn-rounded  " + (lnc_battat === "2" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.onChange} >Tắt</button>
							</div>
						</div>
						<h6 className="mb-0 mt-3 font-14" style={ lnc_battat === "1" ? {"display":"block"} : {"display":"none"} }>
							Hệ thống sẽ <span className="bold green">thu phí thêm 20%</span> cho <span className="bold green">1 lựa chọn nâng cao</span>
						</h6>
						<div className="card card-gray mt-2" style={ lnc_battat === "1" ? {"display":"block"} : {"display":"none"} }>
							<div className="card-body">
								<div className="row align-items-center">
									<div className="col-md-4">
										<h6 className="bold mb-0">Giới tính:</h6>
									</div>
									<div className="col-md">
										<div className="row">
											<div className="col-md-4 mt-2">
												<button type="submit" name="lnc_namnu" value="1" className={"btn btn-block btn-rounded  " + (lnc_namnu === "1" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.onChange} >Nam</button>
											</div>
											<div className="col-md-4 mt-2">
												<button type="submit" name="lnc_namnu" value="2" className={"btn btn-block btn-rounded  " + (lnc_namnu === "2" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.onChange} >Nữ</button>
											</div>
											<div className="col-md-4 mt-2">
												<button type="submit" name="lnc_namnu" value="3" className={"btn btn-block btn-rounded  " + (lnc_namnu === "3" ? "btn-hero-primary" : "btn-hero-light")} onClick={this.onChange} >Tất Cả</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row align-items-center mt-3">
								<div className="col-md-4">
									<h6 className="bold mb-0">Độ tuổi:</h6>
								</div>
								<div className="col-md">
									<div className="row align-items-center">
										<div className="col-5">
											<div className="input-group">
												<select className="form-control custom-select select-light" id="lnc_dotuoi_tu" name="lnc_dotuoi_tu" onChange={this.onChange} >
													<option value="">Không lọc độ tuổi</option>
													{this.option_lnc_dotuoi()}
												</select>
											</div>
											</div>
												<div className="col-2">
													<h6 className="bold mb-0">đến</h6>
												</div>
												<div className="col-5">
													<div className="input-group">
														<select className="form-control custom-select select-light" id="lnc_dotuoi_den" name="lnc_dotuoi_den" onChange={this.onChange} disabled={lnc_dotuoi_tu === "" ? 'disabled' : ''} >
															<option value=""></option>
															{this.option_lnc_dotuoi()}
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="row align-items-center mt-3">
										<div className="col-md-4">
											<h6 className="bold mb-0">Số bạn bè:</h6>
										</div>
										<div className="col-md">
										<div className="row align-items-center">
											<div className="col-5">
												<div className="input-group">
													<select className="form-control custom-select select-light" id="lnc_sobanbe_tu" name="lnc_sobanbe_tu" onChange={this.onChange} >
														<option value="">Không lọc bạn bè</option>
														{this.option_lnc_sobanbe()}
													</select>
												</div>
											</div>
											<div className="col-2">
												<h6 className="bold mb-0">đến</h6>
											</div>
											<div className="col-5">
												<div className="input-group">
													<select className="form-control custom-select select-light" id="lnc_sobanbe_den" name="lnc_sobanbe_den" onChange={this.onChange} disabled={lnc_sobanbe_tu === "" ? 'disabled' : ''} >
														<option value=""></option>
														{this.option_lnc_sobanbe()}
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				{
					lsct == 'review_sv3' || lsct == 'review_sv4' ?
						<div className="form-group row">
							<label className="col-sm-4 col-form-label" htmlFor="">Số lượng:</label>
							<div className="col-sm-8">
								<input type="number" className="form-control" id="slct" name="slct" placeholder="" onChange={this.onChange} value={slct} onKeyPress={this.onKeyPress} />
							</div>
						</div>
					: ''
				}
				<div className="form-group row">
					<div className="col-sm-12">
						<label className="mr-2 mt-3">Nhập nội dung Review (<label className="font-bold font-13">Mỗi dòng tương đương với 1 Review</label>):</label>
						<span className="badge badge-primary text-white py-1">Số lượng: {count_review}</span>
						<h6><span className="badge badge-danger text-white py-2">Lưu ý: Nội dung của một review tối thiểu phải 25 ký tự</span></h6>
						<textarea rows="10" placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 lần seeding" className="form-control input-gray" id="ndr" name="ndr" onChange={this.onChange} ></textarea>
					</div>
				</div>
				{/* <div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor=""></label>
					<div className="col-sm-8">
						<div className="card card-orange">
							<div className="card-body py-2">
								<h6 className="font-14 mb-0">
									<label className="mb-0 ml-1 text-danger font-bold mr-1"> Giá thấp nhất: {prices_web} Coin</label>
								</h6>
								<h6 className="font-14 mb-0 text-danger font-bold">Lưu ý: Nên buff dư thêm 20 - 30% trên tổng số lượng để tránh tụt.</h6>
							</div>
						</div>
					</div>
				</div> */}
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Giá tiền mỗi tương tác:</label>
					<div className="col-sm-8">
						<input type="number" className="form-control" id="gtmtt" name="gtmtt" placeholder="" onChange={this.onChange} value={gtmtt} onKeyPress={this.onKeyPress} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="facebook_buff_danhgiacheckinpage" giatien={gtmtt} baohanh={parseInt(baohanh)} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{count_review} review</span> với giá <span className="green bold">{price}</span> Coin</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffseedingreview: state.buffseedingreview,
});
const mapDispatchToProps = { addBSR, updateBSR, getID, loadPriceBSR, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);