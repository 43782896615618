import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addFVMLSV, updateFVMLSV, loadPriceFVMLSV } from '../../../actions/Facebookvipmatlivestreamv2Actions';
import { add, updateFbVip, getIdFbVip, getConfigMoDong } from '../../../actions/FbvipActions';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		addFVMLSV: PropTypes.func.isRequired,
		updateFVMLSV: PropTypes.func.isRequired,
		loadPriceFVMLSV: PropTypes.func.isRequired,
		add: PropTypes.func.isRequired,
		updateFbVip: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	option_goivip = () => {
		let ar_option_goivip = [];

		for (let i = 50; i <= 200; i+=50) {
			ar_option_goivip.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_goivip;
	}
	
	option_tgdtm = (lsct) => {
		let ar_option_tgdtm = [];

		var i = 30;
		for (i; i < 60; i += 15) {
			ar_option_tgdtm.push(
				<option value={i} key={i}>
					{i} phút
				</option>
			)
		}
		for (i = 60; i <= 240; i += 30) {
			ar_option_tgdtm.push(
				<option value={i} key={i}>
					{i} phút
				</option>
			)
		}
		return ar_option_tgdtm;
	}
	
	componentDidMount() {
		// this.props.loadPriceFVMLSV();
        this.props.getConfigMoDong('facebookvipmatlivestreamv2');
	};
	
	isNumber(n) {
		return !isNaN(parseFloat(n)) && !isNaN(n - 0)
	}
	onChange = (e) => {
		const { taotientrinh } = this.props.Facebookvipmatlivestreamv2;
		if ((e.target.name === "idfb") && (e.target.value.indexOf("facebook.com") > -1 || (this.isNumber(e.target.value) && e.target.value.length > 6))) {
			if (e.target.value !== "") {
				var matches_uid = e.target.value.match(/id=(.*?)&/);
        		if (matches_uid != null) {
					var new_taotientrinh = {
						...taotientrinh,
						"idfb": matches_uid[1]
					};
        		} else {
					var matches_uid = e.target.value.match(/id=(.*)/);
					if (matches_uid != null) {
						var new_taotientrinh = {
							...taotientrinh,
							"idfb": matches_uid[1]
						};
					} else {
						var new_taotientrinh = {
							...taotientrinh,
							[e.target.name]: e.target.value
						};
						this.props.getIdFbVip('facebookvipmatlivestreamv2', new_taotientrinh);
					}
        		}
			}
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateFbVip('facebookvipmatlivestreamv2', new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Facebookvipmatlivestreamv2;
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user
		};
		if (taotientrinh.idfb === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'ID FB cần chạy không được để trống',
			});
			return false;
		}
		var inck = taotientrinh.inck.split("|");
		if (inck.length > 6) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Tối đa chỉ 6 id nhóm!',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá Rate/Mắt/Ngày/1 LIVE: "+taotientrinh.gtmtt+" Coin? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.add('facebookvipmatlivestreamv2', new_taotientrinh);
			}
		})
	}
	
	onChangeLoaiSeeding = (e) => {
		let valueChange = e.target.value;
		const { taotientrinh } = this.props.Facebookvipmatlivestreamv2;
		var giatien = 0;
		if (valueChange == '1') {
			giatien = this.props.Facebookvipmatlivestreamv2.datapricebuffMat.facebookvipmatlivestreamv2.prices_web
		// } else if(valueChange == '2'){
			// giatien = this.props.Facebookvipmatlivestreamv2.datapricebuffMat.facebookvipmatlivestreamv2_sv2.prices_web
		// } else if(valueChange == '3'){
			// giatien = this.props.Facebookvipmatlivestreamv2.datapricebuffMat.facebookvipmatlivestreamv2_sv3.prices_web
		}

		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: e.target.value,
			gtmtt: giatien
		};
		this.props.updateFbVip('facebookvipmatlivestreamv2', new_taotientrinh);
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { idfb, usernamefb, lsct, goivip, tgdtm, ghltmn, time, timelive, tdnm, ln, inck, gtmtt } = this.props.Facebookvipmatlivestreamv2.taotientrinh;
 		const { facebookvipmatlivestream_new_sv1_mo_dong } = this.props.Facebookvipmatlivestreamv2;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_livestreamnew_sv1 = '';
		if (typeof(listDataServicesInfo.facebook_vip_livestreamnew_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_vip_livestreamnew_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_vip_livestreamnew_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_vip_livestreamnew_sv1) !== 'undefined') {
			html_livestreamnew_sv1 = parse(listDataServicesInfo.facebook_vip_livestreamnew_sv1.ghichu_sv);
			if (lsct.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_livestreamnew_sv1.ghichu);
			}
		}
		
		var price = Math.round((goivip * time * timelive * gtmtt * ghltmn + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID Profile cài VIP:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="idfb" name="idfb" placeholder="Vui Lòng Nhập Chính Xác IDFB" onChange={this.onChange} value={idfb} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Tên Người Dùng:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="usernamefb" name="usernamefb" placeholder="Nhập Tên Nick FB" onChange={this.onChange} value={usernamefb} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Chọn Server VIP:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.Facebookvipmatlivestreamv2.datapricebuffMat.facebookvipmatlivestreamv2.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChangeLoaiSeeding} value="1" type="radio" className="custom-control-input" id="loaiseeding_1" name="lsct" checked={lsct.toString() === "1"} disabled={this.props.Facebookvipmatlivestreamv2.datapricebuffMat.facebookvipmatlivestreamv2.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_1" >SV1 {html_livestreamnew_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.Facebookvipmatlivestreamv2.datapricebuffMat.facebookvipmatlivestreamv2.prices_web} coin/mắt/ngày</span> {facebookvipmatlivestream_new_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-12">
						<div className="form-group">
							<label className="mt-2" htmlFor="">
								Chọn Gói VIP:
							</label>
							<select className="form-control" id="goivip" name="goivip" onChange={this.onChange} value={goivip} >
								{this.option_goivip()}
							</select>
						</div>
					</div>
					{/* <div className="col-md-6">
						<div className="form-group">
							<label className="mt-2" htmlFor="">
								Thời gian duy trì mắt:
							</label>
							<select className="form-control" id="tgdtm" name="tgdtm" onChange={this.onChange} value={tgdtm} >
								{this.option_tgdtm()}
							</select>
						</div>
					</div> */}
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<div className="form-group">
							<label className="mt-2" htmlFor="">
								Thời gian sử dụng:
							</label>
							<select className="form-control" id="time" name="time" onChange={this.onChange} value={time} >
								<option value="7">7 ngày</option>
								<option value="10">10 ngày</option>
								<option value="15">15 ngày</option>
								<option value="30">30 ngày</option>
							</select>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group">
							<label className="mt-2" htmlFor="">
								Giới Hạn Live Trong 1 Ngày:
							</label>
							<select className="form-control" id="ghltmn" name="ghltmn" onChange={this.onChange} value={ghltmn} >
								<option value="1">1 Live / 1 Ngày  (Giá x 1)</option>
								<option value="1.5">2 Live / 1 Ngày  (Giá x 1.5)</option>
								<option value="2">3 Live / 1 Ngày  (Giá x 2)</option>
								<option value="4">6 Live / 1 Ngày  (Giá x 4)</option>
							</select>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group">
							<label className="mt-2" htmlFor="">
								Thời gian tối đa cho 1 livestream:
							</label>
							<select className="form-control" id="timelive" name="timelive" onChange={this.onChange} value={timelive} >
								<option value="1">1,5 Giờ (Giá x 1)</option>
								<option value="1.5">3 Giờ (Giá x 1.5)</option>
								<option value="2">4 Giờ (Giá x 2)</option>
							</select>
						</div>
					</div>
				</div>
				<div className="row mt-2">
				    <div className="col-12 bold">
				        <div className="card card-orange">
				        	<div className="card-body py-2">
				        		<h4 className="text-center mb-0 text-danger">Tổng tiền của gói vip sẽ = (Gói VIP) x (Thời gian sử dụng) x (Giá tiền/mắt/ngày)</h4>
			        		</div>
		        		</div>
				    </div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Tùy Chọn Live:
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<select className="form-control custom-select select-light" id="ln" name="ln" onChange={this.onChange} value={ln} >
								<option value="1">Trang cá nhân, Page</option>
								<option value="2">Nhóm</option>
								<option value="3">Cả (trang cá nhân, Page) và nhóm</option>
							</select>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">ID Nhóm Công Khai (ví dụ:213|2412|251|192|146|122) (tối đa 6 id nhóm):</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="inck" name="inck" placeholder=" ví dụ: 2332|1213|1212|1232|1321|213 đây là 6 id nhóm!" onChange={this.onChange} value={inck} disabled={ln == 2 || ln == 3 ? '' : 'disabled'}/>
					</div>
				</div>
				{/* <div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea style={{"width":"100%"}} rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div> */}
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="facebook_vip_livestreamnew" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá Rate/Mắt/Ngày/1 LIVE: <span className="green">{gtmtt} Coin</span></span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookvipmatlivestreamv2: state.Facebookvipmatlivestreamv2,
});
const mapDispatchToProps = { addFVMLSV, updateFVMLSV, loadPriceFVMLSV, add, updateFbVip, getIdFbVip, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);