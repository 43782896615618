import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, NavLink, Redirect } from "react-router-dom";
import NumberFormat from 'react-number-format';
import Admin1 from '../../icon/admin1.png';
import Users1 from '../../icon/users1.png';
import { BiComment, BiMagnet, BiWallet } from "react-icons/bi";
import { BsReply } from "react-icons/bs";
import { TiBatteryFull } from "react-icons/ti";
import { FaRegUser } from "react-icons/fa";
import { logout } from '../../actions/authActions';

class sitecontent extends Component {
	onClickDangxuat = e => {
		e.preventDefault();
		this.props.logout();
	};
	render() {
		const { id, username, money, facebook_id, type, notes, tien_datieu, tien_nap } = this.props.auth.user;
		// var ava = "https://graph.facebook.com/" + facebook_id + "/picture?width=100&amp;height=100";
		var ava = Users1;
		if (type == 1) {
			ava = Admin1;
		}
		return (
			<Fragment>
				{/* <div>
					<div className="card card-infor" style={{"boxShadow": "0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07)", "marginTop": "20px"}}>
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col-auto pr-0">
									<a href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="profile-pic">
										<img src={ava} width="160" alt="user" className="img-avatars rounded-circle border" />
									</a>
								</div>
								<div className="col text-left">
									<div className="row align-items-center mt-1">
										<div className="col">
											<h6 className="bold font-22 mb-0"><span>{ username }</span></h6>
										</div>
										<div className="col-auto">
											<h6 className="mb-0 font-14 bt-green user-level">
												{notes}
											</h6>
										</div>
									</div>
									<div className="row align-items-center mt-1">
										<div className="col">
											<h6 className="bold font-22 mb-0 green"><span><NumberFormat value={ money } thousandSeparator={true} suffix=" Coin" displayType={'text'} /></span></h6>
										</div>
										<div className="col-auto">
											<h6 className="mb-0 font-14 user-discount"> Giảm 0% </h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				<div className="col-md-12 homepage-cus" style={{"padding": "1.75rem 0rem 0px"}}>
					<div className="card mb-4">
						<div className="d-flex flex-row">
							<a href="/info-user" aria-current="page" className="d-flex nuxt-link-exact-active nuxt-link-active">
								<img src={"https://avatar.oxro.io/avatar.svg?background=random&name="+username} alt="le an" width="95" className="img-thumbnail list-thumbnail rounded-circle align-self-center m-4 mw-85" />
							</a>
							<div className="d-flex flex-grow-1 min-width-zero">
								<div className="pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
									<div className="min-width-zero">
										<a href="/" aria-current="page" className="nuxt-link-exact-active nuxt-link-active">
											<h6 className="mb-1 card-subtitle truncate"> {username} </h6>
										</a>
										<p className="text-muted mb-2"> <NumberFormat value={ money } displayType={'text'} thousandSeparator={true} suffix={' coin'} /> </p>
										<button type="button" className="btn btn-outline-primary btn-xs">{notes}</button>
									</div>
								</div>
							</div>
							<div className="d-flex min-width-zero">
								<div className="pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
									<div className="min-width-zero">
										<a href="/info-user" className="infor-out-cus" style={{"marginRight": "15px"}}>
											<i className="far fa-fw fa-user mr-1"></i>Thông tin
										</a>
										<br/>
										<a href="#" onClick={this.onClickDangxuat} className="infor-out-cus" style={{"marginRight": "15px"}}>
											<i className="far fa-fw fa-arrow-alt-circle-left mr-1"></i>
											Đăng xuất
										</a>
										<br/>
										<br/>
										<br/>
									</div>
								</div>
							</div>
						</div>
						<div className="card-body py-0">
							<div className="row">
								<div className="col-6">
									<div className="d-flex flex-row align-items-center mb-1">
										{/* <FaRegUser style={{"fontSize": "52px"}}/> */}
										<img src={process.env.PUBLIC_URL + '/images/icon/Male-icon.png'}  width="52" height="52" />
										<div className="pl-3 pt-2 pr-2 pb-2">
											<div className="list-item-heading font-weight-bold text-nowrap"> {id} </div>
											<div className="text-muted"> Mã khách hàng </div>
										</div>
									</div>
								</div>
								<div className="col-6">
									<div className="d-flex flex-row align-items-center mb-1">
										{/* <BiWallet style={{"fontSize": "52px"}}/> */}
										<img src={process.env.PUBLIC_URL + '/images/icon/Wallet-icon.png'}  width="52" height="52" />
										<div className="pl-3 pt-2 pr-2 pb-2">
											<div className="list-item-heading font-weight-bold text-nowrap"> <NumberFormat value={ money } displayType={'text'} thousandSeparator={true} suffix={' coin'} /> </div>
											<div className="text-muted"> Số dư </div>
										</div>
									</div>
								</div>
								<div className="col-6">
									<div className="d-flex flex-row align-items-center mb-1">
										{/* <TiBatteryFull style={{"fontSize": "52px"}}/> */}
										<img src={process.env.PUBLIC_URL + '/images/icon/Battery-100-icon.png'}  width="52" height="52" />
										<div className="pl-3 pt-2 pr-2 pb-2">
											<div className="list-item-heading font-weight-bold text-nowrap"> <NumberFormat value={ tien_datieu } displayType={'text'} thousandSeparator={true} suffix={' coin'} /> </div>
											<div className="text-muted"> Số tiền đã tiêu </div>
										</div>
									</div>
								</div>
								<div className="col-6">
									<div className="d-flex flex-row align-items-center mb-1">
										{/* <BiMagnet style={{"fontSize": "52px"}}/> */}
										<img src={process.env.PUBLIC_URL + '/images/icon/Ustream-icon.png'}  width="52" height="52" />
										<div className="pl-3 pt-2 pr-2 pb-2">
											<div className="list-item-heading font-weight-bold text-nowrap"> <NumberFormat value={ tien_nap } displayType={'text'} thousandSeparator={true} suffix={' coin'} /> </div>
											<div className="text-muted"> Số tiền đã nạp </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
});
const mapDispatchToProps = { logout }

export default connect(mapStateToProps, mapDispatchToProps)(sitecontent);