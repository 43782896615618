import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, NavLink, Redirect } from "react-router-dom";
import { changeSidebar, changeSubSidebar, changeOpen, getDataFeature, getDataSticker, getSevicesInfo } from "../actions/authActions";
import { isMobile } from 'react-device-detect';
import iconTiktok from '../icon/tiktok.svg';
import iconShopee from '../icon/shopee.svg';
import parse from 'html-react-parser';
import NumberFormat from 'react-number-format';
import { FcHome, FcMoneyTransfer, FcSimCard, FcComboChart, FcOnlineSupport, FcShop, FcFeedback, FcBusinessman, FcStatistics } from "react-icons/fc";
import { AiFillLike, AiOutlineLike, AiFillHeart, AiOutlineFieldTime, AiFillTool, AiFillFlag, AiOutlineShop, AiFillDislike } from "react-icons/ai";
import { RiVipFill, RiHeartAddFill, RiAdvertisementFill, RiSimCard2Fill } from "react-icons/ri";
import { GiThreeFriends, GiDeathSkull, GiRobotAntennas, GiBurningEye } from "react-icons/gi";
import { FaUserFriends, FaUserPlus, FaRobot, FaComments, FaReply, FaUsers, FaCookieBite, FaMapMarkedAlt, FaHandPointer } from "react-icons/fa";
import { BiWorld, BiRename, BiSearchAlt, BiCoinStack } from "react-icons/bi";
import { ImEyePlus, ImShare2 } from "react-icons/im";
import { MdMessage, MdReportProblem, MdPhoneInTalk } from "react-icons/md";
import { GoPlug } from "react-icons/go";
import { BsMusicNote } from "react-icons/bs";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { FiNavigation } from "react-icons/fi";
import Admin1 from '../icon/admin1.png';
import Users1 from '../icon/users1.png';

class Navbar extends Component {
	
	componentDidMount(){
		try {
			if (localStorage.getItem("listDataFeature") == null) {
				this.props.getDataFeature(9999,'');
			} else {
				var listDataFeature = JSON.parse(localStorage.getItem("listDataFeature"));
				var checktime = {checktime:0};
				checktime = listDataFeature[listDataFeature.length - 1];
				if (typeof checktime.checktime === 'undefined' || checktime.checktime == 0 || (parseInt(Date.now() / 1000) - checktime.checktime > 3600)) {
					this.props.getDataFeature(9999,'');
				}
			}
		} catch(e) {
			this.props.getDataFeature(9999,'');
		}
		try {
			if (localStorage.getItem("listStickerPackage") == null) {
				this.props.getDataSticker();
			} else {
				var listStickerPackage = JSON.parse(localStorage.getItem("listStickerPackage"));
				var checktime = {checktime:0};
				checktime = listStickerPackage[listStickerPackage.length - 1];
				if (typeof checktime.checktime === 'undefined' || checktime.checktime == 0 || (parseInt(Date.now() / 1000) - checktime.checktime > 3600)) {
					this.props.getDataSticker();
				}
			}
		} catch(e) {
			this.props.getDataSticker();
		}
		try {
			if (localStorage.getItem("listDataServicesInfo") == null) {
				this.props.getSevicesInfo();
			} else {
				var listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
				var checktime = {checktime:0};
				checktime = listDataServicesInfo.checktime;
				if (typeof checktime.checktime === 'undefined' || checktime.checktime == 0 || (parseInt(Date.now() / 1000) - checktime.checktime > 900)) {
					this.props.getSevicesInfo();
				}
			}
		} catch(e) {
			this.props.getSevicesInfo();
		}
    }
	
	state = {
		isOpenFbBuff: this.props.auth.isOpenFbBuff,
		isOpenFbVip: this.props.auth.isOpenFbVip,
		isOpenFbKhac: this.props.auth.isOpenFbKhac,
		tab_active: 'tab_trangchu',
	}
	
	onClick = (type, isOpen) => {
		if (type === 'buff') {
			this.props.changeOpen("FbBuff", !isOpen);
		}
		if (type === 'vip') {
			this.props.changeOpen("FbVip", !isOpen);
		}
		if (type === 'khac') {
			this.props.changeOpen("FbKhac", !isOpen);
		}
		if (type === 'main_facebook') {
			this.props.changeOpen("Fb", !isOpen);
		}
		if (type === 'main_facebook_bot') {
			this.props.changeOpen("FbBot", !isOpen);
		}
		if (type === 'main_instagram') {
			this.props.changeOpen("Instagram", !isOpen);
		}
		if (type === 'main_youtube') {
			this.props.changeOpen("Youtube", !isOpen);
		}
		if (type === 'main_shopee') {
			this.props.changeOpen("Shopee", !isOpen);
		}
		if (type === 'main_tiktok') {
			this.props.changeOpen("Tiktok", !isOpen);
		}
		if (type === 'main_google') {
			this.props.changeOpen("Google", !isOpen);
		}
		if (type === 'main_telegram') {
			this.props.changeOpen("Telegram", !isOpen);
		}
		if (type === 'main_facebook_adbreaks') {
			this.props.changeOpen("Fbadbreaks", !isOpen);
		}
		if (type === 'main_twitter') {
			this.props.changeOpen("Twitter", !isOpen);
		}
		if (type === 'close_all') {
			this.props.changeOpen("CloseAll", false);
		}
	}
	
	onClickSidebar = (isOpen) => {
		this.props.changeSidebar(!isOpen);
	}
	
	onClickSubSidebar = (isOpen) => {
		this.props.changeSubSidebar(!isOpen);
	}
	
	onClickTabActive = (data) => {
		this.setState({
			tab_active: data
		});
	}
	
	render() {
		var listDataFeature = {};
		try {
			if (localStorage.getItem("listDataFeature") != null) {
				listDataFeature = JSON.parse(localStorage.getItem("listDataFeature"));
			} else {
				listDataFeature = this.props.adminpage.listDataFeature;
			}
		} catch(e) {
			listDataFeature = []
		}
		// const { listDataFeature } = this.props.adminpage;
		var obj = {};
		obj['/tool/Bufflikecommentshare'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikecommentshare_like'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikecommentshare_comment'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikecommentshare_likecomment'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikecommentshare_share'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Buffsub'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffsub_slow'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffsub_sale'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikefanpage'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Bufflikefanpagesale'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/Buffsubfanpage'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffsublikev2'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buff-seeding-review'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookbuffview'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffviewstory'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffmatlivestream'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffgroup'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffgroupsharelive'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/vip-comment-seeding-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/vip-seeding-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookvipcomment'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookvipviewvideo'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookvipmatlivestream'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookvipmatlivestreamv2'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookbotv2'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookbotlovestory'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookbotcmt'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookbotreplyinboxcho'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookvipbotlive'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebooklocbanbekhongtuongtac'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookchocbanbe'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buy_proxy'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buff-seeding-like-instagram-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buff-seeding-sub-instagram-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buff-seeding-comment-instagram-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buff-seeding-view-instagram-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-view'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-gioxem'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-like'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-dislike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-tichnghesi'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-likecomment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-comment'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/youtube-sub'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbuff'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbuffcmt'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbufflike'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbuffview'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbuffshare'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokbuffmat'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokviplike'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/tiktokvipview'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/shopee'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/shopeeseedinglivestream'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/shopeeviplivestream'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/shopeetim'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/ho-tro-nhan-tin'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/ho-tro-cuoc-goi'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/emailtouid'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/thuesim'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/ripacc'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/muabanfanpagegroup'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/muafanpage'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/bufflikesubcomment'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/instagramviplike'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/reportyoutube'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/renamefanpage'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/buffgrouptele'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/twitterlike'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/twitterfollow'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookadbreaksgioxem'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookadbreakstuongtac'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/facebookadbreaksviewdexuat'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/googlemap'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		obj['/tool/vip-like-group'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="Hoạt động"></i>');
		listDataFeature.map(function(value,index){
			if (value.link === '/tool/Bufflikecommentshare') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikecommentshare'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikecommentshare'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikecommentshare_like') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikecommentshare_like'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikecommentshare_like'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikecommentshare_comment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikecommentshare_comment'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikecommentshare_comment'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikecommentshare_likecomment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikecommentshare_likecomment'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikecommentshare_likecomment'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikecommentshare_share') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikecommentshare_share'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikecommentshare_share'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Buffsub') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Buffsub'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Buffsub'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffsub_slow') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffsub_slow'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffsub_slow'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffsub_sale') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffsub_sale'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffsub_sale'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikefanpage') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikefanpage'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikefanpage'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Bufflikefanpagesale') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Bufflikefanpagesale'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Bufflikefanpagesale'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/Buffsubfanpage') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/Buffsubfanpage'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/Buffsubfanpage'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffsublikev2') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffsublikev2'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffsublikev2'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buff-seeding-review') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buff-seeding-review'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buff-seeding-review'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookbuffview') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookbuffview'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookbuffview'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffviewstory') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffviewstory'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffviewstory'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffmatlivestream') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffmatlivestream'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffmatlivestream'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffgroup') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffgroup'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffgroup'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffgroupsharelive') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffgroupsharelive'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffgroupsharelive'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/vip-like-clone') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/vip-like-clone'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/vip-like-clone'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/vip-like-group') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/vip-like-group'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/vip-like-group'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/vip-comment-seeding-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/vip-comment-seeding-order'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/vip-comment-seeding-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/vip-seeding-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/vip-seeding-order'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/vip-seeding-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookvipcomment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookvipcomment'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookvipcomment'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookvipviewvideo') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookvipviewvideo'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookvipviewvideo'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookvipmatlivestream') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookvipmatlivestream'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookvipmatlivestream'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookvipmatlivestreamv2') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookvipmatlivestreamv2'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookvipmatlivestreamv2'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookbotv2') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookbotv2'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookbotv2'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookbotlovestory') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookbotlovestory'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookbotlovestory'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookbotcmt') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookbotcmt'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookbotcmt'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookbotreplyinboxcho') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookbotreplyinboxcho'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookbotreplyinboxcho'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookvipbotlive') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookvipbotlive'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookvipbotlive'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebooklocbanbekhongtuongtac') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebooklocbanbekhongtuongtac'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebooklocbanbekhongtuongtac'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookchocbanbe') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookchocbanbe'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookchocbanbe'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buy_proxy') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buy_proxy'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buy_proxy'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buff-seeding-like-instagram-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buff-seeding-like-instagram-order'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buff-seeding-like-instagram-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buff-seeding-sub-instagram-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buff-seeding-sub-instagram-order'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buff-seeding-sub-instagram-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buff-seeding-comment-instagram-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buff-seeding-comment-instagram-order'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buff-seeding-comment-instagram-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buff-seeding-view-instagram-order') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buff-seeding-view-instagram-order'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buff-seeding-view-instagram-order'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-view') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-view'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-view'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-gioxem') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-gioxem'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-gioxem'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-like') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-like'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-like'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-dislike') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-dislike'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-dislike'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-tichnghesi') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-tichnghesi'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-tichnghesi'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-likecomment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-likecomment'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-likecomment'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-comment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-comment'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-comment'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/youtube-sub') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/youtube-sub'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/youtube-sub'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbuff') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbuff'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbuff'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbuffcmt') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbuffcmt'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbuffcmt'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbufflike') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbufflike'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbufflike'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbuffview') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbuffview'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbuffview'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbuffshare') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbuffshare'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbuffshare'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokbuffmat') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokbuffmat'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokbuffmat'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokviplike') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokviplike'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokviplike'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/tiktokvipview') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/tiktokvipview'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/tiktokvipview'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/shopee') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/shopee'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/shopee'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/shopeeseedinglivestream') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/shopeeseedinglivestream'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/shopeeseedinglivestream'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/shopeeviplivestream') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/shopeeviplivestream'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/shopeeviplivestream'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/ho-tro-nhan-tin') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/ho-tro-nhan-tin'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/ho-tro-nhan-tin'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/ho-tro-cuoc-goi') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/ho-tro-cuoc-goi'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/ho-tro-cuoc-goi'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/emailtouid') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/emailtouid'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/emailtouid'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/thuesim') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/thuesim'] = parse('<i class="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" class="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/thuesim'] = parse('<i class="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/buffgrouptele') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/buffgrouptele'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/buffgrouptele'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/twitterlike') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/twitterlike'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/twitterlike'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/twitterfollow') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/twitterfollow'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/twitterfollow'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookadbreaksgioxem') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookadbreaksgioxem'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookadbreaksgioxem'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookadbreakstuongtac') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookadbreakstuongtac'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookadbreakstuongtac'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/facebookadbreaksviewdexuat') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/facebookadbreaksviewdexuat'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/facebookadbreaksviewdexuat'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/googlemap') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/googlemap'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/googlemap'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/ripacc') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/ripacc'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/ripacc'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/renamefanpage') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/renamefanpage'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/renamefanpage'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/muabanfanpagegroup') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/muabanfanpagegroup'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/muabanfanpagegroup'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/muafanpage') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/muafanpage'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/muafanpage'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/bufflikesubcomment') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/bufflikesubcomment'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/bufflikesubcomment'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/instagramviplike') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/instagramviplike'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/instagramviplike'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
			if (value.link === '/tool/reportyoutube') {
				if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
					obj['/tool/reportyoutube'] = parse('<i className="fa fa-times-circle text-danger" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i><h6 style="display: inline" className="text-danger"> ('+value.active+')</h6>');
				} else {
					obj['/tool/reportyoutube'] = parse('<i className="fa fa-check-circle text-success" aria-hidden="true" style="margin-left: 5px" title="'+value.active+'"></i>');
				}
			}
		});
		const { username, money, facebook_id, type, notes } = this.props.auth.user;
		var ava = Users1;
		if (type == 1) {
			ava = Admin1;
		}
		var config_website = {};
		try {
			if (localStorage.getItem("confignavbar") != null) {
				config_website = JSON.parse(localStorage.getItem("confignavbar"));
			} else {
				config_website = this.props.auth.config_website;
			}
		} catch(e) {
			// this.props.getDataBtConfigBgNav();
		}
		return (
			<nav id="sidebar" aria-label="Main Navigation" data-simplebar="init" style={{"overflow": "scroll", "overflowY": "visible", "top": "118px"}}>
			<div className="simplebar-wrapper" style={{ marginRight: "0px" }}>
				<div className="simplebar-height-auto-observer-wrapper">
					<div className="simplebar-height-auto-observer"></div>
				</div>
				<div className="">
					<div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }} >
						<div className="simplebar-content-wrapper" >
							<div className="simplebar-content" style={{ padding: "0px" }} style={{ height: "100%" }}>
								<div className={"content-side content-side-full row"+((isMobile && this.props.auth.isOpenSubSidebar)? " content-side-full-cus-main" : "")+((isMobile && !this.props.auth.isOpenSubSidebar)? " content-side-full-cus-sub" : "")} style={{ height: (isMobile? "" : "100%") }}>
									<div className="main-menu col-lg-3 main-menu-cus" style={{ height: "93.1%", overflow: "hidden scroll", "zIndex": "999", backgroundColor: (isMobile? "" : "#fff") }}>
										<section className="ps-container scroll ps">
											<ul className="list-unstyled">
												<li data-flag="home" className={(this.state.tab_active == 'tab_trangchu'? "active" : "")}>
													<NavLink to="/" className="nuxt-link-active" onClick={() => {this.onClick('close_all', false); this.onClickTabActive('tab_trangchu'); this.onClickSubSidebar(false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar); } }} >
														{/* <FcHome style={{"verticalAlign": "-9px"}}/> */}
														<img src={process.env.PUBLIC_URL + '/images/icon/Shop-icon.png'}  width="40" height="40" style={{"marginBottom": "5px"}} />
														Trang chủ
													</NavLink>
												</li>
												<li data-flag="deposit" className={(this.state.tab_active == 'tab_naptien'? "active" : "")}>
													<NavLink to="/naptien" className="nuxt-link-active" onClick={() => {this.onClick('close_all', false); this.onClickTabActive('tab_naptien'); this.onClickSubSidebar(false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar); } }} >
														{/* <FcMoneyTransfer /> */}
														<img src={process.env.PUBLIC_URL + '/images/icon/Coins-icon.png'}  width="40" height="40" style={{"marginBottom": "5px"}} />
														<span className="nav-main-link-name">Nạp tiền</span>
													</NavLink>
												</li>
												<li data-flag="deposit" className={((this.state.tab_active == 'tab_dichvu' && !this.props.auth.isOpenSubSidebar)? "active" : "")}>
													<a href="#" className="nuxt-link-active" onClick={() => {this.onClick('close_all', false); this.onClickTabActive('tab_dichvu'); this.onClickSubSidebar(this.props.auth.isOpenSubSidebar); }} >
														{/* <FcStatistics /> */}
														<img src={process.env.PUBLIC_URL + '/images/icon/Digital-Drawing-icon.png'}  width="40" height="40" style={{"marginBottom": "5px"}} />
														<span className="nav-main-link-name">Dịch vụ</span>
													</a>
												</li>
												<li data-flag="deposit" className={(this.state.tab_active == 'tab_lichsugiaodich'? "active" : "")}>
													<NavLink to="/tool/history_order" className="nuxt-link-active" onClick={() => {this.onClick('close_all', false); this.onClickTabActive('tab_lichsugiaodich'); this.onClickSubSidebar(false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar); } }} >
														{/* <FcComboChart /> */}
														<img src={process.env.PUBLIC_URL + '/images/icon/Bar-Chart-icon.png'}  width="40" height="40" style={{"marginBottom": "5px"}} />
														<span className="nav-main-link-name">Lịch sử giao dịch</span>
													</NavLink>
												</li>
												<li data-flag="deposit" className={(this.state.tab_active == 'tab_tichhopsitedaily'? "active" : "")}>
													<NavLink to="/siteagency" className="nuxt-link-active" onClick={() => {this.onClick('close_all', false); this.onClickTabActive('tab_tichhopsitedaily'); this.onClickSubSidebar(false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar); } }} >
														{/* <FcShop /> */}
														<img src={process.env.PUBLIC_URL + '/images/icon/Clothing-Store-icon.png'}  width="40" height="40" style={{"marginBottom": "5px"}} />
														<span className="nav-main-link-name">Tích hợp site đại lý</span>
													</NavLink>
												</li>
												<li data-flag="deposit" className={(this.state.tab_active == 'tab_lienhehotro'? "active" : "")}>
													<NavLink to="/support" className="nuxt-link-active" onClick={() => {this.onClick('close_all', false); this.onClickTabActive('tab_lienhehotro'); this.onClickSubSidebar(false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar); } }} >
														{/* <FcOnlineSupport /> */}
														<img src={process.env.PUBLIC_URL + '/images/icon/Megaphone-icon.png'}  width="40" height="40" style={{"marginBottom": "5px"}} />
														<span className="nav-main-link-name">Liên hệ hỗ trợ</span>
													</NavLink>
												</li>
												{
													this.props.auth.user.type === 1 ?
														<Fragment>
															<li data-flag="deposit" className={(this.state.tab_active == 'tab_admin'? "active" : "")}>
																<NavLink to="/admin" className="nuxt-link-active" onClick={() => {this.onClick('close_all', false); this.onClickTabActive('tab_admin'); this.onClickSubSidebar(false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																	{/* <FcBusinessman /> */}
																	<img src={process.env.PUBLIC_URL + '/images/icon/Male-icon.png'}  width="40" height="40" style={{"marginBottom": "5px"}} />
																	<span className="nav-main-link-name">Admin</span>
																</NavLink>
															</li>
															<li data-flag="deposit" className={(this.state.tab_active == 'tab_hoantien'? "active" : "")}>
																<NavLink to="/tool/refund_order" className="nuxt-link-active" onClick={() => {this.onClick('close_all', false); this.onClickTabActive('tab_hoantien'); this.onClickSubSidebar(false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																	{/* <FcFeedback /> */}
																	<img src={process.env.PUBLIC_URL + '/images/icon/Mail-Money-icon.png'}  width="40" height="40" style={{"marginBottom": "5px"}} />
																	<span className="nav-main-link-name">Hoàn tiền</span>
																</NavLink>
															</li>
														</Fragment>
													: ""
												}
											</ul>
										</section>
									</div>
									<div className={"sub-menu col-lg-8 sub-menu-cus"+(this.props.auth.isOpenSubSidebar? '' : ' showtool-cus')} style={{ height: "93.1%", overflow: "hidden scroll", backgroundColor: (isMobile? "" : "#fff") }}>
										<section className="ps-container scroll ps">
											<ul data-parent="home" className="list-unstyled">
												{
													window.location.hostname === 'thegioifanpage.com' ?
														<li className="nav-main-item">
															<a variant="link" href="https://docs.google.com/spreadsheets/d/1e1rx7xtSWlIRowVrTdMNbFYNin4JaZNwuzYxc2GdJ7I/edit#gid=0" target="_blank" className="rotate-arrow-icon not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} >
																<AiFillFlag />
																<span className="">MUA BÁN FANPAGE/GROUP</span>
															</a>
														</li>
													: ""
												}
												<li className={"nav-main-item"+ (this.props.auth.isOpenFbBuff ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('buff', this.props.auth.isOpenFbBuff)}} >
														{this.props.auth.isOpenFbBuff ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">FACEBOOK BUFF</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikecommentshare_like" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF LIKE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikecommentshare_likecomment" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF LIKE COMMENT
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffmatlivestream" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF MẮT (LIVESTREAM)
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikecommentshare_comment" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF COMMENT
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Buffsub" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF SUB SPEED
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffsub_slow" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF SUB ĐỀ XUẤT
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffsub_sale" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF SUB SALE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikefanpage" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF LIKE PAGE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikefanpagesale" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF LIKE PAGE SALE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Buffsubfanpage" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF SUB PAGE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buff-seeding-review" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF ĐÁNH GIÁ & CHECKIN PAGE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/Bufflikecommentshare_share" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF SHARE PROFILE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffgroupsharelive" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF SHARE GROUP
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffgroup" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF MEMBER GROUP
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookbuffview" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF VIEW VIDEO
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffviewstory" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF VIEW STORY
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buffsublikev2" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF LIKE, SUB, CMT SALE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/bufflikesubcomment" className="nav-main-link" onClick={() => {this.onClick('buff', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF LIKE SUB TÂY
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenFbVip ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('vip', this.props.auth.isOpenFbVip)}} >
														{this.props.auth.isOpenFbVip ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">FACEBOOK VIP</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/vip-like-clone" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> VIP LIKE CLONE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/vip-comment-seeding-order" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> VIP CMT PRO
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/vip-seeding-order" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> VIP LIKE PRO
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/vip-like-group" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> VIP LIKE GROUP
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookvipcomment" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> VIP COMMENT CLONE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookvipviewvideo" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> VIP VIEW
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookvipmatlivestream" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> VIP LIVESTREAM
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookvipmatlivestreamv2" className="nav-main-link" onClick={() => {this.onClick('vip', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> VIP LIVESTREAM NEW
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenFbKhac ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('khac', this.props.auth.isOpenFbKhac)}} >
														{this.props.auth.isOpenFbKhac ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">FACEBOOK KHÁC</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/facebooklocbanbekhongtuongtac" className="nav-main-link" onClick={() => {this.onClick('khac', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> LỌC BẠN BÈ
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookchocbanbe" className="nav-main-link" onClick={() => {this.onClick('khac', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> CHỌC BẠN BÈ
																</span>
															</NavLink>
														</li>
														{/* <li className="nav-main-item">
															<NavLink to="/tool/renamefanpage" className="nav-main-link" onClick={() => {this.onClick('khac', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> ĐỔI TÊN FANPAGE
																</span>
															</NavLink>
														</li> */}
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenFbBot ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('main_facebook_bot', this.props.auth.isOpenFbBot)}} >
														{this.props.auth.isOpenFbBot ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">FACEBOOK BOT</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/facebookbotv2" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BOT LOVE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookbotlovestory" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BOT LOVE STORY
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookbotcmt" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BOT CMT
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookbotreplyinboxcho" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BOT REPLY INBOX
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookvipbotlive" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BOT INVITE LIVESTEAM
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buy_proxy" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> MUA PROXY
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/get-cookie" className="nav-main-link" onClick={() => {this.onClick('main_facebook_bot', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> GET COOKIE FACEBOOK
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenFacebookAdBreaks ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('main_facebook_adbreaks', this.props.auth.isOpenFacebookAdBreaks)}} >
														{this.props.auth.isOpenFacebookAdBreaks ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">FACEBOOK AD BREAKS</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/facebookadbreaksgioxem" className="nav-main-link" onClick={() => {this.onClick('main_facebook_adbreaks', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF 600K PHÚT XEM
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookadbreakstuongtac" className="nav-main-link" onClick={() => {this.onClick('main_facebook_adbreaks', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF 15K TƯƠNG TÁC
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/facebookadbreaksviewdexuat" className="nav-main-link" onClick={() => {this.onClick('main_facebook_adbreaks', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-facebook" style={{ "color": "#0665d0"}}></i> BUFF VIEW ĐỀ XUẤT
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenInstagram ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('main_instagram', this.props.auth.isOpenInstagram)}} >
														{this.props.auth.isOpenInstagram ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">INSTAGRAM</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/buff-seeding-like-instagram-order" className="nav-main-link" onClick={() => {this.onClick('main_instagram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-instagram" style={{ "color": "#a900ff"}}></i> BUFF LIKE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buff-seeding-sub-instagram-order" className="nav-main-link" onClick={() => {this.onClick('main_instagram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-instagram" style={{ "color": "#a900ff"}}></i> BUFF SUB
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buff-seeding-comment-instagram-order" className="nav-main-link" onClick={() => {this.onClick('main_instagram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-instagram" style={{ "color": "#a900ff"}}></i> BUFF CMT
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/buff-seeding-view-instagram-order" className="nav-main-link" onClick={() => {this.onClick('main_instagram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-instagram" style={{ "color": "#a900ff"}}></i> BUFF VIEW
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/instagramviplike" className="nav-main-link" onClick={() => {this.onClick('main_instagram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-instagram" style={{ "color": "#a900ff"}}></i>VIP LIKE
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenYoutube ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('main_youtube', this.props.auth.isOpenYoutube)}} >
														{this.props.auth.isOpenYoutube ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">YOUTUBE</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/youtube-view" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-youtube" style={{ "color": "#ff0000"}}></i> BUFF VIEW
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/youtube-like" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-youtube" style={{ "color": "#ff0000"}}></i> BUFF LIKE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/youtube-dislike" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-youtube" style={{ "color": "#ff0000"}}></i> BUFF DISLIKE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/youtube-comment" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-youtube" style={{ "color": "#ff0000"}}></i> BUFF COMMENT
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/youtube-likecomment" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-youtube" style={{ "color": "#ff0000"}}></i> BUFF LIKE COMMENT
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/youtube-sub" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-youtube" style={{ "color": "#ff0000"}}></i> BUFF SUB
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/youtube-gioxem" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-youtube" style={{ "color": "#ff0000"}}></i> BUFF 4K GIỜ XEM
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/reportyoutube" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-youtube" style={{ "color": "#ff0000"}}></i> ĐÁNH GẬY
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/youtube-tichnghesi" className="nav-main-link" onClick={() => {this.onClick('main_youtube', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-youtube" style={{ "color": "#ff0000"}}></i> TÍCH NGHỆ SĨ
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenTiktok ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('main_tiktok', this.props.auth.isOpenTiktok)}} >
														{this.props.auth.isOpenTiktok ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">TIK TOK</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/tiktokbufflike" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconTiktok} className="nav-main-link-icon-custom" style={{ "marginRight": "25px" }} /> BUFF LIKE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/tiktokbuffcmt" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconTiktok} className="nav-main-link-icon-custom" style={{ "marginRight": "25px" }} /> BUFF CMT
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/tiktokbuff" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconTiktok} className="nav-main-link-icon-custom" style={{ "marginRight": "25px" }} /> BUFF FOLLOW
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/tiktokbuffview" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconTiktok} className="nav-main-link-icon-custom" style={{ "marginRight": "25px" }} /> BUFF VIEW
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/tiktokbuffshare" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconTiktok} className="nav-main-link-icon-custom" style={{ "marginRight": "25px" }} /> BUFF SHARE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/tiktokbuffmat" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconTiktok} className="nav-main-link-icon-custom" style={{ "marginRight": "25px" }} /> BUFF MẮT
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/tiktokviplike" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconTiktok} className="nav-main-link-icon-custom" style={{ "marginRight": "25px" }} /> VIP LIKE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/tiktokvipview" className="nav-main-link" onClick={() => {this.onClick('main_tiktok', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconTiktok} className="nav-main-link-icon-custom" style={{ "marginRight": "25px" }} /> VIP VIEW
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenShopee ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('main_shopee', this.props.auth.isOpenShopee)}} >
														{this.props.auth.isOpenShopee ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">SHOPEE</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/shopee" className="nav-main-link" onClick={() => {this.onClick('main_shopee', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconShopee} className="nav-main-link-icon-custom" style={{ "marginRight": "25px", "height": "50px" }} /> BUFF SUB
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/shopeeseedinglivestream" className="nav-main-link" onClick={() => {this.onClick('main_shopee', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconShopee} className="nav-main-link-icon-custom" style={{ "marginRight": "25px", "height": "50px" }} /> SEEDING LIVESTEAM
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/shopeeviplivestream" className="nav-main-link" onClick={() => {this.onClick('main_shopee', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconShopee} className="nav-main-link-icon-custom" style={{ "marginRight": "25px", "height": "50px" }} /> VIP LIVESTEAM
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/shopeetim" className="nav-main-link" onClick={() => {this.onClick('main_shopee', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<img src={iconShopee} className="nav-main-link-icon-custom" style={{ "marginRight": "25px", "height": "50px" }} /> BUFF TIM
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenGoogle ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('main_google', this.props.auth.isOpenGoogle)}} >
														{this.props.auth.isOpenGoogle ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">GOOGLE</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/googlemap" className="nav-main-link" onClick={() => {this.onClick('main_google', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-google" style={{ "marginRight": "25px" }}></i> GOOGLE MAP REVIEW
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenTelegram ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('main_telegram', this.props.auth.isOpenTelegram)}} >
														{this.props.auth.isOpenTelegram ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">TELEGRAM</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/buffgrouptele" className="nav-main-link" onClick={() => {this.onClick('main_telegram', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fa fa-paper-plane" style={{ "marginRight": "25px" }}></i> BUFF MEMBER GROUP
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className={"nav-main-item"+ (this.props.auth.isOpenTwitter ? ' open' : '')}>
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} onClick={() => {this.onClick('main_twitter', this.props.auth.isOpenTwitter)}} >
														{this.props.auth.isOpenTwitter ? <IoIosArrowDown /> : <IoIosArrowForward />}
														<span className="d-inline-block">TWITTER</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/twitterlike" className="nav-main-link" onClick={() => {this.onClick('main_twitter', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-twitter" style={{ "marginRight": "25px" }}></i> BUFF LIKE
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/twitterfollow" className="nav-main-link" onClick={() => {this.onClick('main_twitter', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);} }} >
																<span className="nav-main-link-name">
																	<i className="nav-main-link-icon fab fa-twitter" style={{ "marginRight": "25px" }}></i> BUFF FOLLOW
																</span>
															</NavLink>
														</li>
													</ul>
												</li>
												<li className="nav-main-item open">
													<a variant="link" href="#" target="_self" className="rotate-arrow-icon opacity-75 not-collapsed" aria-expanded="true" aria-controls="menu_2_0" style={{"overflow-anchor": "none"}} >
														<IoIosArrowDown />
														<span className="d-inline-block">KHÁC</span>
													</a>
													<ul className="nav-main-submenu">
														<li className="nav-main-item">
															<NavLink to="/tool/ho-tro-nhan-tin" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
																<span className="nav-main-link-name">
																	<MdMessage /> Hỗ trợ nhắn tin
																</span>
															</NavLink>
														</li>
														{/* <li className="nav-main-item">
															<NavLink to="/tool/ho-tro-cuoc-goi" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
																<span className="nav-main-link-name">
																	<MdPhoneInTalk /> Hỗ trợ cuộc gọi
																</span>
															</NavLink>
														</li> */}
														<li className="nav-main-item">
															<NavLink to="/tool/emailtouid" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
																<span className="nav-main-link-name">
																	<BiSearchAlt /> Convert email to UID facebook
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/thuesim" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
																<span className="nav-main-link-name">
																	<RiSimCard2Fill /> Thuê sim OTP Facebook
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/edittext" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
																<span className="nav-main-link-name">
																	<FiNavigation /> Tool Edit Text
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/get-otp" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
																<span className="nav-main-link-name">
																	<FiNavigation /> Lấy mã OTP 2FA
																</span>
															</NavLink>
														</li>
														{/* <li className="nav-main-item">
															<NavLink to="/tool/findmyid" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
																<span className="nav-main-link-name">
																	Lấy ID Facebook, Ins
																</span>
															</NavLink>
														</li>
														<li className="nav-main-item">
															<NavLink to="/tool/shieldavatar" className="nav-main-link" onClick={() => {this.onClick('close_all', false); if(isMobile){this.onClickSidebar(this.props.auth.isOpenSidebar);}}} >
																<span className="nav-main-link-name">
																	Bật khiên avatar
																</span>
															</NavLink>
														</li> */}
													</ul>
												</li>
											</ul>
										</section>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="simplebar-placeholder" style={{ width: "auto", height: "1198px" }} ></div>
			</div>
			<div className="simplebar-track simplebar-horizontal" style={{ visibility: "hidden" }} >
				<div className="simplebar-scrollbar" style={{ width: "0px", display: "none" }} ></div>
			</div>
			<div className="simplebar-track simplebar-vertical" style={{ visibility: "visible" }} >
				<div className="simplebar-scrollbar" style={{ height: "732px", transform: "translate3d(0px, 78px, 0px)", display: "block" }} ></div>
			</div>
			</nav>
		);
	}
}

Navbar.propTypes = {
	auth: PropTypes.object,
	changeSidebar: PropTypes.func.isRequired,
	changeSubSidebar: PropTypes.func.isRequired,
	changeOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
	adminpage: state.adminpage,
});

export default connect(mapStateToProps, { changeSidebar, changeSubSidebar, changeOpen, getDataFeature, getDataSticker, getSevicesInfo })(Navbar);