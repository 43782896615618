import axios from "axios";
import { returnErrors } from "./errorActions";
import { getListBankHistoryThe } from "./adminPageAction";

import {
	USER_LOADED,
	USER_LOADING,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT_SUCCESS,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	CHANGE_SIDEBAR,
	CHANGE_SUB_SIDEBAR,
	CHANGE_MENUPROFILE,
	CHANGE_MENUNOTI,
	NOTI_LOADED,
	NOTI_SP_LOADED,
	CHANGE_OPENFBBUFF,
	CHANGE_OPENFBVIP,
	CHANGE_OPENFBKHAC,
	CHANGE_OPENFB,
	CHANGE_OPENFBBOT,
	CHANGE_OPENINSTAGRAM,
	CHANGE_OPENYOUTUBE,
	CHANGE_OPENSHOPEE,
	CHANGE_OPENTIKTOK,
	CHANGE_OPENGOOGLE,
	CHANGE_OPENTELEGRAM,
	CHANGE_OPENFBADBREAKS,
	CHANGE_OPENTWITTER,
	CHANGE_CLOSEALL,
	FORGOTPASSWORD_SEND_SUCCESS,
	FORGOTPASSWORD_SEND_FAIL,
	RESETPASSWORD_SEND_SUCCESS,
	RESETPASSWORD_SEND_FAIL,
	TOKEN_RESET_SUCCESS,
	TOKEN_RESET_FAIL,
} from "../actions/types";
import Swal from 'sweetalert2';

export const loadUser = () => (dispatch, getState) => {
  dispatch({
    type: USER_LOADING
  });
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-type": "application/json"
    }
  };
  if (token) {
    config.headers["ht-token"] = token;
    axios
      .get("/api/auth/user", config)
      .then(res =>
        dispatch({
          type: USER_LOADED,
          payload: res.data
        })
      )
      .catch(err => {
        // dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
          type: AUTH_ERROR
        });
      });
  } else {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

//Login
export const login = ({ username, password, maxacminh }) => dispatch => {
  const config = {
    headers: {
      "Content-type": "application/json"
    }
  };
  dispatch({
    type: USER_LOADING
  });
  const body = JSON.stringify({ username, password, maxacminh });
  if (username.trim() == '' || password.trim() == '') {
	dispatch(
	  returnErrors({msg: "Vui lòng điền đầy đủ"}, 400, "LOGIN_FAIL")
	);
	dispatch({
	  type: LOGIN_FAIL
	});
	return false;
  }
  setTimeout(() => {
    axios
      .post("/api/auth", body, config)
      .then(res => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data
        });
		window.location.reload();
	  })
      .catch(err => {
		if (err.response.status === 401) {
			dispatch(
				returnErrors(err.response.data, err.response.status, "LOGIN_FAIL_2FA")
			);
		} else {
			dispatch(
				returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
			);
		}
        dispatch({
          type: LOGIN_FAIL
        });
      });
  }, 1000);
};

// Logout User
export const logout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

export const register = ({ username, email, password, repeatpassword, facebook_id, phone }) => dispatch => {
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	}
	
	// Request body
	var domain = window.location.hostname;
	const body = JSON.stringify({ username, email, password, repeatpassword, facebook_id, phone, domain });
	if (username.trim() == '' || email.trim() == '' || password.trim() == '' || repeatpassword.trim() == '' || phone.trim() == '') {
		dispatch(
			returnErrors({msg: "Vui lòng điền đầy đủ"}, 400, "REGISTER_FAIL")
		);
		dispatch({
			type: REGISTER_FAIL
		});
		return false;
	}
	
	dispatch({
	  type: 'LOADING_AUTH',
	  payload: true
    });
	axios
		.post('/api/users', body, config)
		.then(res => {
			dispatch({
				type: 'LOADING_AUTH',
				payload: false
			});
			dispatch({
				type: REGISTER_SUCCESS,
				payload: res.data
			});
			window.location.reload();
		})
		.catch(err => {
			dispatch({
				type: 'LOADING_AUTH',
				payload: false
			});
			dispatch(
				returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL')
			);
			dispatch({
				type: REGISTER_FAIL
			});
		});
	
}

export const forgotpassword = ({ username, email }) => dispatch => {
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	}
	
	// Request body
	const body = JSON.stringify({ username, email });
	if (username.trim() == '' || email.trim() == '') {
		dispatch(
			returnErrors({msg: "Vui lòng điền đầy đủ"}, 400, "FORGOTPASSWORD_SEND_FAIL")
		);
		dispatch({
			type: FORGOTPASSWORD_SEND_FAIL
		});
		return false;
	}
	
    dispatch({
	  type: 'LOADING_AUTH',
	  payload: true
    });
	axios
		.post('/api/users/forgotpassword', body, config)
		.then(res => {
			dispatch({
				type: 'LOADING_AUTH',
				payload: false
			});
			dispatch(
				returnErrors(res.data, res.status, 'FORGOTPASSWORD_SEND_SUCCESS')
			);
			dispatch({
				type: FORGOTPASSWORD_SEND_SUCCESS
			});
			// window.location.reload();
		})
		.catch(err => {
			dispatch({
				type: 'LOADING_AUTH',
				payload: false
			});
			dispatch(
				returnErrors(err.response.data, err.response.status, 'FORGOTPASSWORD_SEND_FAIL')
			);
			dispatch({
				type: FORGOTPASSWORD_SEND_FAIL
			});
		});
	
}

export const resetpassword = ({ token, password, repeatpassword }) => dispatch => {
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	}
	
	// Request body
	const body = JSON.stringify({ token, password, repeatpassword });
	if (token.trim() == '') {
		dispatch(
			returnErrors({msg: "Link đặt lại mật khẩu đã hết hạn! #fe"}, 400, "RESETPASSWORD_SEND_FAIL")
		);
		dispatch({
			type: RESETPASSWORD_SEND_FAIL
		});
		return false;
	}
	if (password.trim() == '' || repeatpassword.trim() == '') {
		dispatch(
			returnErrors({msg: "Vui lòng điền đầy đủ"}, 400, "RESETPASSWORD_SEND_FAIL")
		);
		dispatch({
			type: RESETPASSWORD_SEND_FAIL
		});
		return false;
	}
	
    dispatch({
	  type: 'LOADING_AUTH',
	  payload: true
    });
	axios
		.post('/api/users/resetpassword', body, config)
		.then(async res => {
			dispatch({
				type: 'LOADING_AUTH',
				payload: false
			});
			dispatch(
				returnErrors(res.data, res.status, 'RESETPASSWORD_SEND_SUCCESS')
			);
			dispatch({
				type: RESETPASSWORD_SEND_SUCCESS
			});
			// window.location.reload();
			await new Promise(resolve => setTimeout(resolve, 3000));
			window.location.href = '/';
		})
		.catch(err => {
			dispatch({
				type: 'LOADING_AUTH',
				payload: false
			});
			dispatch(
				returnErrors(err.response.data, err.response.status, 'RESETPASSWORD_SEND_FAIL')
			);
			dispatch({
				type: RESETPASSWORD_SEND_FAIL
			});
		});
	
}
export const checkTokenReset = ({ token }) => dispatch => {
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	}
	
	// Request body
	const body = JSON.stringify({ token });
	
    dispatch({
	  type: 'LOADING_AUTH',
	  payload: true
    });
	axios
		.post('/api/users/checktokenreset', body, config)
		.then(async res => {
			dispatch({
				type: 'LOADING_AUTH',
				payload: false
			});
			dispatch(
				returnErrors(res.data, res.status, 'TOKEN_RESET_SUCCESS')
			);
			dispatch({
				type: TOKEN_RESET_SUCCESS
			});
			// window.location.reload();
		})
		.catch(err => {
			dispatch({
				type: 'LOADING_AUTH',
				payload: false
			});
			dispatch(
				returnErrors(err.response.data, err.response.status, 'TOKEN_RESET_FAIL')
			);
			dispatch({
				type: TOKEN_RESET_FAIL
			});
		});
	
}
export const changeOpen = (type, isOpen) => dispatch => {
	if (type === "FbBuff") {
		dispatch ({
			type: CHANGE_OPENFBBUFF,
			payload: isOpen
		});
	}
	if (type === "FbVip") {
		dispatch ({
			type: CHANGE_OPENFBVIP,
			payload: isOpen
		});
	}
	if (type === "FbKhac") {
		dispatch ({
			type: CHANGE_OPENFBKHAC,
			payload: isOpen
		});
	}
	if (type === "Fb") {
		dispatch ({
			type: CHANGE_OPENFB,
			payload: isOpen
		});
	}
	if (type === "FbBot") {
		dispatch ({
			type: CHANGE_OPENFBBOT,
			payload: isOpen
		});
	}
	if (type === "Instagram") {
		dispatch ({
			type: CHANGE_OPENINSTAGRAM,
			payload: isOpen
		});
	}
	if (type === "Youtube") {
		dispatch ({
			type: CHANGE_OPENYOUTUBE,
			payload: isOpen
		});
	}
	if (type === "Shopee") {
		dispatch ({
			type: CHANGE_OPENSHOPEE,
			payload: isOpen
		});
	}
	if (type === "Tiktok") {
		dispatch ({
			type: CHANGE_OPENTIKTOK,
			payload: isOpen
		});
	}
	if (type === "Google") {
		dispatch ({
			type: CHANGE_OPENGOOGLE,
			payload: isOpen
		});
	}
	if (type === "Telegram") {
		dispatch ({
			type: CHANGE_OPENTELEGRAM,
			payload: isOpen
		});
	}
	if (type === "Fbadbreaks") {
		dispatch ({
			type: CHANGE_OPENFBADBREAKS,
			payload: isOpen
		});
	}
	if (type === "Twitter") {
		dispatch ({
			type: CHANGE_OPENTWITTER,
			payload: isOpen
		});
	}
	if (type === "CloseAll") {
		dispatch ({
			type: CHANGE_CLOSEALL,
			payload: isOpen
		});
	}
};
export const changeSidebar = (isOpen) => dispatch => {
	dispatch ({
		type: CHANGE_SIDEBAR,
		payload: isOpen
	});
};
export const changeSubSidebar = (isOpen) => dispatch => {
	dispatch ({
		type: CHANGE_SUB_SIDEBAR,
		payload: isOpen
	});
};
export const changeMenuProfile = (isOpen) => dispatch => {
	dispatch ({
		type: CHANGE_MENUPROFILE,
		payload: isOpen
	});
};
export const changeMenuNoti = (isOpen) => dispatch => {
	dispatch ({
		type: CHANGE_MENUNOTI,
		payload: isOpen
	});
};

export const layThongBaoNapTien = () => (dispatch, getState) => {
	const config = {
	    headers: {
	      "Content-type": "application/json",
	      "ht-token": getState().auth.token
	    }
	};
	axios.get('/api/auth/getinfodep', config)
	.then(res => {
		if (res.data.status === 200) {
			var mess = '';
			if (res.data.data.type === 0) {
				mess = 'Bạn đã nạp thành công số tiền: '+Math.floor((res.data.data.tien_nap + (res.data.data.tien_nap*res.data.data.khuyen_mai/100)))+' VND';
			} else {
				mess = 'Admin đã trừ số tiền của bạn: - '+res.data.data.tien_nap+' VND';
			}
			openBtComfirmAlert(mess, 'success', 'Thông báo');
			dispatch(loadUser());
		}
	});
}


export const getDataBtConfigBgNav = (name) => (dispatch, getState) => {
	const config = {
	    headers: {
	      "Content-type": "application/json",
	    }
	};
	axios.get('/api/auth/getdatabtconfigbgnav', config)
	.then(res => {
		if (res.data.status === 200 && res.data.data.length > 0) {
			var new_data = {
				support_id_fanpage: '',
				bg_navbar_site: '0665d0',
				cf_title: window.location.hostname.replace('https://', '').replace('http://', '').replace('www.', '').toUpperCase(),
				cf_icon: window.location.protocol + "//" + window.location.hostname.replace('https://', '').replace('http://', '').replace('www.', '') + '/favicon.ico',
				cf_description: '',
				cf_og_title: '',
				cf_og_site_name: '',
				cf_og_description: '',
				cf_og_type: '',
				cf_og_url: '',
				cf_og_keywords: '',
				cf_og_image: '',
				title_dndk: 'Chào mừng bạn đến với hệ thống tăng like sub Facebook, Tiktok, Youtube, Instagram...',
				title_color_dndk: '000000',
				landingpage: '8',
				displaytabmuabanfanpagegroup: '1',
				defaultlanguage: '-1',
				naptien_mota: '',
				naptien_chuy: '',
				usertype_default: '0',
			};
			res.data.data.forEach(function(v, i) {
				var check = 0;
				if ((v.name == 'bg_navbar_site' && v.value == '') || (v.name == 'cf_title' && v.value == '')
					|| (v.name == 'cf_icon' && v.value == '') || (v.name == 'title_dndk' && v.value == '')
					|| (v.name == 'title_color_dndk' && v.value == '') || (v.name == 'landingpage' && v.value == '')
					|| (v.name == 'defaultlanguage' && v.value == '') || (v.name == 'naptien_mota' && v.value == '')
					|| (v.name == 'naptien_chuy' && v.value == '') || (v.name == 'displaytabmuabanfanpagegroup' && v.value == '')
					|| (v.name == 'usertype_default' && v.value == '')) {
					check = 1;
				}
				if (check == 0) {
					new_data = {
						...new_data,
						[v.name]: v.value
					}
				}
			});
			dispatch ({
				type: 'SET_DATA_BG_NAVBAR',
				payload: new_data
			});
		}
	});
}

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
	return false;
}

export const getNoti = () => (dispatch, getState) => {
	const user = getState().auth.user;
	if (user !== null) {
		const token = getState().auth.token;
		const type = getState().auth.user.type;
		const config = {
			headers: {
				"Content-type": "application/json"
			},
			params: {
				"id_noti": 0
			}
		};
		if (token) {
			config.headers["ht-token"] = token;
			axios
				.get("/api/auth/noti", config)
				.then(res => {
						if (res.data.status === 200) {
							if (type !== 1) {
								if (res.data.data.noti !== "") {
									var title = '<span class="text-danger font-bold custom_noti_desktop_title">'+Buffer.from(res.data.data.noti.title, 'base64').toString('utf8')+'</span>';
									
									var html = '<span class="text-danger font-bold custom_noti_desktop_content" style="font-size: 24px; white-space: pre-line;">'+Buffer.from(res.data.data.noti.content, 'base64').toString('utf8')+'</span>';
									if (res.data.data.noti.image) {
										html += '<img src="'+res.data.data.noti.image+'" alt="'+res.data.data.noti.image+'" width="100%" style="margin-bottom: 10px" />';
									}
									if (res.data.data.noti.video) {
										html += '<iframe height="420" style="margin-bottom: 10px; width: 100%" src="https://www.youtube.com/embed/'+res.data.data.noti.video+'" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>';
									}
									
									Swal.fire({
										icon: 'warning',
										title: title,
										html: html,
										width: 'fit-content',
										customClass: 'custom-max-width custom-text-align',
										showCancelButton: true,
										reverseButtons: true,
										confirmButtonText: "Đã đọc",
										cancelButtonText: "Bỏ qua",
										showCloseButton: true,
										closeButtonHtml: '<i class="fas fa-times" style="color: #f27474;"></i>',
									}).then((result) => {
										if (result.value || result.dismiss == 'close') {
											config.params["id_noti"] = res.data.data.noti.id;
											axios.get("/api/auth/notiseen", config);
										}
									});
								}
							}
							dispatch({
								type: NOTI_LOADED,
								payload: res.data.data.list_noti
							})
						} else {
							openBtComfirmAlert('Hệ thống đang bảo trì, xin vui lòng quay lại sau 5 - 10 phút!', 'error', 'Thông báo');
						}
					}
				)
				.catch(err => {
					// dispatch(returnErrors(err.response.data, err.response.status));
					// dispatch({
						// type: AUTH_ERROR
					// });
				});
		}
	}
};

export const getNotiSP = () => (dispatch, getState) => {
	const user = getState().auth.user;
	if (user !== null) {
		const token = getState().auth.token;
		const type = getState().auth.user.type;
		const config = {
			headers: {
				"Content-type": "application/json"
			},
			params: {
				"id_noti": 0
			}
		};
		if (token) {
			config.headers["ht-token"] = token;
			axios
				.get("/api/auth/notisp", config)
				.then(res => {
						if (res.data.status === 200) {
							dispatch({
								type: NOTI_SP_LOADED,
								payload: res.data.data.list_noti_sp
							});
							var path_check = window.location.pathname.replace(/\/+$/g, '');
							if (path_check.indexOf('/support') > -1) {
								return false;
							}
							if (type !== 1) {
								if (res.data.data.noti !== "") {
									var title = '<span class="text-danger font-bold custom_noti_desktop_title">Đơn hỗ trợ '+res.data.data.noti.id_ticket+'</span>';
									
									var html = '<span class="text-danger font-bold custom_noti_desktop_content" style="font-size: 24px; white-space: pre-line;">'+res.data.data.noti.mota+'</span>';
									if (res.data.data.noti.anh) {
										html += '<img src="'+res.data.data.noti.anh+'" alt="'+res.data.data.noti.anh+'" width="100%" style="margin-bottom: 10px" />';
									}
									
									Swal.fire({
										icon: 'warning',
										title: title,
										html: html,
										width: 'fit-content',
										customClass: 'custom-max-width custom-text-align',
										showCancelButton: true,
										reverseButtons: true,
										confirmButtonText: "Đi đến",
										cancelButtonText: "Bỏ qua",
										showCloseButton: true,
										closeButtonHtml: '<i class="fas fa-times" style="color: #f27474;"></i>',
									}).then((result) => {
										if (result.value || result.dismiss == 'close') {
											config.params["id_noti"] = res.data.data.noti.id_ticket;
											// console.log(config.params["id_noti"]);
											axios.get("/api/auth/notiseensp", config).then(r => {
												window.location.href = '/support/'+res.data.data.noti.id_ticket;
											}).catch(e => {
												window.location.href = '/support/'+res.data.data.noti.id_ticket;
											});
										}
									});
								}
							}
						} else {
							openBtComfirmAlert('Hệ thống đang bảo trì, xin vui lòng quay lại sau 5 - 10 phút!', 'error', 'Thông báo');
						}
					}
				)
				.catch(err => {
					// dispatch(returnErrors(err.response.data, err.response.status));
					// dispatch({
						// type: AUTH_ERROR
					// });
				});
		}
	}
};

export const laylichsumuathe = (type) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
		params: {
	      	idUser: getState().auth.user.id,
	      	type: type
	    },
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/auth/bank_history', dataPass)
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'GET_HISTORY_DEPOST_THE',
				payload: res.data.data
			});
		}
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}

export const editHistoryThe = (id, status, closeM) => (dispatch, getState) => {
	var body = {
			id: id,
			status: status,
		};
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.post('/api/auth/bank_history_edit', body, dataPass)
		.then(res => {
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
			dispatch(laylichsumuathe(0));
			dispatch(getListBankHistoryThe());
			closeM();
			if (res.data.status === 200) {
				Swal.fire({
					icon: 'success',
					html: 'Tạo tiến trình thành công'
				});
			} else {
				var message = '';
				if (res.data.error) {
					if (res.data.error.object_id) {
						message = res.data.error.object_id[0];
					} else if (res.data.error.quantity) {
						message = res.data.error.quantity[0];
					} else {
						message = res.data.message;
					}
				} else {
					message = res.data.message;
				}
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: message,
				});
			}
		});
};

export const getDataFeature = (limit, search) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
		params: {
	      	limit: limit,
	      	search: search
	    },
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/auth/getfeature', dataPass)
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
			type: 'LIST_DATA_FEATURE',
			payload: res.data.data
		});
		}
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}

export const getDataSticker = (limit, search) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
		params: {
	      	limit: limit,
	      	search: search
	    },
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/auth/getsticker', dataPass)
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
			type: 'LIST_DATA_STICKER_PACKAGE',
			payload: res.data.data
		});
		}
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}
export const getTokenAvt = () => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/auth/gettokenavt', dataPass)
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'GET_TOKEN_AVT',
				payload: res.data
			});
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}
	});
}

export const getSevices = () => (dispatch, getState) => {
	axios.get('/api/auth/getservices')
	.then(res => {
		if (res.data.status === 200 && res.data.data.length > 0) {
			dispatch ({
				type: 'SET_DATA_SERVICES',
				payload: res.data.data
			});
		}
	});
}

export const getSevicesInfo = () => (dispatch, getState) => {
	const token = getState().auth.token;
	const dataPass = {
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/auth/getservicesinfo', dataPass)
	.then(res => {
		if (res.data.status === 200 && Object.keys(res.data.data).length > 0) {
			dispatch ({
				type: 'SET_DATA_SERVICES_INFO',
				payload: res.data.data
			});
		}
	});
}