import axios from 'axios';
import Swal from 'sweetalert2';

export const getListUser = (page = 1, obj_search = '') => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.get('/api/admin/listuser?page='+page+'&obj_search='+obj_search, tokenConfig(getState))
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LIST_USER',
				payload: res.data.data
			});
			dispatch({
				type: 'ADMIN_LIST_DATA_USER_TYPE',
				payload: res.data.usertype
			});
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}
	});
}

export const laylichsumua = (limit) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
		params: {
	      	idUser: getState().auth.user.id,
	      	limit: limit
	    },
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/payment/list_bankpayment', dataPass)
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
			type: 'GET_HISTORY_DEPOST',
			payload: res.data.data
		});
		}
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}

export const getTokenAgency = () => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/admin/gettokenagency', dataPass)
	.then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'CHANGE_TOKEN_AGENCY',
				payload: res.data.data
			});
			Swal.fire({ icon: 'success', title: '', text: 'Lấy thành công token đại lý!' });
		} else {
			Swal.fire({ icon: 'error', title: '', text: 'Đã có lỗi xảy ra, vui lòng thử lại!' });
		}
	});
}

export const registerAgency = (domain = '') => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
		params: {
	      	domain: domain
	    },
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/admin/registeragency', dataPass)
	.then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			getListDomain();
			dispatch({
				type: 'NAME_SERVERS_CLOUDFLARE',
				payload: res.data.data
			});
			Swal.fire({ icon: 'success', title: '', text: 'Bạn đã đăng kí thành công đại lý!' });
		} else {
			Swal.fire({ icon: 'error', title: '', text: res.data.message });
		}
	});
}

export const getListDomain = () => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/admin/getlistdomain', dataPass)
	.then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_DOMAIN',
				payload: res.data.data
			});
		} else {
			dispatch({
				type: 'LIST_DOMAIN',
				payload: []
			});
		}
	});
}
export const getConfigSite = () =>(dispatch, getState) =>{
	axios.get('/api/admin/list_configyoutube', tokenConfig(getState))
	.then(res => {
		if (res.data.status === 200) {
			if (res.data.data.length > 0) {
				dispatch({
					type: 'CHANGE_CONFIG_SITE',
					payload: res.data.data[0]
				});
			}
		}
	});
}

export const editConfigSite = (data) =>(dispatch, getState) =>{
	dispatch({
		type: 'CHANGE_CONFIG_SITE',
		payload: data
	});
}

export const saveConfigSite = (data) =>(dispatch, getState) =>{
	axios.post('/api/admin/updateconfigyoutube/', data, tokenConfig(getState))
		.then(res => {
			if (res.data.status === 200) {
				var obj = {
					id: res.data.data.insertId,
					link_youtube1: data.link_youtube1,
					link_youtube2: data.link_youtube2
				}
				dispatch({
					type: 'CHANGE_CONFIG_SITE',
					payload: obj
				});
				Swal.fire({ icon: 'success', title: '', text: 'Cập nhật dữ liệu thành công!' });
			} else {
				Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
			}
		});
}
export const deleteItemUser = (id_remove) => (dispatch, getState) => {
	let bodysend = {
		id: id_remove,
	};

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn muốn xóa tài khoản này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		axios.post('/api/admin/delete_user/', bodysend, tokenConfig(getState))
			.then(res => {
				if (res.data.status === 200) {
					dispatch({
						type: 'ADMIN_REMOVE_USER',
						payload: id_remove
					});
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Xóa tài khoản thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	}
  	})
}

export const searchDataUser = (value_search) => (dispatch, getState) => {
	let bodysend = {
		search: value_search,
	};
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/admin/filter_user/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LIST_USER',
				payload: res.data.data
			});
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const RegisterUserRun = (data, propPass) => (dispatch, getState) => {
	if (data.nameUser.trim() === ''
		|| data.emailUser.trim() === ''
		|| data.phoneUser.trim() === ''
		|| data.passUser.trim() === ''
		|| data.rePass.trim() === ''
	) {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'Các trường không được để trống!' });
		return false;
	}

	if (data.rePass !== data.passUser) {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'Mật khẩu nhập lại không khớp!' });
		return false;
	}

	let bodysend = {
		dataform: data,
	};

	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/admin/register_user/', bodysend)
	.then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch(getListUser());
			propPass.openModal();
			Swal.fire({ icon: 'success', title: 'Thành công', text: 'Tạo tài khoản thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', html: res.data.message });
		}
	}).catch(err => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		Swal.fire({ icon: 'error', title: 'Lỗi', text: err.response.data.msg });
    });
}
export const getListBankHistory = (limit, search) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
		params: {
	      	limit: limit,
	      	search: search
	    },
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/admin/gethistorydep', dataPass)
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
			type: 'LIST_DATA_BANK_PAYMENT_HISTORY',
			payload: res.data.data
		});
		}
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}

export const getListBankHistoryThe = (limit, search) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
		params: {
	      	limit: limit,
	      	search: search
	    },
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/admin/gethistorydepthe', dataPass)
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
			type: 'LIST_DATA_BANK_PAYMENT_HISTORY_THE',
			payload: res.data.data
		});
		}
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}

export const getListDaiLy = () => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/admin/getlistdaily', dataPass)
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
			type: 'LIST_DATA_DAI_LY',
			payload: res.data.data
		});
		}
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}

export const getDataFeature = (limit, search) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	const token = getState().auth.token;
	const dataPass = {
		params: {
	      	limit: limit,
	      	search: search
	    },
	    headers: {
	    	"Content-type": "application/json",
	    }
  	}
	if (token) {
		dataPass.headers['ht-token'] = token;
	}
	axios.get('/api/admin/getfeature', dataPass)
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
			type: 'LIST_DATA_FEATURE',
			payload: res.data.data
		});
		}
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}
export const saveRefundPriceUser = (data, fromdata, propPass, priceEnd = 0) => (dispatch, getState) => {
	let bodysend = {
		dataform: fromdata,
		id: data.id
	};

	let statusChange = 0;
	if (fromdata.type_add === 'api') {
		statusChange = 1;
	}

	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn với điều này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		axios.post('/api/admin/update_refund_price_user/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'ADMIN_LOADING_BLOCK',
					payload: false
				});
				dispatch(getListUser());
				if (res.data.status === 200) {
					// dispatch({
						// type: 'ADMIN_EDIT_PRICE_USER',
						// payload: priceEnd,
						// id: data.id,
						// status_change: statusChange
					// });
					propPass.openModal();
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Sửa tài khoản thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	} else {
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}
  	});
}
export const saveEditPriceUser = (data, fromdata, propPass, priceEnd = 0) => (dispatch, getState) => {
	let bodysend = {
		dataform: fromdata,
		id: data.id
	};

	let statusChange = 0;
	if (fromdata.type_add === 'api') {
		statusChange = 1;
	}

	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn với điều này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		axios.post('/api/admin/update_price_user/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'ADMIN_LOADING_BLOCK',
					payload: false
				});
				if (res.data.status === 200) {
					dispatch({
						type: 'ADMIN_EDIT_PRICE_USER',
						payload: priceEnd,
						id: data.id,
						status_change: statusChange
					});
					dispatch(getListUser());
					propPass.openModal();
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Sửa tài khoản thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	} else {
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}
  	});
}

export const saveNewPassUser = (data, propPass) => (dispatch, getState) => {
	if (data.newPass !== data.rePass) {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'Mật khẩu nhập lại không khớp!' });
		return false;
	}

	let bodysend = {
		dataform: data,
	};

	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn với điều này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		axios.post('/api/admin/reset_pass_user/', bodysend, tokenConfig(getState))
			.then(res => {
				if (res.data.status === 200) {
					propPass.openModal();
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Đổi mật khẩu tài khoản thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', html: res.data.message });
				}
			});
	  	}
  	});
}

export const getOverviewUsers = () => (dispatch, getState) => {
	axios.get('/api/admin/overview_user/', tokenConfig(getState))
	.then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_DATA_OVERVIEW_USER',
				payload: res.data.data
			});
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const saveConfigColorNav = (data) => (dispatch, getState) => {
	let bodysend = {
		cf_data: data,
	};
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn với điều này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: true
			});
	  		axios.post('/api/admin/updateOverviewUsers/', bodysend, tokenConfig(getState))
			.then(res => {
				dispatch({
					type: 'ADMIN_LOADING_BLOCK',
					payload: false
				});
				if (res.data.status === 200) {
					dispatch ({
						type: 'SET_DATA_BG_NAVBAR',
						payload: data
					});
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Lưu thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	}
  	});
}

export const getDataIsUser = () => (dispatch, getState) => {
	return axios.get('/api/admin/data_user/', tokenConfig(getState));
}

export const getDataWeb = (data) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.get('/api/admin/data_web?type='+data, tokenConfig(getState)).then(res => {
		
		setTimeout(function(){ 
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}, 500);
		if (res.data.status === 200) {
			dispatch({
				type: 'LOAD_ALL_DATA_WEB',
				payload: res.data.data
			});
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const saveCreatNotice = (title, content, video, image, fclosenodal) => (dispatch, getState) => {
	if (title.trim() === '' && content.trim() === '') {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'Tiêu đề nội dung không được để trống' });
		return false;
	}

	let bodysend = {
		title: title,
		content: content,
		video: video,
		image: image,
	}
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});

	axios.post('/api/admin/add_notice', bodysend, tokenConfig(getState)).then(res => {
		
		setTimeout(function(){ 
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}, 500);

		if (res.data.status === 200) {
			fclosenodal();

			// dispatch({
				// type: 'ADMIN_ADD_NOTICE',
				// payload: {id: res.data.data.insertId, title: title, content: content}
			// });
			dispatch(getlistNoticeAdmin());

			Swal.fire({ icon: 'success', title: '', text: 'Thêm thành công' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
};

export const getlistNoticeAdmin = (data) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.get('/api/admin/list_notice', tokenConfig(getState)).then(res => {
		
		setTimeout(function(){ 
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}, 500);
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LOAD_LIST_NOTICE',
				payload: res.data.data
			});
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const deleteItemNotice = (id) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có muốn xóa thông báo này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			axios.get('/api/admin/delete_notice?id='+id, tokenConfig(getState)).then(res => {
				dispatch({
					type: 'ADMIN_LOADING_BLOCK',
					payload: false
				});
				if (res.data.status === 200) {
					dispatch({
						type: 'ADMIN_DELETE_NOTICE',
						payload: id
					});
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Xóa thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	} else {
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}
  	})
}

export const deleteDaiLy = (id) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có muốn xóa đại lý này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			axios.get('/api/admin/delete_daily?id='+id, tokenConfig(getState)).then(res => {
				dispatch({
					type: 'ADMIN_LOADING_BLOCK',
					payload: false
				});
				if (res.data.status === 200) {
					dispatch({
						type: 'ADMIN_DELETE_DAI_LY',
						payload: id
					});
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Xóa thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	} else {
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}
  	})
}

export const getListSpeciesClone = (checkload) => (dispatch, getState) => {
	if (checkload !== 0) {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: true
		});
	}

	axios.get('/api/admin/list_species_clone', tokenConfig(getState)).then(res => {
		if (checkload !== 0) {
			setTimeout(function(){ 
				dispatch({
					type: 'ADMIN_LOADING_BLOCK',
					payload: false
				});
			}, 500);
		}

		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LOAD_SPECIES_CLONE',
				payload: res.data.data
			});
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const saveAddSpeciesClone = (data, fclosenodal) => (dispatch, getState) => {
	const {namespecies,prices,notes} = data;

	if (namespecies.trim() === '') {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'Tên không được để trống' });
		return false;
	}

	let bodysend = {
		dataform: data
	}

	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});

	axios.post('/api/admin/add_species_clone', bodysend, tokenConfig(getState)).then(res => {
		
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});

		if (res.data.status === 200) {
			fclosenodal();
			dispatch({
				type: 'ADMIN_ADD_SPECIES',
				payload: {id:res.data.data.insertId, name:namespecies, notes:notes, price:prices}
			});

			Swal.fire({ icon: 'success', title: '', text: 'Thêm thành công' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const deleteItemSpeciesClone = (id) => (dispatch, getState) => {
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có muốn xóa loại này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			axios.get('/api/admin/delete_species_clone?id='+id, tokenConfig(getState)).then(res => {
				if (res.data.status === 200) {
					dispatch({
						type: 'ADMIN_DELETE_SPECIES_CLONE',
						payload: id
					});
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Xóa thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	}
  	})
}

export const saveEditSpeciesClone = (data, id_edit, fclosenodal) => (dispatch, getState) => {
	const {namespecies,prices,notes} = data;

	if (namespecies.trim() === '') {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'Tên không được để trống' });
		return false;
	}

	let bodysend = {
		dataform: data
	}

	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});

	axios.post('/api/admin/edit_species_clone', bodysend, tokenConfig(getState)).then(res => {
		
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});

		if (res.data.status === 200) {
			fclosenodal();
			
			dispatch({
				type: 'ADMIN_EDIT_SPECIES_CLONE',
				payload: {id:id_edit, name:namespecies, notes:notes, price:prices},
				id: id_edit
			});

			Swal.fire({ icon: 'success', title: '', text: 'Thêm thành công' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const getListAccClone = () => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});

	axios.get('/api/admin/list_account_clone', tokenConfig(getState)).then(res => {
		
		setTimeout(function(){ 
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}, 500);
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LOAD_ACCOUNT_CLONE',
				payload: res.data.data
			});
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const saveAddAccClone = (data, fclosenodal) => (dispatch, getState) => {
	const {uidfb,password,loaigoi} = data;

	if (uidfb.trim() === '') {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'ID không được để trống' });
		return false;
	}
	if (password.trim() === '') {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'Mật khẩu không được để trống' });
		return false;
	}

	let bodysend = {
		dataform: data
	}

	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});

	axios.post('/api/admin/add_accclone_clone', bodysend, tokenConfig(getState)).then(res => {
		
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});

		if (res.data.status === 200) {
			fclosenodal();
			// dispatch({
				// type: 'ADMIN_ADD_ACC_CLONE',
				// payload: {id:res.data.data.insertId, uid:uidfb, id_loai:parseInt(loaigoi), date_sale: 0, own: 0, name_own: null, create_at:Math.floor(Date.now() / 1000)}
			// });
			dispatch(getListAccClone());

			Swal.fire({ icon: 'success', title: '', text: 'Thêm thành công' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const deleteItemAccClone = (id) => (dispatch, getState) => {
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có muốn xóa tài khoản này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			axios.get('/api/admin/delete_account_clone?id='+id, tokenConfig(getState)).then(res => {
				if (res.data.status === 200) {
					dispatch({
						type: 'ADMIN_DELETE_ACCOUNT_CLONE',
						payload: id
					});
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Xóa thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	}
  	})
}

export const saveEditAccClone = (data, data_select, fclosenodal) => (dispatch, getState) => {
	const {uidfb,loaigoi} = data;

	if (uidfb.trim() === '') {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'uid Không được để trống' });
		return false;
	}

	let bodysend = {
		dataform: data
	}

	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});

	axios.post('/api/admin/edit_account_clone', bodysend, tokenConfig(getState)).then(res => {
		
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});

		if (res.data.status === 200) {
			fclosenodal();
			
			data_select['uid'] = uidfb;
			data_select['id_loai'] = parseInt(loaigoi);
			dispatch({
				type: 'ADMIN_EDIT_ACCOUNT_CLONE',
				payload: data_select,
				id: data_select.id
			});

			Swal.fire({ icon: 'success', title: '', text: 'Thêm thành công' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const loadInfoUser = () => (dispatch, getState) => {
	return axios.get("/api/auth/user", tokenConfig(getState));
};

export const loadConfig2FA = () => (dispatch, getState) => {
	return axios.get("/api/auth/get2fa", tokenConfig(getState));
};

export const saveDataUser = (data) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
	}

	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/admin/user_save_info', bodysend, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: '', text: 'Sửa thành công' });
			window.location.reload();
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', html: res.data.message });
		}
	});
};

export const sendVerifyEmail = (data) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.get('/api/admin/sendverifyemail', tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: '', text: 'Đã gửi xác minh vào mail của bạn! Vui lòng kiểm tra mail!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', html: res.data.message });
		}
	});
};

export const enable2FA = (data) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.get('/api/admin/enable2fa', tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'UPDATE_USERINFO',
				payload: {
					...data,
					// "config2fa": 1,
				}
			})
			dispatch({
				type: 'UPDATE_USERINFO_CONFIG2FA',
				payload: {
					"config2fa_secret": res.data.data.secret,
					"config2fa_uri": res.data.data.uri,
					"config2fa_qr": res.data.data.qr,
				}
			})
			Swal.fire({ icon: 'success', title: '', text: res.data.message });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', html: res.data.message });
		}
	});
};

export const disable2FA = (data) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.get('/api/admin/disable2fa', tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'UPDATE_USERINFO',
				payload: {
					...data,
					"config2fa": 0,
				}
			})
			dispatch({
				type: 'UPDATE_USERINFO_CONFIG2FA',
				payload: {
					"config2fa_secret": "",
					"config2fa_uri": "",
					"config2fa_qr": "",
				}
			})
			Swal.fire({ icon: 'success', title: '', text: res.data.message });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', html: res.data.message });
		}
	});
};

export const confirm2FA = (data, maxacminh) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	let bodysend = {
		maxacminh: maxacminh,
	}
	axios.post('/api/admin/confirm2fa', bodysend, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'UPDATE_USERINFO',
				payload: {
					...data,
					"config2fa": 1,
				}
			})
			Swal.fire({ icon: 'success', title: '', text: res.data.message });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', html: res.data.message });
		}
	});
};

export const disableAdmin2FA = (id_user) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	let bodysend = {
		id_user: id_user,
	}
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn muốn tắt mã 2FA cho tài khoản này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
	  		axios.post('/api/admin/disableadmin2fa', bodysend, tokenConfig(getState)).then(res => {
				dispatch({
					type: 'ADMIN_LOADING_BLOCK',
					payload: false
				});
				if (res.data.status === 200) {
					Swal.fire({ icon: 'success', title: '', text: res.data.message });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', html: res.data.message });
				}
			});
	  	} else {
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}
  	})
};

export const getDataPriceAllFunction = (obj_search = '') => (dispatch, getState) => {
	let param_url = '';
	if (obj_search != '') {
		param_url += '?obj_search='+obj_search;
	}
	axios.get("/api/admin/price_all_function"+param_url, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LIST_PRICE_ALL_FUNCTION',
				payload: res.data.data,
			});
		}
	});
};

export const deleteItemPriceFunc = (id_delete) => (dispatch, getState) => {
	let bodysend = {
		id: id_delete
	};
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có muốn xóa loại này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			axios.post("/api/admin/price_all_function/delete", bodysend, tokenConfig(getState)).then(res => {
				if (res.data.status === 200) {
					Swal.fire({ icon: 'success', title: '', text: 'Xóa thành công' });
					dispatch({
						type: 'ADMIN_DELETE_PRICE_FUNCTION',
						payload: id_delete,
					});
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
		}
	});
}

export const saveAddPriceFunction = (data, closemodal) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
	}

	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/admin/price_all_function/add', bodysend, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: '', text: 'Thêm thành công!' });
			let {name, nameusertype, price_bh_api,price_bh_web,start_price_web,end_price_api,start_price_api} = data;
			// dispatch({
				// type: 'ADMIN_ADD_PRICE_FUNCTION',
				// payload: {id: res.data.data.insertId, name: name.replace('&nbsp;', '').trim(), name_types_user: nameusertype.replace('&nbsp;', '').trim(),min_bh_api: price_bh_api, min_bh_web: price_bh_web, prices_web: start_price_web, prices_api_min:start_price_api, prices_api_max:end_price_api},
			// });
			dispatch(getDataPriceAllFunction());
			closemodal();
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
			closemodal();
		}
	});
}

export const saveAddPriceSpeedFunction = (data, closemodal) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
	}

	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	html: "Thêm tự động sẽ xóa hết giá cũ của loại tài khoản <span class=\"text-danger text-uppercase\">"+data.nametype+"</span> và thêm lại tất cả giá và cộng thêm <span class=\"text-danger text-uppercase\">"+data.percent+"%</span> so với giá site gốc. Bạn có chắc chắn về điều này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			axios.post('/api/admin/price_all_function/add_speed', bodysend, tokenConfig(getState)).then(res => {
				dispatch({
					type: 'ADMIN_LOADING_BLOCK',
					payload: false
				});
				dispatch(getDataPriceAllFunction());
				if (res.data.status === 200) {
					Swal.fire({ icon: 'success', title: '', text: 'Thêm thành công!' });
					closemodal();
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
					closemodal();
				}
			});
		} else {
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}
	});
}

export const saveEditPriceFunction = (data, closemodal) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
	}

	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/admin/price_all_function/edit', bodysend, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: '', text: 'Sửa thành công!' });
			let {id_edit,price_bh_api,price_bh_web,start_price_web,end_price_api,start_price_api} = data;
			// dispatch({
				// type: 'ADMIN_EDIT_PRICE_FUNCTION',
				// payload: {id: id_edit, min_bh_api: price_bh_api, min_bh_web: price_bh_web, prices_web: start_price_web, prices_api_min:start_price_api, prices_api_max:end_price_api},
			// });
			dispatch(getDataPriceAllFunction());
			closemodal();
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
			closemodal();
		}
	});
}

export const saveAdminEditDataUser = (data, closemodal) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
	}
	axios.post('/api/admin/admin_edit_user', bodysend, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: '', text: 'Sửa thành công!' });
			dispatch({
				type: 'ADMIN_EDIT_NEW_DATA_USER',
				payload: {id: data.id_user,type: data.type_user,name: data.nametype},
			});
			closemodal();
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
			closemodal();
		}
	});
}

export const saveAddTypeUser = (data) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
	}

	axios.post('/api/admin/admin_add_type_user', bodysend, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: '', text: 'Thêm thành công!' });
			dispatch({
				type: 'ADMIN_ADD_USER_TYPE_NEW',
				payload: {id: res.data.data,notes: data.note, name: data.name},
			});
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const saveEditItemStatusUser = (data) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
	}
	axios.post('/api/admin/admin_edit_type_user', bodysend, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: '', text: 'Sửa thành công!' });
			// dispatch({
				// type: 'ADMIN_EDIT_USER_TYPE_NEW',
				// payload: {id: data.id,notes: data.notes, name: data.name},
			// });
			dispatch(getListUser());
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const saveEditBankPayment = (data,closemodal) => (dispatch, getState) => {
	let bodysend = {
		dataform: data,
	}
	axios.post('/api/admin/admin_edit_bankpayment', bodysend, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			closemodal();
			dispatch({
				type: 'EDIT_ITEM_BANK_PAYMENT',
				payload: {id: data.id, name: data.namebank,account_name: data.name_account,account_code: data.id_account,branch: data.branch}
			});
			Swal.fire({ icon: 'success', title: '', text: 'Sửa thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
			closemodal();
		}
	});
}

export const saveAddBankPayment = (data,closemodal) => (dispatch, getState) => {
	axios.post('/api/admin/admin_add_bankpayment', data, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			
			dispatch({
				type: 'ADD_ITEM_BANK_PAYMENT',
				payload: {id: res.data.insertId, noidung: data.noidung,url_banking: data.url_banking,cookie_banking: data.cookie_banking,data_banking: data.data_banking,tile: data.tile,minmoney: data.minmoney}
			});
			Swal.fire({ icon: 'success', title: '', text: 'Cập nhật thành công phương thức thanh toán!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}
export const saveAddSuppot = (data,closemodal) => (dispatch, getState) => {
	axios.post('/api/admin/admin_add_support', data, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'EDIT_ITEM_SUPPORT',
				payload: {id: res.data.data.insertId, link_facebook: data.link_facebook, link_zalo: data.link_zalo, link_telegram: data.link_telegram, link_skype: data.link_skype, link_other: data.link_other, phone_1: data.phone_1, phone_2: data.phone_2}
			});
			Swal.fire({ icon: 'success', title: '', text: 'Cập nhật thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const addFeature = (data) => (dispatch, getState) => {
	axios.post('/api/admin/addfeature', data, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			dispatch(getDataFeature(9999, ''));
			Swal.fire({ icon: 'success', title: '', text: 'Lưu tính năng thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}
export const editFeature = (data) => (dispatch, getState) => {
	axios.post('/api/admin/editfeature', data, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			dispatch(getDataFeature(9999, ''));
			Swal.fire({ icon: 'success', title: '', text: 'Cập nhật tính năng thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const deleteFeature = (id) => (dispatch, getState) => {
	var data = {
		id: id
	}
	axios.post('/api/admin/deletefeature', data, tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			dispatch(getDataFeature(9999, ''));
			Swal.fire({ icon: 'success', title: '', text: 'Xóa tính năng thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const listSupport = () => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true,
	});
	axios.get('/api/admin/list_support', tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			if (res.data.data.length > 0) {
				var data = res.data.data[0];
				dispatch({
					type: 'LIST_DATA_SUPPORT',
					payload: data,
				});
			}
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false,
			});
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: 'không lấy được dữ liệu!' });
		}
	});
}

export const listBankPayment = () => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true,
	});
	axios.get('/api/admin/list_bankpayment', tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			if (res.data.data.length > 0) {
				var data = res.data.data[0];
				dispatch({
					type: 'LIST_DATA_BANK_PAYMENT',
					payload: data,
				});
			}
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false,
			});
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: 'không lấy được dữ liệu!' });
		}
	});
}

export const editbankpayment = (data) =>(dispatch, getState) =>{
	dispatch({
		type: 'EDIT_ITEM_BANK_PAYMENT',
		payload: data,
	});
}
export const editconfigsupport = (data) =>(dispatch, getState) =>{
	dispatch({
		type: 'EDIT_ITEM_SUPPORT',
		payload: data,
	});
}

export const deleteItemBankPayment = (id) => (dispatch, getState) => {
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có chắc chắn muốn xóa bank này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			axios.get('/api/admin/delete_bankpayment?id='+id, tokenConfig(getState)).then(res => {
				if (res.data.status === 200) {
					dispatch({
						type: 'DELETE_ITEM_BANK_PAYMENT',
						payload: id,
					});
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: 'không lấy được dữ liệu!' });
				}
			});
	  	}
	})
}

export const getAllDataPriceFunction = () => (dispatch, getState) => {
	axios.get('/api/admin/get_all_price_function/', tokenConfig(getState)).then(res => {
		let arPrice = res.data.data;
		
		arPrice.push({checktime: parseInt(Date.now() / 1000)});
        localStorage.setItem("listDataPriceAll", JSON.stringify(arPrice));
		
		if (res.data.status === 200) {
			if (arPrice.length > 0) {
				// add price like comment share
				let datalks = res.data.data;
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFFSUB_LCS',
					payload: datalks
				});

				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BFVIEWVIDEO_YTB',
					payload: datalks
				});
				// end add price like comment share


				// buff sub slow
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFF_SUB_SLOW',
					payload: datalks
				});

				// buff sub sale
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFF_SUB_SALE',
					payload: datalks
				});

				// add price tik tok

				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_TIKTOK_BUFF',
					payload: datalks
				});
				dispatch({
					type: 'LOAD_PRICE_VIP_LIKE_PRO',
					payload: datalks
				});
				
				// add price sublikev2
				dispatch({
					type: 'LOAD_PRICE_BSLV',
					payload: datalks
				});
				
				// add price likesubcomment
				dispatch({
					type: 'LOAD_PRICE_BLSC',
					payload: datalks
				});
				
				// add price sublikev2
				dispatch({
					type: 'LOAD_PRICE_BGSL',
					payload: datalks
				});

				// vip like clone
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFF_VIP_CLONE',
					payload: datalks
				});
				
				// vip like group
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFF_VIP_GROUP',
					payload: datalks
				});

				// buff like fanapge
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFF_LIKE_FANPAGE',
					payload: datalks
				});
				
				// buff like fanapge sale
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFF_LIKE_FANPAGE_SALE',
					payload: datalks
				});
				
				// buff sub fanapge
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFF_SUB_FANPAGE',
					payload: datalks
				});

				// buff sub instagram
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFFSUB_IST',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_BSLIO',
					payload: datalks
				});

				dispatch({
					type: 'LOAD_PRICE_BSCIO',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_BSVIO',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_YV',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_YGX',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_YL',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_YDL',
					payload: datalks
				});

				dispatch({
					type: 'LOAD_PRICE_YTNS',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_YLC',
					payload: datalks
				});

				dispatch({
					type: 'LOAD_PRICE_YC',
					payload: datalks
				});

				dispatch({
					type: 'LOAD_PRICE_BMLS',
					payload: datalks
				});
				
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFF_SUB',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_BG',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_GM',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_IVL',
					payload: datalks
				});
				
				// facebookvipmatlivestream
				dispatch({
					type: 'LOAD_PRICE_FVMLS',
					payload: datalks
				});
				
				// facebookvipmatlivestreamv2
				dispatch({
					type: 'LOAD_PRICE_FVMLSV',
					payload: datalks
				});
				
				// buffgrouptele
				dispatch({
					type: 'LOAD_PRICE_BGT',
					payload: datalks
				});
				
				// buff sub youtube
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_BUFFSUB_YTB',
					payload: datalks
				});
				
				// buffviewstory
				dispatch({
					type: 'LOAD_PRICE_BVS',
					payload: datalks
				});
				
				// facebook ad breaks gio xem
				dispatch({
					type: 'LOAD_PRICE_FABGX',
					payload: datalks
				});
				
				// facebook ad breaks tuong tac
				dispatch({
					type: 'LOAD_PRICE_FABTT',
					payload: datalks
				});
				
				// facebook ad breaks view de xuat
				dispatch({
					type: 'LOAD_PRICE_FABVDX',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_BSR',
					payload: datalks
				});
				
				// twitter like
				dispatch({
					type: 'LOAD_PRICE_TWL',
					payload: datalks
				});
				
				// twitter follow
				dispatch({
					type: 'LOAD_PRICE_TWF',
					payload: datalks
				});
				
				// spotify follow
				dispatch({
					type: 'LOAD_PRICE_STFF',
					payload: datalks
				});
				
				// spotify play
				dispatch({
					type: 'LOAD_PRICE_STFP',
					payload: datalks
				});
				
				// soundcloud follow
				dispatch({
					type: 'LOAD_PRICE_SCF',
					payload: datalks
				});
				
				// soundcloud play
				dispatch({
					type: 'LOAD_PRICE_SCP',
					payload: datalks
				});
				
				// soundcloud like
				dispatch({
					type: 'LOAD_PRICE_SCL',
					payload: datalks
				});
				
				// vimeo follow
				dispatch({
					type: 'LOAD_PRICE_VF',
					payload: datalks
				});
				
				// vimeo view
				dispatch({
					type: 'LOAD_PRICE_VV',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_FVC',
					payload: datalks
				});
				
				dispatch({
					type: 'LOAD_PRICE_THUESIM',
					payload: datalks
				});
				
				arPrice.forEach(function(v, i){
					
					// buff sub youtube
					// if (v.name_table === "youtube_buffsub") {
						// let dataPrice = v;
						// let dataFormOld = getState().buffsubyoutube.formData;
						// dispatch({
							// type: 'CHANGE_DATA_FORM_YTB_BUFFSUB',
							// payload: {
								// ...dataFormOld,
								// giatien: dataPrice.prices_web
							// }
						// });
						// dispatch({
							// type: 'DATA_PRICE_IS_FUNCTION_BUFFSUB_YTB',
							// payload: dataPrice
						// });
					// }

					if (v.name_table === "buffchocbanbe") {
						let dataPrice = v;
						dispatch({
							type: 'DATA_PRICE_IS_FUNCTION_CHOCBANBE_YTB',
							payload: dataPrice
						});
					}

					if (v.name_table === "bufffbvipbotlive") {
						dispatch({
							type: 'DATA_PRICE_IS_FUNCTION_VIPBOTLIVE',
							payload: v
						});
					}

					if (v.name_table === 'viplikethang') {
						dispatch({
							type: 'LOAD_PRICE_VLT',
							payload: v
						});
					}

					if (v.name_table === 'vipcommentthang') {
						dispatch({
							type: 'LOAD_PRICE_VBLT',
							payload: v
						});
					}

					if (v.name_table === 'facebookvipviewvideo') {
						dispatch({
							type: 'LOAD_PRICE_FVVV',
							payload: v
						});
					}

					if (v.name_table === 'facebooklocbanbekhongtuongtac') {
						dispatch({
							type: 'LOAD_PRICE_FLBBKTT',
							payload: v
						});
					}

					if (v.name_table === 'facebookbot') {
						dispatch({
							type: 'LOAD_PRICE_FBB',
							payload: v
						});
					}

					if (v.name_table === 'facebookbotreplyinboxcho') {
						dispatch({
							type: 'LOAD_PRICE_FBRIC',
							payload: v
						});
					}

					if (v.name_table === 'facebookbotv2') {
						dispatch({
							type: 'LOAD_PRICE_FBBV',
							payload: v
						});
					}

					if (v.name_table === 'facebookbotlovestory') {
						dispatch({
							type: 'LOAD_PRICE_FBBLS',
							payload: v
						});
					}

					if (v.name_table === 'facebookbotcmt') {
						dispatch({
							type: 'LOAD_PRICE_FBBC',
							payload: v
						});
					}

					if (v.name_table === 'shopee_sub') {
						dispatch({
							type: 'LOAD_PRICE_SHOPEE',
							payload: v
						});
					}
					
					if (v.name_table === 'shopee_tim') {
						dispatch({
							type: 'LOAD_PRICE_SHOPEETIM',
							payload: v
						});
					}
					
					if (v.name_table === 'shopee_seedinglivestream') {
						dispatch({
							type: 'LOAD_PRICE_SHOPEESEEDINGLIVESTREAM',
							payload: v
						});
					}
					
					if (v.name_table === 'shopee_viplivestream') {
						dispatch({
							type: 'LOAD_PRICE_SHOPEEVIPLIVESTREAM',
							payload: v
						});
					}
					
					if (v.name_table === 'spamsms') {
						dispatch({
							type: 'LOAD_PRICE_SPAMSMS',
							payload: v
						});
					}

					if (v.name_table === 'spamcall') {
						dispatch({
							type: 'LOAD_PRICE_SPAMCALL',
							payload: v
						});
					}
					
					if (v.name_table === 'emailtouid') {
						dispatch({
							type: 'LOAD_PRICE_EMAILTOUID',
							payload: v
						});
					}
					
					if (v.name_table === 'ripacc') {
						dispatch({
							type: 'LOAD_PRICE_RIPACC',
							payload: v
						});
					}
					
					if (v.name_table === 'reportyoutube') {
						dispatch({
							type: 'LOAD_PRICE_REPORTYOUTUBE',
							payload: v
						});
					}
					
					if (v.name_table === 'renamefanpage') {
						dispatch({
							type: 'LOAD_PRICE_RNF',
							payload: v
						});
					}
					
					if (v.name_table === 'muafanpage') {
						dispatch({
							type: 'LOAD_PRICE_MF',
							payload: v
						});
					}
				});
			}
		}
	});
}

export const getAllDataPriceFunctionFromCache = () => (dispatch, getState) => {
	let arPrice = JSON.parse(localStorage.getItem("listDataPriceAll"));
	if (arPrice.length > 0) {
		// add price like comment share
		let datalks = JSON.parse(localStorage.getItem("listDataPriceAll"));
		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BUFFSUB_LCS',
			payload: datalks
		});

		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BFVIEWVIDEO_YTB',
			payload: datalks
		});
		// end add price like comment share


		// buff sub slow
		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BUFF_SUB_SLOW',
			payload: datalks
		});

		// buff sub sale
		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BUFF_SUB_SALE',
			payload: datalks
		});
		
		// add price tik tok

		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_TIKTOK_BUFF',
			payload: datalks
		});
		dispatch({
			type: 'LOAD_PRICE_VIP_LIKE_PRO',
			payload: datalks
		});
		
		// add price sublikev2
		dispatch({
			type: 'LOAD_PRICE_BSLV',
			payload: datalks
		});
		
		// add price likesubcomment
		dispatch({
			type: 'LOAD_PRICE_BLSC',
			payload: datalks
		});
		
		// add price sublikev2
		dispatch({
			type: 'LOAD_PRICE_BGSL',
			payload: datalks
		});

		// vip like clone
		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BUFF_VIP_CLONE',
			payload: datalks
		});
		
		// vip like group
		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BUFF_VIP_GROUP',
			payload: datalks
		});

		// buff like fanapge
		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BUFF_LIKE_FANPAGE',
			payload: datalks
		});
				
		// buff like fanapge sale
		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BUFF_LIKE_FANPAGE_SALE',
			payload: datalks
		});
		
		// buff sub fanapge
		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BUFF_SUB_FANPAGE',
			payload: datalks
		});

		// buff sub instagram
		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BUFFSUB_IST',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_BSLIO',
			payload: datalks
		});

		dispatch({
			type: 'LOAD_PRICE_BSCIO',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_BSVIO',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_YV',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_YGX',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_YL',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_YDL',
			payload: datalks
		});

		dispatch({
			type: 'LOAD_PRICE_YTNS',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_YLC',
			payload: datalks
		});

		dispatch({
			type: 'LOAD_PRICE_YC',
			payload: datalks
		});

		dispatch({
			type: 'LOAD_PRICE_BMLS',
			payload: datalks
		});
		
		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BUFF_SUB',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_BG',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_GM',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_IVL',
			payload: datalks
		});
		
		// facebookvipmatlivestream
		dispatch({
			type: 'LOAD_PRICE_FVMLS',
			payload: datalks
		});
		
		// facebookvipmatlivestreamv2
		dispatch({
			type: 'LOAD_PRICE_FVMLSV',
			payload: datalks
		});

		// buffgrouptele
		dispatch({
			type: 'LOAD_PRICE_BGT',
			payload: datalks
		});
		
		// buff sub youtube
		dispatch({
			type: 'DATA_PRICE_IS_FUNCTION_BUFFSUB_YTB',
			payload: datalks
		});
		
		// buffviewstory
		dispatch({
			type: 'LOAD_PRICE_BVS',
			payload: datalks
		});
		
		// facebook ad breaks gio xem
		dispatch({
			type: 'LOAD_PRICE_FABGX',
			payload: datalks
		});
		
		// facebook ad breaks tuong tac
		dispatch({
			type: 'LOAD_PRICE_FABTT',
			payload: datalks
		});
		
		// facebook ad breaks view de xuat
		dispatch({
			type: 'LOAD_PRICE_FABVDX',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_BSR',
			payload: datalks
		});
		
		// twitter like
		dispatch({
			type: 'LOAD_PRICE_TWL',
			payload: datalks
		});
		
		// twitter follow
		dispatch({
			type: 'LOAD_PRICE_TWF',
			payload: datalks
		});
		
		// spotify follow
		dispatch({
			type: 'LOAD_PRICE_STFF',
			payload: datalks
		});
		
		// spotify play
		dispatch({
			type: 'LOAD_PRICE_STFP',
			payload: datalks
		});
		
		// soundcloud follow
		dispatch({
			type: 'LOAD_PRICE_SCF',
			payload: datalks
		});
		
		// soundcloud play
		dispatch({
			type: 'LOAD_PRICE_SCP',
			payload: datalks
		});
		
		// soundcloud like
		dispatch({
			type: 'LOAD_PRICE_SCL',
			payload: datalks
		});
		
		// vimeo follow
		dispatch({
			type: 'LOAD_PRICE_VF',
			payload: datalks
		});
		
		// vimeo view
		dispatch({
			type: 'LOAD_PRICE_VV',
			payload: datalks
		});

		dispatch({
			type: 'LOAD_PRICE_FVC',
			payload: datalks
		});
		
		dispatch({
			type: 'LOAD_PRICE_THUESIM',
			payload: datalks
		});
		
		arPrice.forEach(function(v, i){
			
			// buff sub youtube
			// if (v.name_table === "youtube_buffsub") {
				// let dataPrice = v;
				// let dataFormOld = getState().buffsubyoutube.formData;
				// dispatch({
					// type: 'CHANGE_DATA_FORM_YTB_BUFFSUB',
					// payload: {
						// ...dataFormOld,
						// giatien: dataPrice.prices_web
					// }
				// });
				// dispatch({
					// type: 'DATA_PRICE_IS_FUNCTION_BUFFSUB_YTB',
					// payload: dataPrice
				// });
			// }

			if (v.name_table === "buffchocbanbe") {
				let dataPrice = v;
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_CHOCBANBE_YTB',
					payload: dataPrice
				});
			}

			if (v.name_table === "bufffbvipbotlive") {
				dispatch({
					type: 'DATA_PRICE_IS_FUNCTION_VIPBOTLIVE',
					payload: v
				});
			}

			if (v.name_table === 'viplikethang') {
				dispatch({
					type: 'LOAD_PRICE_VLT',
					payload: v
				});
			}

			if (v.name_table === 'vipcommentthang') {
				dispatch({
					type: 'LOAD_PRICE_VBLT',
					payload: v
				});
			}

			if (v.name_table === 'facebookvipviewvideo') {
				dispatch({
					type: 'LOAD_PRICE_FVVV',
					payload: v
				});
			}

			if (v.name_table === 'facebooklocbanbekhongtuongtac') {
				dispatch({
					type: 'LOAD_PRICE_FLBBKTT',
					payload: v
				});
			}

			if (v.name_table === 'facebookbot') {
				dispatch({
					type: 'LOAD_PRICE_FBB',
					payload: v
				});
			}

			if (v.name_table === 'facebookbotreplyinboxcho') {
				dispatch({
					type: 'LOAD_PRICE_FBRIC',
					payload: v
				});
			}

			if (v.name_table === 'facebookbotv2') {
				dispatch({
					type: 'LOAD_PRICE_FBBV',
					payload: v
				});
			}

			if (v.name_table === 'facebookbotlovestory') {
				dispatch({
					type: 'LOAD_PRICE_FBBLS',
					payload: v
				});
			}

			if (v.name_table === 'facebookbotcmt') {
				dispatch({
					type: 'LOAD_PRICE_FBBC',
					payload: v
				});
			}

			if (v.name_table === 'shopee_sub') {
				dispatch({
					type: 'LOAD_PRICE_SHOPEE',
					payload: v
				});
			}
			
			if (v.name_table === 'shopee_tim') {
				dispatch({
					type: 'LOAD_PRICE_SHOPEETIM',
					payload: v
				});
			}
			
			if (v.name_table === 'shopee_seedinglivestream') {
				dispatch({
					type: 'LOAD_PRICE_SHOPEESEEDINGLIVESTREAM',
					payload: v
				});
			}
			
			if (v.name_table === 'shopee_viplivestream') {
				dispatch({
					type: 'LOAD_PRICE_SHOPEEVIPLIVESTREAM',
					payload: v
				});
			}
			
			if (v.name_table === 'spamsms') {
				dispatch({
					type: 'LOAD_PRICE_SPAMSMS',
					payload: v
				});
			}

			if (v.name_table === 'spamcall') {
				dispatch({
					type: 'LOAD_PRICE_SPAMCALL',
					payload: v
				});
			}
			
			if (v.name_table === 'emailtouid') {
				dispatch({
					type: 'LOAD_PRICE_EMAILTOUID',
					payload: v
				});
			}
			
			if (v.name_table === 'ripacc') {
				dispatch({
					type: 'LOAD_PRICE_RIPACC',
					payload: v
				});
			}
			
			if (v.name_table === 'reportyoutube') {
				dispatch({
					type: 'LOAD_PRICE_REPORTYOUTUBE',
					payload: v
				});
			}
			
			if (v.name_table === 'renamefanpage') {
				dispatch({
					type: 'LOAD_PRICE_RNF',
					payload: v
				});
			}
			
			if (v.name_table === 'muafanpage') {
				dispatch({
					type: 'LOAD_PRICE_MF',
					payload: v
				});
			}
		});
	}
}

export const getPriceMedium = () => (dispatch, getState) => {
	axios.get('/api/facebook_buff/get_price_medium/', tokenConfig(getState)).then(res => {
		let arrPrice = res.data.data;
		if (res.data.status === 200) {
			if (arrPrice.length > 0) {
				let arrResRedux = {};
				arrPrice.forEach(function(v, i){
					if (v.param === 'like') {
						arrResRedux['like'] = v;
					} else if (v.param === 'comment') {
						arrResRedux['comment'] = v;
					} else if (v.param === 'share') {
						arrResRedux['share'] = v;
					} else if (v.param === 'buff_sub') {
						arrResRedux['buff_sub'] = v;
					}
				});
				dispatch({
					type: 'LOAD_PRICE_MEDIUM_SITE',
					payload: arrResRedux
				});
			}
		}
	});
}

export const getTokenSiteBase = () => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	return axios.get('/api/admin/gettokenbase/', tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			if (res.data.data.length > 0) {
				dispatch({
					type: 'DATA_TOKEN_BASE_SITE',
					payload: res.data.data[0].token
				});
			}
		}

		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}

export const saveTokenSiteBase = (token) => (dispatch, getState) => {
	let data = {
		token: token,
	};
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/admin/savetokenbase/', data, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: 'Thành công', text: 'Sửa thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const getlistBankInfoAdmin = (data) => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.get('/api/admin/list_bankinfo', tokenConfig(getState)).then(res => {
		
		setTimeout(function(){ 
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}, 500);
		if (res.data.status === 200) {
			dispatch({
				type: 'ADMIN_LOAD_LIST_BANKINFO',
				payload: res.data.data
			});
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const saveCreateBankInfo = (sotaikhoan, tentaikhoan, nganhang, chinhanh, gionapnhanh, fclosenodal) => (dispatch, getState) => {
	if (sotaikhoan.trim() === '' || tentaikhoan.trim() === '' || nganhang.trim() === '' || chinhanh.trim() === '') {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: 'Không được để trống trường nào!' });
		return false;
	}

	let bodysend = {
		sotaikhoan: sotaikhoan,
		tentaikhoan: tentaikhoan,
		nganhang: nganhang,
		chinhanh: chinhanh,
		gionapnhanh: gionapnhanh,
	}
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});

	axios.post('/api/admin/add_bankinfo', bodysend, tokenConfig(getState)).then(res => {
		
		setTimeout(function(){ 
			dispatch({
				type: 'ADMIN_LOADING_BLOCK',
				payload: false
			});
		}, 500);

		if (res.data.status === 200) {
			fclosenodal();

			dispatch({
				type: 'ADMIN_ADD_BANKINFO',
				payload: {id: res.data.data.insertId, sotaikhoan: sotaikhoan, tentaikhoan: tentaikhoan, nganhang: nganhang, chinhanh: chinhanh, gionapnhanh: gionapnhanh}
			});

			Swal.fire({ icon: 'success', title: '', text: 'Thêm thành công' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
};

export const deleteItemBankInfo = (id) => (dispatch, getState) => {
	Swal.fire({
	  	title: 'Bạn có chắc?',
	  	text: "Bạn có muốn xóa bank info này?",
	  	icon: 'warning',
	  	showCancelButton: true,
	  	confirmButtonColor: '#3085d6',
	  	cancelButtonColor: '#d33',
	  	confirmButtonText: 'Đồng ý',
	  	cancelButtonText: 'Không'
	}).then((result) => {
	  	if (result.value) {
			axios.get('/api/admin/delete_bankinfo?id='+id, tokenConfig(getState)).then(res => {
				if (res.data.status === 200) {
					dispatch({
						type: 'ADMIN_DELETE_BANKINFO',
						payload: id
					});
					Swal.fire({ icon: 'success', title: 'Thành công', text: 'Xóa thành công!' });
				} else {
					Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
				}
			});
	  	}
  	})
}

export const getID = (body) => (dispatch, getState) => {
	let bodysend = {
		id_user: getState().auth.user.id,
		link: body.idfacebook_user
	};
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/checklinkfb/check/', bodysend, tokenConfig(getState))
	.then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			dispatch({
				type: 'UPDATE_USERINFO_IDFB',
				payload: res.data.id
			});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: res.data.msg,
			});
		}
	}).catch(err => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		openBtComfirmAlert('Có lỗi xảy ra!', 'error', 'Lỗi');
    });
};

export const updateUserInfo = (body) => (dispatch) => {
	dispatch({
		type: 'UPDATE_USERINFO',
		payload: body
	})
};

export const updateConfig2FAData = (body) => (dispatch) => {
	dispatch({
		type: 'UPDATE_USERINFO_CONFIG2FA',
		payload: body
	})
};

export const updateNapTienThe = (body) => (dispatch) => {
	dispatch({
		type: 'UPDATE_NAPTIENTHE',
		payload: body
	})
};

export const addNapTienThe = (body) => (dispatch, getState) => {
	let bodysend = {
		loaithe: body.loaithe,
		menhgia: body.menhgia,
		seri: body.seri,
		mathe: body.mathe,
	};
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/auth/naptienthe', bodysend, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: 'Thành công', text: res.data.message });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
};

export const getAPITrumThe = () => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	return axios.get('/api/admin/getapitrumthe', tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'DATA_API_TRUMTHE',
				payload: res.data.data
			});
		}

		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}

export const getAPITrumTheFront = () => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	return axios.get('/api/admin/getapitrumthefront', tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'DATA_API_TRUMTHE',
				payload: res.data.data
			});
		}

		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}

export const checkAPITrumThe = () => (dispatch, getState) => {
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	return axios.get('/api/admin/checkapitrumthe', tokenConfig(getState)).then(res => {
		if (res.data.status === 200) {
			dispatch({
				type: 'DATA_CHECK_API_TRUMTHE',
				payload: res.data.data
			});
		}

		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
	});
}

export const saveAPITrumThe = (servernapthe, api_key_trumthe247, api_secret_trumthe247, chietkhau_thap_trumthe247, chietkhau_cao_trumthe247) => (dispatch, getState) => {
	if (parseInt(chietkhau_thap_trumthe247) < 34 || parseInt(chietkhau_thap_trumthe247) > 100 || !Number.isInteger(parseInt(chietkhau_thap_trumthe247))) {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: "Chiết khấu (%) thẻ mệnh giá thấp: 10k, 20k, 30k: thấp nhất là 34% và cao nhất là 100%" });
		return false;
	}
	if (parseInt(chietkhau_cao_trumthe247) < 31 || parseInt(chietkhau_cao_trumthe247) > 100 || !Number.isInteger(parseInt(chietkhau_cao_trumthe247))) {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: "Chiết khấu (%) thẻ mệnh giá thấp: 50k trở lên: thấp nhất là 31% và cao nhất là 100%" });
		return false;
	}
	let data = {
		servernapthe: servernapthe,
		api_key_trumthe247: api_key_trumthe247,
		api_secret_trumthe247: api_secret_trumthe247,
		chietkhau_thap_trumthe247: chietkhau_thap_trumthe247,
		chietkhau_cao_trumthe247: chietkhau_cao_trumthe247,
	};
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/admin/saveapitrumthe', data, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: 'Thành công', text: 'Sửa thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const saveAPITheSieuRe = (servernapthe, partner_id_thesieure, partner_key_thesieure, vi_thesieure, chietkhau_thap_thesieure, chietkhau_cao_thesieure) => (dispatch, getState) => {
	if (parseInt(chietkhau_thap_thesieure) < 1 || parseInt(chietkhau_thap_thesieure) > 100 || !Number.isInteger(parseInt(chietkhau_thap_thesieure))) {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: "Chiết khấu (%) thẻ mệnh giá thấp: 10k, 20k, 30k: thấp nhất là 1% và cao nhất là 100%" });
		return false;
	}
	if (parseInt(chietkhau_cao_thesieure) < 1 || parseInt(chietkhau_cao_thesieure) > 100 || !Number.isInteger(parseInt(chietkhau_cao_thesieure))) {
		Swal.fire({ icon: 'error', title: 'Lỗi', text: "Chiết khấu (%) thẻ mệnh giá thấp: 50k trở lên: thấp nhất là 1% và cao nhất là 100%" });
		return false;
	}
	let data = {
		servernapthe: servernapthe,
		partner_id_thesieure: partner_id_thesieure,
		partner_key_thesieure: partner_key_thesieure,
		vi_thesieure: vi_thesieure,
		chietkhau_thap_thesieure: chietkhau_thap_thesieure,
		chietkhau_cao_thesieure: chietkhau_cao_thesieure,
	};
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/admin/saveapitrumthe', data, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: 'Thành công', text: 'Sửa thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const saveChenhLech = (chenhlech_5, chenhlech_5_20, chenhlech_20_50, chenhlech_50_100, chenhlech_100) => (dispatch, getState) => {
	let data = {
		chenhlech_5: chenhlech_5,
		chenhlech_5_20: chenhlech_5_20,
		chenhlech_20_50: chenhlech_20_50,
		chenhlech_50_100: chenhlech_50_100,
		chenhlech_100: chenhlech_100,
	};
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/admin/savechenhlech/', data, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		if (res.data.status === 200) {
			Swal.fire({ icon: 'success', title: 'Thành công', text: 'Sửa thành công!' });
		} else {
			Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.message });
		}
	});
}

export const getChenhLech = (chenhlech) => (dispatch, getState) => {
	let data = {
		chenhlech: chenhlech,
	};
	dispatch({
		type: 'ADMIN_LOADING_BLOCK',
		payload: true
	});
	axios.post('/api/admin/getchenhlech/', data, tokenConfig(getState)).then(res => {
		dispatch({
			type: 'ADMIN_LOADING_BLOCK',
			payload: false
		});
		dispatch({
			type: 'ADMIN_GET_CHENHLECH',
			payload: res.data
		});
	});
}

export const updateChenhLech = (name_chenhlech, chenhlech) => (dispatch, getState) => {
	if (name_chenhlech == 'chenhlech_5') {
		dispatch({
			type: 'ADMIN_CHANGE_CHENHLECH_5',
			payload: chenhlech
		});
	}
	if (name_chenhlech == 'chenhlech_5_20') {
		dispatch({
			type: 'ADMIN_CHANGE_CHENHLECH_5_20',
			payload: chenhlech
		});
	}
	if (name_chenhlech == 'chenhlech_20_50') {
		dispatch({
			type: 'ADMIN_CHANGE_CHENHLECH_20_50',
			payload: chenhlech
		});
	}
	if (name_chenhlech == 'chenhlech_50_100') {
		dispatch({
			type: 'ADMIN_CHANGE_CHENHLECH_50_100',
			payload: chenhlech
		});
	}
	if (name_chenhlech == 'chenhlech_100') {
		dispatch({
			type: 'ADMIN_CHANGE_CHENHLECH_100',
			payload: chenhlech
		});
	}
}

export const openBtComfirmAlert = (message, type, title = '') => {
	Swal.fire({
		icon: type,
		title: title,
		text: message,
	});
	return false;
}

export const tokenConfig = getState => {
	// Get token from localhost
	const token = getState().auth.token;
	
	// Headers
	const config = {
		headers: {
			"Content-type": "application/json"
		}
	};
	
	// If token, add to headers
	if (token) {
		config.headers['ht-token'] = token;
	}
	
	return config;
};