import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import { updateBuffviewstory, addBuffviewstory, getConfigMoDong } from '../../../actions/buffviewstoryActions';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class taotientrinh extends Component {
	static propTypes =  {
		updateBuffviewstory: PropTypes.func.isRequired,
		addBuffviewstory: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceFLBBKTT();
		this.props.getConfigMoDong();
	};
	
    getIDFacebook(t) {
        let e = null;
        if (t) {
            if (t.indexOf("facebook.com/stories") < 0) e = [t];
            else {
                var a = t.match(/(.*)\/([0-9]{8,})\/(.*)\//),
					d = t.match(/(.*)\/([0-9]{8,})\/(.*)\?/),
					c = t.match(/(.*)\/([0-9]{8,})\/(.*)/),
					b = t.match(/(.*)\/([0-9]{8,})/);
				if (a !== null) {
                    return [a[2], a[3]];
                } else if (d !== null) {
                    return [d[2], d[3]];
                } else if (c !== null) {
                    return [c[2], c[3]];
                } else if (b !== null) {
                    return [b[2]];
                }
            }
            return e
        }
    }
	
	onChange = (e) => {
		var { taotientrinh } = this.props.buffviewstory;
		// if (e.target.name === "loaikeomem") {
			// if (e.target.value === "0") {
				// var value = this.props.buffviewstory.price.buffgrouptele_random.prices_web;
				// taotientrinh = {
					// ...taotientrinh,
					// "gtmtt": value,
				// };
			// }
			// if (e.target.value === "1") {
				// var value = this.props.buffviewstory.price.buffgrouptele_theoyeucau.prices_web;
				// taotientrinh = {
					// ...taotientrinh,
					// "gtmtt": value,
				// };
			// }
			// var new_taotientrinh = {
				// ...taotientrinh,
				// [e.target.name]: e.target.value,
			// };
			
		if (e.target.name === "lhi") {
			var arrid = [];
			if (e.target.value.indexOf("facebook.com/stories") > -1) {
				arrid = this.getIDFacebook(e.target.value);
			}
			var lhi = e.target.value;
			var url = "";
			if (arrid.length == 1) {
				lhi = arrid[0];
			}
			if (arrid.length >= 2) {
				lhi = arrid[0];
				url = arrid[1];
			}
			var new_taotientrinh = {
				...taotientrinh,
				"lhi": lhi + '_' + url,
				"url": url,
			};
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateBuffviewstory(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.buffviewstory;
		var new_taotientrinh = {
			...taotientrinh,
			"type_api": "buffviewstory"
		};
		var price = Math.round((taotientrinh.slct * taotientrinh.gtmtt + Number.EPSILON) * 100) / 100;
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn buff "+taotientrinh.slct+" với giá: "+price+" Coin? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addBuffviewstory(new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { lhi, url, lsct, slct, gc, gtmtt } = this.props.buffviewstory.taotientrinh;
 		const { facebookbuffviewstory_sv1_mo_dong } = this.props.buffviewstory;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_viewstory_sv1 = '';
		if (typeof(listDataServicesInfo.facebook_buff_viewstory_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_buff_viewstory_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_buff_viewstory_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_buff_viewstory_sv1) !== 'undefined') {
			html_viewstory_sv1 = parse(listDataServicesInfo.facebook_buff_viewstory_sv1.ghichu_sv);
			if (lsct.toString() === "0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_buff_viewstory_sv1.ghichu);
			}
		}
		
		var price = Math.round((slct * gtmtt + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link story:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhi" name="lhi" placeholder="" onChange={this.onChange} value={lhi} />
						<h6 style={{"marginTop":"5px", "marginBottom":"0px"}}>Hướng dẫn lấy link full của story: <a href="https://i.imgur.com/0DXPYsc.png" target="_blank" className="font-bold"> Tại đây</a></h6>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại Seeding cần tăng:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline">
							<input onChange={this.onChange} value="0" type="radio" className="custom-control-input" id="loai_0" name="lsct" checked={lsct.toString() === "0"}  disabled={this.props.buffviewstory.price.buffviewstory.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="loai_0" > SV1 {html_viewstory_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffviewstory.price.buffviewstory.prices_web} coin</span> {facebookbuffviewstory_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số lượng cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input type="number" className="form-control" onChange={this.onChange} name="slct" value={slct} onKeyPress={this.onKeyPress}/>
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Giá tiền mỗi tương tác:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="number" className="form-control" onChange={this.onChange} name="gtmtt" value={gtmtt}/>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="facebook_buff_viewstory" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{slct} view</span> với giá <span className="green bold">{price}</span> Coin</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffviewstory: state.buffviewstory,
});
const mapDispatchToProps = { updateBuffviewstory, addBuffviewstory, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);