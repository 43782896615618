import React, { Component, Fragment } from "react";
import NumberFormat from 'react-number-format';
import { getDataIsUser, saveDataUser,loadInfoUser} from '../../actions/adminPageAction';
import { getDataFeature, logout } from '../../actions/authActions';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import iconLikeCmtShare from '../../icon/vip_like.png';
import iconSub from '../../icon/buff_sub.png';
import iconReview from '../../icon/buff_comment.png';
import iconLike2 from '../../icon/buff_like.png';
import iconCMT2 from '../../icon/vip_cmt.png';
import iconView from '../../icon/buff_view.png';
import iconSub2 from '../../icon/buff_share_profile.png';
import iconSub3 from '../../icon/buff_share.png';
import iconCoin from '../../icon/coin.png';
import iconCoins from '../../icon/coins.png';
import iconAdmin from '../../icon/admin.png';
import { Lightbox } from "react-modal-image";
import ModalImage from "react-modal-image";
import { FiSmile } from "react-icons/fi";
import { AiOutlineCamera, AiOutlineFileGif, AiOutlinePicture, AiOutlineLike } from "react-icons/ai";
import { BiComment, BiMagnet, BiWallet } from "react-icons/bi";
import { BsReply } from "react-icons/bs";
import { TiBatteryFull } from "react-icons/ti";
import { FaRegUser } from "react-icons/fa";
import Admin1 from '../../icon/admin1.png';
import Users1 from '../../icon/users1.png';
import iconTiktok from '../../icon/tiktok.svg';
import iconShopee from '../../icon/shopee.svg';

class Quanlytaikhoan extends Component {
	state = {
		dataUser: {},
		formdata: {
			name_user: '',
			email_user: '',
			phone_user: '',
			idfacebook_user: '',
			re_pass_user: '',
			new_pass_user: '',
		},
		loadding_page: true,
		open: false,
		key_search: '',
		total_facebook: 0,
		total_instagram: 0,
		total_youtube: 0,
		total_tiktok: 0,
		total_shopee: 0,
		total_google: 0,
		total_telegram: 0,
		total_twitter: 0,
	}
	componentDidMount(){
		// this.props.getDataFeature(9999,'');
        // this.props.getDataIsUser().then(res => {
        	// if (res.data.status !== 200) {
				// Swal.fire({ icon: 'error', title: 'Lỗi', text: res.data.msg });
			// } else {
				// this.setState({
					// dataUser: res.data.data
				// })
			// }
        // })
        // this.props.loadInfoUser().then(res =>{
        	// this.setState({
				// formdata: {
					// ...this.state.formdata,
					// name_user: res.data.username,
					// email_user: res.data.email !== null ? res.data.email : '',
					// phone_user: res.data.phone !== null ? res.data.phone : '',
					// idfacebook_user: res.data.facebook_id !== null ? res.data.facebook_id : '',
					// notes: res.data.notes !== null ? res.data.notes : '',
				// },
				// loadding_page: false,
			// });
        // });
		
		
		var listDataFeature = {};
		try {
			if (localStorage.getItem("listDataFeature") != null) {
				listDataFeature = JSON.parse(localStorage.getItem("listDataFeature"));
			} else {
				listDataFeature = this.props.adminpage.listDataFeature;
			}
		} catch(e) {
			listDataFeature = []
		}
		var total_facebook = 0;
		var total_instagram = 0;
		var total_youtube = 0;
		var total_tiktok = 0;
		var total_shopee = 0;
		var total_google = 0;
		var total_telegram = 0;
		var total_twitter = 0;
		listDataFeature.map(function(value,index){
			if (value.type_category === 'Facebook') {
				total_facebook = total_facebook + 1;
			}
			if (value.type_category === 'Instagram') {
				total_instagram = total_instagram + 1;
			}
			if (value.type_category === 'Youtube') {
				total_youtube = total_youtube + 1;
			}
			if (value.type_category === 'Tiktok') {
				total_tiktok = total_tiktok + 1;
			}
			if (value.type_category === 'Shoppe') {
				total_shopee = total_shopee + 1;
			}
			if (value.type_category === 'Google') {
				total_google = total_google + 1;
			}
			if (value.type_category === 'Telegram') {
				total_telegram = total_telegram + 1;
			}
			if (value.type_category === 'Twitter') {
				total_twitter = total_twitter + 1;
			}
		});
		this.setState({
			"total_facebook": total_facebook,
			"total_instagram": total_instagram,
			"total_youtube": total_youtube,
			"total_tiktok": total_tiktok,
			"total_shopee": total_shopee,
			"total_google": total_google,
			"total_telegram": total_telegram,
			"total_twitter": total_twitter,
		});
    }

    betwen2date = (timeCheck) => {
    	if (typeof timeCheck !== 'undefined') {
	    	const oneDay = 24 * 60 * 60 * 1000;
			const firstDate = new Date(timeCheck*1000);
			const secondDate = new Date();

			const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
			return diffDays;
    	}
    }
    ChangeInfoUser = (e) => {
		this.setState({
			formdata: {
				...this.state.formdata,
				[e.target.name]: e.target.value
			}
		});
	}
	renderImage(img){
		if (img === '{iconLikeCmtShare}') {
			return iconLikeCmtShare;
		}
		if (img === '{iconSub}') {
			return iconSub;
		}
		if (img === '{iconReview}') {
			return iconReview;
		}
		if (img === '{iconLike2}') {
			return iconLike2;
		}
		if (img === '{iconCMT2}') {
			return iconCMT2;
		}
		if (img === '{iconView}') {
			return iconView;
		}
		if (img === '{iconSub2}') {
			return iconSub2;
		}
		if (img === '{iconSub3}') {
			return iconSub3;
		}
		return img;
	}
	closeLightbox = () => {
		this.state.open = false;
	}
	openLightbox = () => {
		this.state.open = true;
	}
	onClickDangxuat = e => {
		e.preventDefault();
		this.props.logout();
	};
	onKeyPressSearch = (e) => {
		var key_search = e.target.value.toString().toLowerCase();
		this.setState({
			"key_search": key_search
		});
		
		// var key_search = this.state.key_search;
		if (key_search == '') {
			document.querySelectorAll("h6[data-name]").forEach( ele => {
				if (ele.closest('.wrap-item-dichvu') != null) {
					ele.closest('.wrap-item-dichvu').style.display = "block";
				}
			});
			if (document.querySelector('.wrap-title-facebook') != null) {
				document.querySelector('.wrap-title-facebook').style.display = "block";
			}
			if (document.querySelector('.wrap-title-instagram') != null) {
				document.querySelector('.wrap-title-instagram').style.display = "block";
			}
			if (document.querySelector('.wrap-title-youtube') != null) {
				document.querySelector('.wrap-title-youtube').style.display = "block";
			}
			if (document.querySelector('.wrap-title-tiktok') != null) {
				document.querySelector('.wrap-title-tiktok').style.display = "block";
			}
			if (document.querySelector('.wrap-title-shopee') != null) {
				document.querySelector('.wrap-title-shopee').style.display = "block";
			}
			if (document.querySelector('.wrap-title-google') != null) {
				document.querySelector('.wrap-title-google').style.display = "block";
			}
			if (document.querySelector('.wrap-title-telegram') != null) {
				document.querySelector('.wrap-title-telegram').style.display = "block";
			}
			if (document.querySelector('.wrap-title-twitter') != null) {
				document.querySelector('.wrap-title-twitter').style.display = "block";
			}
			
			return false;
		}
		var total_facebook_state = this.state.total_facebook;
		var total_instagram_state = this.state.total_instagram;
		var total_youtube_state = this.state.total_youtube;
		var total_tiktok_state = this.state.total_tiktok;
		var total_shopee_state = this.state.total_shopee;
		var total_google_state = this.state.total_google;
		var total_telegram_state = this.state.total_telegram;
		var total_twitter_state = this.state.total_twitter;
		var total_facebook = 0;
		var total_instagram = 0;
		var total_youtube = 0;
		var total_tiktok = 0;
		var total_shopee = 0;
		var total_google = 0;
		var total_telegram = 0;
		var total_twitter = 0;
		document.querySelectorAll("h6[data-name]").forEach( ele => {
			var tendichvu = ele.getAttribute('data-name').toString().toLowerCase();
			if (tendichvu.indexOf(key_search) > -1) {
				if (ele.closest('.wrap-item-dichvu') != null) {
					ele.closest('.wrap-item-dichvu').style.display = "block";
				}
			} else {
				if (ele.closest('.wrap-item-dichvu') != null) {
					ele.closest('.wrap-item-dichvu').style.display = "none";
					
					if (ele.getAttribute('data-category') != null) {
						if (ele.getAttribute('data-category') === 'Facebook') {
							total_facebook = total_facebook + 1;
						}
						if (ele.getAttribute('data-category') === 'Instagram') {
							total_instagram = total_instagram + 1;
						}
						if (ele.getAttribute('data-category') === 'Youtube') {
							total_youtube = total_youtube + 1;
						}
						if (ele.getAttribute('data-category') === 'Tiktok') {
							total_tiktok = total_tiktok + 1;
						}
						if (ele.getAttribute('data-category') === 'Shoppe') {
							total_shopee = total_shopee + 1;
						}
						if (ele.getAttribute('data-category') === 'Google') {
							total_google = total_google + 1;
						}
						if (ele.getAttribute('data-category') === 'Telegram') {
							total_telegram = total_telegram + 1;
						}
						if (ele.getAttribute('data-category') === 'Twitter') {
							total_twitter = total_twitter + 1;
						}
					}
				}
			}
			if (total_facebook_state == total_facebook) {
				if (document.querySelector('.wrap-title-facebook') != null) {
					document.querySelector('.wrap-title-facebook').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-facebook') != null) {
					document.querySelector('.wrap-title-facebook').style.display = "block";
				}
			}
			if (total_instagram_state == total_instagram) {
				if (document.querySelector('.wrap-title-instagram') != null) {
					document.querySelector('.wrap-title-instagram').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-instagram') != null) {
					document.querySelector('.wrap-title-instagram').style.display = "block";
				}
			}
			if (total_youtube_state == total_youtube) {
				if (document.querySelector('.wrap-title-youtube') != null) {
					document.querySelector('.wrap-title-youtube').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-youtube') != null) {
					document.querySelector('.wrap-title-youtube').style.display = "block";
				}
			}
			if (total_tiktok_state == total_tiktok) {
				if (document.querySelector('.wrap-title-tiktok') != null) {
					document.querySelector('.wrap-title-tiktok').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-tiktok') != null) {
					document.querySelector('.wrap-title-tiktok').style.display = "block";
				}
			}
			if (total_shopee_state == total_shopee) {
				if (document.querySelector('.wrap-title-shopee') != null) {
					document.querySelector('.wrap-title-shopee').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-shopee') != null) {
					document.querySelector('.wrap-title-shopee').style.display = "block";
				}
			}
			if (total_google_state == total_google) {
				if (document.querySelector('.wrap-title-google') != null) {
					document.querySelector('.wrap-title-google').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-google') != null) {
					document.querySelector('.wrap-title-google').style.display = "block";
				}
			}
			if (total_telegram_state == total_telegram) {
				if (document.querySelector('.wrap-title-telegram') != null) {
					document.querySelector('.wrap-title-telegram').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-telegram') != null) {
					document.querySelector('.wrap-title-telegram').style.display = "block";
				}
			}
			if (total_twitter_state == total_twitter) {
				if (document.querySelector('.wrap-title-twitter') != null) {
					document.querySelector('.wrap-title-twitter').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-twitter') != null) {
					document.querySelector('.wrap-title-twitter').style.display = "block";
				}
			}
		});
	}
	onClickSearch = (e) => {
		var key_search = this.state.key_search;
		if (key_search == '') {
			document.querySelectorAll("h6[data-name]").forEach( ele => {
				if (ele.closest('.wrap-item-dichvu') != null) {
					ele.closest('.wrap-item-dichvu').style.display = "block";
				}
			});
			if (document.querySelector('.wrap-title-facebook') != null) {
				document.querySelector('.wrap-title-facebook').style.display = "block";
			}
			if (document.querySelector('.wrap-title-instagram') != null) {
				document.querySelector('.wrap-title-instagram').style.display = "block";
			}
			if (document.querySelector('.wrap-title-youtube') != null) {
				document.querySelector('.wrap-title-youtube').style.display = "block";
			}
			if (document.querySelector('.wrap-title-tiktok') != null) {
				document.querySelector('.wrap-title-tiktok').style.display = "block";
			}
			if (document.querySelector('.wrap-title-shopee') != null) {
				document.querySelector('.wrap-title-shopee').style.display = "block";
			}
			if (document.querySelector('.wrap-title-google') != null) {
				document.querySelector('.wrap-title-google').style.display = "block";
			}
			if (document.querySelector('.wrap-title-telegram') != null) {
				document.querySelector('.wrap-title-telegram').style.display = "block";
			}
			if (document.querySelector('.wrap-title-twitter') != null) {
				document.querySelector('.wrap-title-twitter').style.display = "block";
			}
			
			return false;
		}
		var total_facebook_state = this.state.total_facebook;
		var total_instagram_state = this.state.total_instagram;
		var total_youtube_state = this.state.total_youtube;
		var total_tiktok_state = this.state.total_tiktok;
		var total_shopee_state = this.state.total_shopee;
		var total_google_state = this.state.total_google;
		var total_telegram_state = this.state.total_telegram;
		var total_twitter_state = this.state.total_twitter;
		var total_facebook = 0;
		var total_instagram = 0;
		var total_youtube = 0;
		var total_tiktok = 0;
		var total_shopee = 0;
		var total_google = 0;
		var total_telegram = 0;
		var total_twitter = 0;
		document.querySelectorAll("h6[data-name]").forEach( ele => {
			var tendichvu = ele.getAttribute('data-name').toString().toLowerCase();
			if (tendichvu.indexOf(key_search) > -1) {
				if (ele.closest('.wrap-item-dichvu') != null) {
					ele.closest('.wrap-item-dichvu').style.display = "block";
				}
			} else {
				if (ele.closest('.wrap-item-dichvu') != null) {
					ele.closest('.wrap-item-dichvu').style.display = "none";
					
					if (ele.getAttribute('data-category') != null) {
						if (ele.getAttribute('data-category') === 'Facebook') {
							total_facebook = total_facebook + 1;
						}
						if (ele.getAttribute('data-category') === 'Instagram') {
							total_instagram = total_instagram + 1;
						}
						if (ele.getAttribute('data-category') === 'Youtube') {
							total_youtube = total_youtube + 1;
						}
						if (ele.getAttribute('data-category') === 'Tiktok') {
							total_tiktok = total_tiktok + 1;
						}
						if (ele.getAttribute('data-category') === 'Shoppe') {
							total_shopee = total_shopee + 1;
						}
						if (ele.getAttribute('data-category') === 'Google') {
							total_google = total_google + 1;
						}
						if (ele.getAttribute('data-category') === 'Telegram') {
							total_telegram = total_telegram + 1;
						}
						if (ele.getAttribute('data-category') === 'Twitter') {
							total_twitter = total_twitter + 1;
						}
					}
				}
			}
			if (total_facebook_state == total_facebook) {
				if (document.querySelector('.wrap-title-facebook') != null) {
					document.querySelector('.wrap-title-facebook').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-facebook') != null) {
					document.querySelector('.wrap-title-facebook').style.display = "block";
				}
			}
			if (total_instagram_state == total_instagram) {
				if (document.querySelector('.wrap-title-instagram') != null) {
					document.querySelector('.wrap-title-instagram').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-instagram') != null) {
					document.querySelector('.wrap-title-instagram').style.display = "block";
				}
			}
			if (total_youtube_state == total_youtube) {
				if (document.querySelector('.wrap-title-youtube') != null) {
					document.querySelector('.wrap-title-youtube').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-youtube') != null) {
					document.querySelector('.wrap-title-youtube').style.display = "block";
				}
			}
			if (total_tiktok_state == total_tiktok) {
				if (document.querySelector('.wrap-title-tiktok') != null) {
					document.querySelector('.wrap-title-tiktok').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-tiktok') != null) {
					document.querySelector('.wrap-title-tiktok').style.display = "block";
				}
			}
			if (total_shopee_state == total_shopee) {
				if (document.querySelector('.wrap-title-shopee') != null) {
					document.querySelector('.wrap-title-shopee').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-shopee') != null) {
					document.querySelector('.wrap-title-shopee').style.display = "block";
				}
			}
			if (total_google_state == total_google) {
				if (document.querySelector('.wrap-title-google') != null) {
					document.querySelector('.wrap-title-google').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-google') != null) {
					document.querySelector('.wrap-title-google').style.display = "block";
				}
			}
			if (total_telegram_state == total_telegram) {
				if (document.querySelector('.wrap-title-telegram') != null) {
					document.querySelector('.wrap-title-telegram').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-telegram') != null) {
					document.querySelector('.wrap-title-telegram').style.display = "block";
				}
			}
			if (total_twitter_state == total_twitter) {
				if (document.querySelector('.wrap-title-twitter') != null) {
					document.querySelector('.wrap-title-twitter').style.display = "none";
				}
			} else {
				if (document.querySelector('.wrap-title-twitter') != null) {
					document.querySelector('.wrap-title-twitter').style.display = "block";
				}
			}
		});
	}
  	render() {
  		const {dataUser} = this.state;
  		const { list_noti, user } = this.props.auth;
		const domainSite = window.location.hostname;
		var listDataFeature = {};
		try {
			if (localStorage.getItem("listDataFeature") != null) {
				listDataFeature = JSON.parse(localStorage.getItem("listDataFeature"));
			} else {
				listDataFeature = this.props.adminpage.listDataFeature;
			}
		} catch(e) {
			listDataFeature = []
		}
		
  		var that = this;
		listDataFeature.reverse();
  		var facebookservice = (
  			<Fragment>
  				{
  					listDataFeature.map(function(value,index){
  						var classcss = 'sevice_onl';
  						if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
  							classcss = 'sevice_offl';
  						}
  						if (value.type_category === 'Facebook') {
							// if (value.name.indexOf('BOT') > -1 || value.name.indexOf('PROXY') > -1 || value.name.indexOf('COOKIE') > -1 || value.name.indexOf('RIP') > -1) {
								// return ('');
							// }
							if (value.name.indexOf('ĐỔI TÊN FANPAGE') > -1) {
								return ('');
							}
							var icon = <img src={process.env.PUBLIC_URL + '/images/icon/3259376_facebook_media_social_icon.png'} className="img img-service card-img" />;
							if (value.name.indexOf('CMT') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/6556698_balloon_bubble_chat_communication_message_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('LIVESTREAM') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/6556722_eye_eyeball_lens_look_view_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('VIEW') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/6556720_eye_eyeball_lens_picture_view_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('GROUP') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/6556733_cross_plus_positive_shape_web_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('SUB') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/6556700_add_friend_person_social_user_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('SHARE') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/6556732_link_media_network_share_social_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('LIKE') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/6556713_approval_best_favourite_like_love_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('ĐÁNH GIÁ') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/6556714_location_map_marker_navigation_pin_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('BOT') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/6556708_approval_best_favourite_like_star_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('MẮT') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/6556722_eye_eyeball_lens_look_view_icon.png'} className="img img-service card-img" />;
							}
							var like_comment_share_likecomment = '';
							if (value.link === '/tool/Bufflikecommentshare') {
								return (
									<Fragment>
										<div className="col-md-4 col-12 wrap-item-dichvu">
											<div className="card mb-4 p-1 text-center h-85">
												<a href="/tool/buffsub_sale" className="">
													<div className="card-body px-1">
														<div className="row m-0 p-0">
															<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
																<img src={process.env.PUBLIC_URL + '/images/icon/6556695_avatar_human_man_person_user_icon.png'} className="img img-service card-img" />
															</div>
															<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
																<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>BUFF SUB SALE</h6>
																<span className={classcss}>{value.active}</span>
															</div>
														</div>
													</div>
												</a>
											</div>
										</div>
										<div className="col-md-4 col-12 wrap-item-dichvu">
											<div className="card mb-4 p-1 text-center h-85">
												<a href="/tool/Bufflikefanpagesale" className="">
													<div className="card-body px-1">
														<div className="row m-0 p-0">
															<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
																<img src={process.env.PUBLIC_URL + '/images/icon/6556716_greeting_heart_like_love_romantic_icon.png'} className="img img-service card-img" />
															</div>
															<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
																<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>BUFF LIKE PAGE SALE</h6>
																<span className={classcss}>{value.active}</span>
															</div>
														</div>
													</div>
												</a>
											</div>
										</div>
										<div className="col-md-4 col-12 wrap-item-dichvu">
											<div className="card mb-4 p-1 text-center h-85">
												<a href="/tool/Bufflikecommentshare_like" className="">
													<div className="card-body px-1">
														<div className="row m-0 p-0">
															<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
																<img src={process.env.PUBLIC_URL + '/images/icon/6556719_approval_best_favourite_love_picture_icon.png'} className="img img-service card-img" />
															</div>
															<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
																<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>BUFF LIKE</h6>
																<span className={classcss}>{value.active}</span>
															</div>
														</div>
													</div>
												</a>
											</div>
										</div>
										<div className="col-md-4 col-12 wrap-item-dichvu">
											<div className="card mb-4 p-1 text-center h-85">
												<a href="/tool/Bufflikecommentshare_comment" className="">
													<div className="card-body px-1">
														<div className="row m-0 p-0">
															<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
																<img src={process.env.PUBLIC_URL + '/images/icon/6556706_communication_connection_email_internet_mail_icon.png'} className="img img-service card-img" />
															</div>
															<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
																<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>BUFF CMT</h6>
																<span className={classcss}>{value.active}</span>
															</div>
														</div>
													</div>
												</a>
											</div>
										</div>
										<div className="col-md-4 col-12 wrap-item-dichvu">
											<div className="card mb-4 p-1 text-center h-85">
												<a href="/tool/Bufflikecommentshare_share" className="">
													<div className="card-body px-1">
														<div className="row m-0 p-0">
															<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
																<img src={process.env.PUBLIC_URL + '/images/icon/6556732_link_media_network_share_social_icon.png'} className="img img-service card-img" />
															</div>
															<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
																<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>BUFF SHARE</h6>
																<span className={classcss}>{value.active}</span>
															</div>
														</div>
													</div>
												</a>
											</div>
										</div>
										<div className="col-md-4 col-12 wrap-item-dichvu">
											<div className="card mb-4 p-1 text-center h-85">
												<a href="/tool/Bufflikecommentshare_likecomment" className="">
													<div className="card-body px-1">
														<div className="row m-0 p-0">
															<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
																<img src={process.env.PUBLIC_URL + '/images/icon/6556719_approval_best_favourite_love_picture_icon.png'} className="img img-service card-img" />
															</div>
															<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
																<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>BUFF LIKE CMT</h6>
																<span className={classcss}>{value.active}</span>
															</div>
														</div>
													</div>
												</a>
											</div>
										</div>
									</Fragment>
								);
							} else {
								return (
									<div className="col-md-4 col-12 wrap-item-dichvu">
										<div className="card mb-4 p-1 text-center h-85">
											<a href={value.link} className="" key={index}>
												<div className="card-body px-1">
													<div className="row m-0 p-0">
														<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
															{/*
																<img src={that.renderImage(value.icon)} alt={value.name} className="img img-service card-img" />
																<i className="nav-main-link-icon fab fa-facebook" style={{"color": "rgb(6, 101, 208)", "fontSize": "45px"}}></i>
															*/}
															{icon}
														</div>
														<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
															<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>{value.name}</h6>
															<span className={classcss}>{value.active}</span>
														</div>
													</div>
												</div>
											</a>
										</div>
									</div>
								);
							}
  						} else {
  							return ('');
  						}
  					})
  				}
  			</Fragment>
  		);
  		var instagramservice = (
  			<Fragment>
  				{
  					listDataFeature.map(function(value,index){
  						var classcss = 'sevice_onl';
  						if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
  							classcss = 'sevice_offl';
  						}
  						if (value.type_category === 'Instagram') {
							var icon = <img src={process.env.PUBLIC_URL + '/images/icon/2246825_instagram_logo_label_logo_instagram_icon.png'} className="img img-service card-img" />;
							if (value.name.indexOf('CMT') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/2246809_comment_insatgram_message_notification_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('VIEW') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/2246814_post_record_video_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('SUB') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/2246811_user_person_profile_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('LIKE') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/2246810_heart_like_notification_one_like_icon.png'} className="img img-service card-img" />;
							}
  							return (
								<div className="col-md-4 col-12 wrap-item-dichvu">
									<div className="card mb-4 p-1 text-center h-85">
										<a href={value.link} className="" key={index}>
											<div className="card-body px-1">
												<div className="row m-0 p-0">
													<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
														{/*
															<img src={that.renderImage(value.icon)} alt={value.name} className="img img-service card-img" />
															<i className="nav-main-link-icon fab fa-instagram" style={{"color": "rgb(169, 0, 255)", "fontSize": "45px"}}></i>
														*/}
														{icon}
													</div>
													<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
														<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>{value.name}</h6>
														<span className={classcss}>{value.active}</span>
													</div>
												</div>
											</div>
										</a>
									</div>
								</div>
							);
  						} else {
  							return ('');
  						}
  					})
  				}
  			</Fragment>
  		);
  		var youtubeservice = (
  			<Fragment>
  				{
  					listDataFeature.map(function(value,index){
  						var classcss = 'sevice_onl';
  						if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
  							classcss = 'sevice_offl';
  						}
  						if (value.type_category === 'Youtube') {
							var icon = <img src={process.env.PUBLIC_URL + '/images/icon/3993857_evil_monster_viral_icon.png'} className="img img-service card-img" />;
							if (value.name.indexOf('CMT') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/3993838_scenario_screenplay_script_storyboard_storytelling_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('VIEW') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/3993848_browser_play_player_ui_video_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('SUB') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/3993872_1m_million_views_youtube_one_million_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('LIKE') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/3993840_gesture_like_thumbs_up_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('XEM') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/3993854_blog_premiere_public_show_video_icon.png'} className="img img-service card-img" />;
							}
  							return (
								<div className="col-md-4 col-12 wrap-item-dichvu">
									<div className="card mb-4 p-1 text-center h-85">
										<a href={value.link} className="" key={index}>
											<div className="card-body px-1">
												<div className="row m-0 p-0">
													<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
														{/*
															<img src={that.renderImage(value.icon)} alt={value.name} className="img img-service card-img" />
															<i className="nav-main-link-icon fab fa-youtube" style={{"color": "rgb(255, 0, 0)", "fontSize": "45px"}}></i>
														*/}
														{icon}
													</div>
													<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
														<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>{value.name}</h6>
														<span className={classcss}>{value.active}</span>
													</div>
												</div>
											</div>
										</a>
									</div>
								</div>
							);
  						} else {
  							return ('');
  						}
  					})
  				}
  			</Fragment>
  		);
  		var tiktokservice = (
  			<Fragment>
  				{
  					listDataFeature.map(function(value,index){
  						var classcss = 'sevice_onl';
  						if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
  							classcss = 'sevice_offl';
  						}
  						if (value.type_category === 'Tiktok') {
							var icon = <img src={process.env.PUBLIC_URL + '/images/icon/4180512_heart_love_marriage_romantic_icon.png'} className="img img-service card-img" />;
							if (value.name.indexOf('CMT') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/4180522_heart_love_marriage_romantic_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('VIEW') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/4180545_heart_love_marriage_romantic_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('LIKE') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/4180551_heart_love_marriage_romantic_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('MẮT') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/4180540_heart_love_marriage_romantic_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('SHARE') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/4180554_heart_love_marriage_romantic_icon.png'} className="img img-service card-img" />;
							}
  							return (
								<div className="col-md-4 col-12 wrap-item-dichvu">
									<div className="card mb-4 p-1 text-center h-85">
										<a href={value.link} className="" key={index}>
											<div className="card-body px-1">
												<div className="row m-0 p-0">
													<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
														{/*
															<img src={that.renderImage(value.icon)} alt={value.name} className="img img-service card-img" />
															<img src={iconTiktok} style={{ "width": "40px" }} />
														*/}
														{icon}
													</div>
													<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
														<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>{value.name}</h6>
														<span className={classcss}>{value.active}</span>
													</div>
												</div>
											</div>
										</a>
									</div>
								</div>
							);
  						} else {
  							return ('');
  						}
  					})
  				}
  			</Fragment>
  		);
  		var shopeeservice = (
  			<Fragment>
  				{
  					listDataFeature.map(function(value,index){
  						var classcss = 'sevice_onl';
  						if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
  							classcss = 'sevice_offl';
  						}
  						if (value.type_category === 'Shoppe') {
							var icon = <img src={process.env.PUBLIC_URL + '/images/icon/2903217_balloon_heart_love_romance_valentines_icon.png'} className="img img-service card-img" />;
							if (value.name.indexOf('SUB') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/2903219_hands_heart_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('LIVESTREAM') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/2903211_love_message_phone_icon.png'} className="img img-service card-img" />;
							}
  							return (
								<div className="col-md-4 col-12 wrap-item-dichvu">
									<div className="card mb-4 p-1 text-center h-85">
										<a href={value.link} className="" key={index}>
											<div className="card-body px-1">
												<div className="row m-0 p-0">
													<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
														{/*
															<img src={that.renderImage(value.icon)} alt={value.name} className="img img-service card-img" />
															<img src={iconShopee} style={{ "width": "40px" }} />
														*/}
														{icon}
													</div>
													<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
														<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>{value.name}</h6>
														<span className={classcss}>{value.active}</span>
													</div>
												</div>
											</div>
										</a>
									</div>
								</div>
							);
  						} else {
  							return ('');
  						}
  					})
  				}
  			</Fragment>
  		);
  		var googleservice = (
  			<Fragment>
  				{
  					listDataFeature.map(function(value,index){
  						var classcss = 'sevice_onl';
  						if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
  							classcss = 'sevice_offl';
  						}
  						if (value.type_category === 'Google') {
							var icon = <img src={process.env.PUBLIC_URL + '/images/icon/8168609_google_maps_navigation_location_icon.png'} className="img img-service card-img" />;
							if (value.name.indexOf('REVIEW') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/8168609_google_maps_navigation_location_icon.png'} className="img img-service card-img" />;
							}
  							return (
								<div className="col-md-4 col-12 wrap-item-dichvu">
									<div className="card mb-4 p-1 text-center h-85">
										<a href={value.link} className="" key={index}>
											<div className="card-body px-1">
												<div className="row m-0 p-0">
													<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
														{/*
															<img src={that.renderImage(value.icon)} alt={value.name} className="img img-service card-img" />
															<i className="nav-main-link-icon fab fa-google-cus" style={{"fontSize": "45px"}}></i>
														*/}
														{icon}
													</div>
													<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
														<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>{value.name}</h6>
														<span className={classcss}>{value.active}</span>
													</div>
												</div>
											</div>
										</a>
									</div>
								</div>
							);
  						} else {
  							return ('');
  						}
  					})
  				}
  			</Fragment>
  		);
  		var telegramservice = (
  			<Fragment>
  				{
  					listDataFeature.map(function(value,index){
  						var classcss = 'sevice_onl';
  						if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
  							classcss = 'sevice_offl';
  						}
  						if (value.type_category === 'Telegram') {
							var icon = <img src={process.env.PUBLIC_URL + '/images/icon/8168633_telegram_paper_airplane_apps_icon.png'} className="img img-service card-img" />;
							if (value.name.indexOf('GROUP') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/8168633_telegram_paper_airplane_apps_icon.png'} className="img img-service card-img" />;
							}
  							return (
								<div className="col-md-4 col-12 wrap-item-dichvu">
									<div className="card mb-4 p-1 text-center h-85">
										<a href={value.link} className="" key={index}>
											<div className="card-body px-1">
												<div className="row m-0 p-0">
													<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
														{/*
															<img src={that.renderImage(value.icon)} alt={value.name} className="img img-service card-img" />
															<i className="nav-main-link-icon fa fa-paper-plane" style={{"fontSize": "45px"}}></i>
														*/}
														{icon}
													</div>
													<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
														<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>{value.name}</h6>
														<span className={classcss}>{value.active}</span>
													</div>
												</div>
											</div>
										</a>
									</div>
								</div>
							);
  						} else {
  							return ('');
  						}
  					})
  				}
  			</Fragment>
  		);
  		var twitterservice = (
  			<Fragment>
  				{
  					listDataFeature.map(function(value,index){
  						var classcss = 'sevice_onl';
  						if (value.active === 'Bảo trì' || value.active === 'Dừng hoạt động') {
  							classcss = 'sevice_offl';
  						}
  						if (value.type_category === 'Twitter') {
							var icon = <img src={process.env.PUBLIC_URL + '/images/icon/8168612_twitter_social_media_bird_icon logo_icon.png'} className="img img-service card-img" />;
							if (value.name.indexOf('FOLLOW') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/8168612_twitter_social_media_bird_icon.png'} className="img img-service card-img" />;
							}
							if (value.name.indexOf('LIKE') > -1) {
								icon = <img src={process.env.PUBLIC_URL + '/images/icon/8168658_health_medical_healthcare_heart_icon.png'} className="img img-service card-img" />;
							}
  							return (
								<div className="col-md-4 col-12 wrap-item-dichvu">
									<div className="card mb-4 p-1 text-center h-85">
										<a href={value.link} className="" key={index}>
											<div className="card-body px-1">
												<div className="row m-0 p-0">
													<div className="m-0 p-0 justify-content-center align-self-center col-lg-4 col-xs-12 col-12">
														{/*
															<img src={that.renderImage(value.icon)} alt={value.name} className="img img-service card-img" />
															<i className="nav-main-link-icon fab fa-twitter" style={{"fontSize": "45px"}}></i>
														*/}
														{icon}
													</div>
													<div className="text-center text-lg-left mt-3 mt-lg-0 pl-0 pl-md-3 col-lg-8 col-xs-12 col-12 danhsach-cus">
														<h6 className="font-weight-bold text-primary" data-name={value.name} data-category={value.type_category}>{value.name}</h6>
														<span className={classcss}>{value.active}</span>
													</div>
												</div>
											</div>
										</a>
									</div>
								</div>
							);
  						} else {
  							return ('');
  						}
  					})
  				}
  			</Fragment>
  		);
		if (list_noti !== "") {
			var cardthongbao = (
				<Fragment>
					{
						list_noti.map(function(v, i) {
							if (i <= 10) {
								return (
									<div className="d-flex flex-row mb-3 pb-3 border-bottom" key={i}>
										<a href="#" aria-current="page" className="nuxt-link-exact-active nuxt-link-active">
											{
												v.image != '' && v.image != null ?
													<Fragment>
														<ModalImage
															className="modalimage-cus nuxt-link-exact-active nuxt-link-active"
															small={ v.image }
															large={ v.image }
															alt={ v.image }
														/>
													</Fragment>
												: 
													
													<Fragment>
														<div style={{"width": "95px", "height": "60px"}}></div>
													</Fragment>
											}
										</a>
										<div className="pl-3 pr-2" style={{whiteSpace: "pre-wrap"}}>
											<a href="#" aria-current="page" className="nuxt-link-exact-active nuxt-link-active">
												<p className="font-weight-medium mb-1" style={{"lineBreak": "anywhere"}}>{ Buffer.from(v.content, 'base64').toString('utf8') }</p>
												<p className="text-muted mb-0 text-small">
													<span style={{"overflow": "hidden", "verticalAlign": "middle"}}><Moment format="DD">{v.create_at * 1000}</Moment> tháng <Moment format="MM ">{v.create_at * 1000}</Moment></span>
													<span style={{"overflow": "hidden", "verticalAlign": "middle"}}>lúc <Moment format="HH:mm ">{v.create_at * 1000}</Moment></span>
													<svg className="svg-inline--fa fa-globe-americas fa-w-16" style={{"width": "1em"}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="globe-americas" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" data-fa-i2svg=""><path fill="currentColor" d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm82.29 357.6c-3.9 3.88-7.99 7.95-11.31 11.28-2.99 3-5.1 6.7-6.17 10.71-1.51 5.66-2.73 11.38-4.77 16.87l-17.39 46.85c-13.76 3-28 4.69-42.65 4.69v-27.38c1.69-12.62-7.64-36.26-22.63-51.25-6-6-9.37-14.14-9.37-22.63v-32.01c0-11.64-6.27-22.34-16.46-27.97-14.37-7.95-34.81-19.06-48.81-26.11-11.48-5.78-22.1-13.14-31.65-21.75l-.8-.72a114.792 114.792 0 0 1-18.06-20.74c-9.38-13.77-24.66-36.42-34.59-51.14 20.47-45.5 57.36-82.04 103.2-101.89l24.01 12.01C203.48 89.74 216 82.01 216 70.11v-11.3c7.99-1.29 16.12-2.11 24.39-2.42l28.3 28.3c6.25 6.25 6.25 16.38 0 22.63L264 112l-10.34 10.34c-3.12 3.12-3.12 8.19 0 11.31l4.69 4.69c3.12 3.12 3.12 8.19 0 11.31l-8 8a8.008 8.008 0 0 1-5.66 2.34h-8.99c-2.08 0-4.08.81-5.58 2.27l-9.92 9.65a8.008 8.008 0 0 0-1.58 9.31l15.59 31.19c2.66 5.32-1.21 11.58-7.15 11.58h-5.64c-1.93 0-3.79-.7-5.24-1.96l-9.28-8.06a16.017 16.017 0 0 0-15.55-3.1l-31.17 10.39a11.95 11.95 0 0 0-8.17 11.34c0 4.53 2.56 8.66 6.61 10.69l11.08 5.54c9.41 4.71 19.79 7.16 30.31 7.16s22.59 27.29 32 32h66.75c8.49 0 16.62 3.37 22.63 9.37l13.69 13.69a30.503 30.503 0 0 1 8.93 21.57 46.536 46.536 0 0 1-13.72 32.98zM417 274.25c-5.79-1.45-10.84-5-14.15-9.97l-17.98-26.97a23.97 23.97 0 0 1 0-26.62l19.59-29.38c2.32-3.47 5.5-6.29 9.24-8.15l12.98-6.49C440.2 193.59 448 223.87 448 256c0 8.67-.74 17.16-1.82 25.54L417 274.25z"></path></svg>
												</p>
											</a>
										</div>
									</div>
								);
							} else {
								return ('');
							}
						})
					}
				</Fragment>
			);
		} else {
			var cardthongbao = '';
		}
		
    	return (
    		<Fragment>
	    		<div className="row homepage-cus" data-toggle="appear" >
					<div className="col-md-12 w-100">
						<div className="row">
							<div className="col-md-12">
								<h1 className="trangchu-text-cus">Trang chủ</h1>
								<ul className="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"><ol className="breadcrumb"><li className="breadcrumb-item active"><span aria-current="location">Trang chủ</span></li></ol></ul>
								<div className="separator mb-5"></div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-5">
								<div className="col-md-12" style={{"marginBottom": "43px"}}>
									<h5 className="card-title font-weight-bold mb-3 d-inline">
										Xin chào {user.username}
									</h5>
								</div>
								<div className="col-md-12">
									<div className="card mb-4">
										<div className="d-flex flex-row">
											<a href="/info-user" aria-current="page" className="d-flex nuxt-link-exact-active nuxt-link-active">
												<img src={"https://avatar.oxro.io/avatar.svg?background=random&name="+user.username} alt="le an" width="95" className="img-thumbnail list-thumbnail rounded-circle align-self-center m-4 mw-85" />
											</a>
											<div className="d-flex flex-grow-1 min-width-zero">
												<div className="pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
													<div className="min-width-zero">
														<a href="/" aria-current="page" className="nuxt-link-exact-active nuxt-link-active">
															<h6 className="mb-1 card-subtitle truncate"> {user.username} </h6>
														</a>
														<p className="text-muted mb-2"> <NumberFormat value={ user.money } displayType={'text'} thousandSeparator={true} suffix={' coin'} /> </p>
														<button type="button" className="btn btn-outline-primary btn-xs">{user.notes}</button>
													</div>
												</div>
											</div>
											<div className="d-flex min-width-zero">
												<div className="pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
													<div className="min-width-zero">
														<Link to="/info-user" className="infor-out-cus" style={{"marginRight": "15px"}}>
															<i className="far fa-fw fa-user mr-1"></i>Thông tin
														</Link>
														<a href="#" onClick={this.onClickDangxuat} className="infor-out-cus" style={{"marginRight": "15px"}}>
															<i className="far fa-fw fa-arrow-alt-circle-left mr-1"></i>
															Đăng xuất
														</a>
														<br/>
														<br/>
														<br/>
														<br/>
													</div>
												</div>
											</div>
										</div>
										<div className="card-body py-0">
											<div className="row">
												<div className="col-6">
													<div className="d-flex flex-row align-items-center mb-1">
														{/* <FaRegUser style={{"fontSize": "52px"}}/> */}
														<img src={process.env.PUBLIC_URL + '/images/icon/Male-icon.png'}  width="52" height="52" />
														<div className="pl-3 pt-2 pr-2 pb-2">
															<div className="list-item-heading font-weight-bold text-nowrap"> {user.id} </div>
															<div className="text-muted"> Mã khách hàng </div>
														</div>
													</div>
												</div>
												<div className="col-6">
													<div className="d-flex flex-row align-items-center mb-1">
														{/* <BiWallet style={{"fontSize": "52px"}}/> */}
														<img src={process.env.PUBLIC_URL + '/images/icon/Wallet-icon.png'}  width="52" height="52" />
														<div className="pl-3 pt-2 pr-2 pb-2">
															<div className="list-item-heading font-weight-bold text-nowrap"> <NumberFormat value={ user.money } displayType={'text'} thousandSeparator={true} suffix={' coin'} /> </div>
															<div className="text-muted"> Số dư </div>
														</div>
													</div>
												</div>
												<div className="col-6">
													<div className="d-flex flex-row align-items-center mb-1">
														{/* <TiBatteryFull style={{"fontSize": "52px"}}/> */}
														<img src={process.env.PUBLIC_URL + '/images/icon/Battery-100-icon.png'}  width="52" height="52" />
														<div className="pl-3 pt-2 pr-2 pb-2">
															<div className="list-item-heading font-weight-bold text-nowrap"> <NumberFormat value={ user.tien_datieu } displayType={'text'} thousandSeparator={true} suffix={' coin'} /> </div>
															<div className="text-muted"> Số tiền đã tiêu </div>
														</div>
													</div>
												</div>
												<div className="col-6">
													<div className="d-flex flex-row align-items-center mb-1">
														{/* <BiMagnet style={{"fontSize": "52px"}}/> */}
														<img src={process.env.PUBLIC_URL + '/images/icon/Ustream-icon.png'}  width="52" height="52" />
														<div className="pl-3 pt-2 pr-2 pb-2">
															<div className="list-item-heading font-weight-bold text-nowrap"> <NumberFormat value={ user.tien_nap } displayType={'text'} thousandSeparator={true} suffix={' coin'} /> </div>
															<div className="text-muted"> Số tiền đã nạp </div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <div className="col-md-12" style={{"marginBottom": "20px"}}>
									<h5 className="card-title font-weight-bold mb-3 d-inline">
										Video hướng dẫn
									</h5>
								</div>
								<div className="col-md-12" style={{"marginBottom": "20px"}}>
									<div className="card mb-4" style={{"paddingTop": "20px"}}>
										<div className="card-body py-0">
											<div className="row">
												<div className="col-12">
													<iframe height="420" style={{"width":"100%", "margin":"0px auto 10px auto"}} src={ "https://www.youtube.com/embed/X4SY8QepPJE" } frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
												</div>
											</div>
										</div>
									</div>
								</div> */}
								<div className="col-md-12" style={{"marginBottom": "20px"}}>
									<div className="card mb-4">
										<div className="card-header">
											<ul role="tablist" className="nav nav-tabs card-header-tabs" id="__BVID__49__BV_tab_controls_">
												<li role="presentation" className="nav-item">
													<a role="tab" aria-selected="true" aria-setsize="2" aria-posinset="1" href="#" target="_self" className="nav-link active" id="__BVID__50___BV_tab_button__" aria-controls="__BVID__50">Thông báo hệ thống</a>
												</li>
											</ul>
										</div>
										<div className="tab-content" id="__BVID__49__BV_tab_container_">
											<div role="tabpanel" aria-hidden="false" className="tab-pane active card-body" id="__BVID__50" aria-labelledby="__BVID__50___BV_tab_button__">
												<section className="ps-container scroll notification-list ps ps--active-y">
													{cardthongbao}
												</section>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-7">
								<div className="col col-12" style={{"marginBottom": "20px", "padding": "0"}}>
									<div className="row ml-1 mb-2">
										<div className="col-md-3 col-12 wrap-item-dichvu">
											<div className="row justify-content-start">
												<div className="mb-2">
													<div className="input-group">
														<input type="text" placeholder="Nhập tên dịch vụ" className="form-control" onChange={this.onKeyPressSearch}/>
														<div className="input-group-append">
															<button type="button" className="btn btn-sm btn-primary" onClick={this.onClickSearch}> Tìm ngay </button>
														</div>
													</div>
												</div>
												{/*<div className="ml-2 align-bottom mb-2 mb-sm-0" style={{"marginTop": "3px"}}>
													<button role="button" className="m-0 py-1 px-2 btn rounded-0 btn-primary">
														<i className="simple-icon-grid h5 align-middle"></i>
													</button>
													<button role="button" className="m-0 py-1 px-2 btn rounded-0 btn-outline-primary">
														<i className="simple-icon-menu h5 align-middle"></i>
													</button>
												</div>*/}
											</div>
										</div>
										<div className="col-md-9 col-12 wrap-item-dichvu" style={{"padding": "5px 0px 0px 15px"}}>
											<span className="nav-bar-flash-feature" style={{"fontSize": "20px"}}>Đây là những tính năng nổi bật tại website vui lòng vào mục dịch vụ để sử dụng nhiều hơn nữa</span>
										</div>
									</div>
								</div>
								<div className="col col-12 wrap-title-facebook" style={{"marginBottom": "20px", "padding": "0"}}>
									<div className="card text-center text-primary">
										<h5 className="font-weight-bold text-uppercase p-0" style={{"marginTop": "12px", "marginBottom": "12px"}}> Danh Sách Dịch Vụ Facebook</h5>
									</div>
								</div>
								<div className="row mb-3">
									{facebookservice}
								</div>
								<div className="col col-12 wrap-title-tiktok" style={{"marginBottom": "20px", "padding": "0"}}>
									<div className="card text-center text-primary">
										<h5 className="font-weight-bold text-uppercase p-0" style={{"marginTop": "12px", "marginBottom": "12px"}}> Danh Sách Dịch Vụ TikTok</h5>
									</div>
								</div>
								<div className="row mb-3">
									{tiktokservice}
								</div>
								<div className="col col-12 wrap-title-instagram" style={{"marginBottom": "20px", "padding": "0"}}>
									<div className="card text-center text-primary">
										<h5 className="font-weight-bold text-uppercase p-0" style={{"marginTop": "12px", "marginBottom": "12px"}}> Danh Sách Dịch Vụ Instagram</h5>
									</div>
								</div>
								<div className="row mb-3">
									{instagramservice}
								</div>
								<div className="col col-12 wrap-title-youtube" style={{"marginBottom": "20px", "padding": "0"}}>
									<div className="card text-center text-primary">
										<h5 className="font-weight-bold text-uppercase p-0" style={{"marginTop": "12px", "marginBottom": "12px"}}> Danh Sách Dịch Vụ Youtube</h5>
									</div>
								</div>
								<div className="row mb-3">
									{youtubeservice}
								</div>
								<div className="col col-12 wrap-title-google" style={{"marginBottom": "20px", "padding": "0"}}>
									<div className="card text-center text-primary">
										<h5 className="font-weight-bold text-uppercase p-0" style={{"marginTop": "12px", "marginBottom": "12px"}}> Danh Sách Dịch Vụ Google</h5>
									</div>
								</div>
								<div className="row mb-3">
									{googleservice}
								</div>
								<div className="col col-12 wrap-title-telegram" style={{"marginBottom": "20px", "padding": "0"}}>
									<div className="card text-center text-primary">
										<h5 className="font-weight-bold text-uppercase p-0" style={{"marginTop": "12px", "marginBottom": "12px"}}> Danh Sách Dịch Vụ Telegram</h5>
									</div>
								</div>
								<div className="row mb-3">
									{telegramservice}
								</div>
								<div className="col col-12 wrap-title-twitter" style={{"marginBottom": "20px", "padding": "0"}}>
									<div className="card text-center text-primary">
										<h5 className="font-weight-bold text-uppercase p-0" style={{"marginTop": "12px", "marginBottom": "12px"}}> Danh Sách Dịch Vụ Twitter</h5>
									</div>
								</div>
								<div className="row mb-3">
									{twitterservice}
								</div>
								<div className="col col-12 wrap-title-shopee" style={{"marginBottom": "20px", "padding": "0"}}>
									<div className="card text-center text-primary">
										<h5 className="font-weight-bold text-uppercase p-0" style={{"marginTop": "12px", "marginBottom": "12px"}}> Danh Sách Dịch Vụ Shopee</h5>
									</div>
								</div>
								<div className="row mb-3">
									{shopeeservice}
								</div>
							</div>
						</div>
			    	</div>
				</div>
    		</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	adminpage: state.adminpage
});

export default connect(mapStateToProps, { getDataIsUser, saveDataUser,loadInfoUser, getDataFeature, logout }) (Quanlytaikhoan);
