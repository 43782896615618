import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addFVVV, updateFVVV, loadPriceFVVV } from '../../../actions/FacebookvipviewvideoActions';
import { add, updateFbVip, getIdFbVip } from '../../../actions/FbvipActions';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		addFVVV: PropTypes.func.isRequired,
		updateFVVV: PropTypes.func.isRequired,
		add: PropTypes.func.isRequired,
		updateFbVip: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	/*componentDidMount() {
		this.props.loadPriceFVVV();
	};*/
	
	option_tgsd = () => {
		let ar_option_tgsd = [];
		ar_option_tgsd.push(
			<option value="0.5" key="0.5">
				15 Ngày
			</option>
		);
		for (let i = 1; i <= 6; i++) {
			ar_option_tgsd.push(
				<option value={i} key={i}>
					{i} Tháng
				</option>
			)
		}
		return ar_option_tgsd;
	}
	
	onChange = (e) => {
		const { taotientrinh } = this.props.Facebookvipviewvideo;
		if ((e.target.name === "idfb") && (e.target.value.indexOf("facebook.com") > -1)) {
			if (e.target.value !== "") {
				var matches_uid = e.target.value.match(/id=(.*?)&/);
        		if (matches_uid != null) {
					var new_taotientrinh = {
						...taotientrinh,
						"idfb": matches_uid[1]
					};
        		} else {
					var matches_uid = e.target.value.match(/id=(.*)/);
					if (matches_uid != null) {
						var new_taotientrinh = {
							...taotientrinh,
							"idfb": matches_uid[1]
						};
					} else {
						var new_taotientrinh = {
							...taotientrinh,
							[e.target.name]: e.target.value
						};
						this.props.getIdFbVip('facebookvipviewvideo', new_taotientrinh);
					}
        		}
			}
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateFbVip('facebookvipviewvideo', new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Facebookvipviewvideo;
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user
		};
		if (taotientrinh.idfb === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'ID FB cần chạy không được để trống',
			});
			return false;
		}
		if (taotientrinh.goiview === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Gói view không được để trống',
			});
			return false;
		}
		if (taotientrinh.goiview % 1000 !== 0) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Gói view không phải là bội số của 1000',
			});
			return false;
		}
		if (taotientrinh.goiview < 1000) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Gói view không được nhỏ hơn 1000',
			});
			return false;
		}
		if (taotientrinh.goiview > 100000) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Gói view không được vượt quá 100000',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá 1 view / 1 tháng: "+taotientrinh.gtmtt+" Coin? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.add('facebookvipviewvideo', new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { idfb, usernamefb, goiview, tgsd, gtmtt } = this.props.Facebookvipviewvideo.taotientrinh;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_view_sv1 = '';
		if (typeof(listDataServicesInfo.facebook_vip_view_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_vip_view_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_vip_view_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_vip_view) !== 'undefined') {
			html_view_sv1 = parse(listDataServicesInfo.facebook_vip_view.ghichu_sv);
			if ("1" === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_view.ghichu);
			}
		}
		
		var price = Math.round((goiview * tgsd * gtmtt + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID Profile cài VIP:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="idfb" name="idfb" placeholder="Vui Lòng Nhập Chính Xác ID FB Người Hưởng Like" onChange={this.onChange} value={idfb} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Tên hoặc ghi chú:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="usernamefb" name="usernamefb" placeholder="Nhập Ghi Chú hoặc tên" onChange={this.onChange} value={usernamefb} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Gói View (là bội số của 1000 min:1000 max:100000) (*):</label>
					<div className="col-sm-8">
						<input type="number" className="form-control" id="goiview" name="goiview" placeholder="Vui Lòng Nhập Chính Xác" onChange={this.onChange} value={goiview} onKeyPress={this.onKeyPress} />
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Thời Gian Sử Dụng:
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<select className="form-control custom-select select-light" id="tgsd" name="tgsd" onChange={this.onChange} value={tgsd} >
								{this.option_tgsd()}
							</select>
						</div>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={"1"} tab="facebook_vip_view" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">1 view</span> / 1 tháng: <span className="green">{gtmtt} Coin</span></span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookvipviewvideo: state.Facebookvipviewvideo,
});
const mapDispatchToProps = { addFVVV, updateFVVV, loadPriceFVVV, add, updateFbVip, getIdFbVip }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);