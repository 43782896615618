import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import { updateBuffgrouptele, addBuffgrouptele, searchIdFb, getConfigMoDong } from '../../../actions/buffgroupteleActions';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

class taotientrinh extends Component {
	static propTypes =  {
		updateBuffgrouptele: PropTypes.func.isRequired,
		addBuffgrouptele: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceFLBBKTT();
		this.props.getConfigMoDong();
	};
	
	onChange = (e) => {
		var { taotientrinh } = this.props.buffgrouptele;
		if (e.target.name === "loaikeomem") {
			if (e.target.value === "0") {
				var value = this.props.buffgrouptele.price.buffgrouptele_random.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (e.target.value === "1") {
				var value = this.props.buffgrouptele.price.buffgrouptele_theoyeucau.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			if (e.target.value === "2") {
				var value = this.props.buffgrouptele.price.buffgrouptele_random_sv2.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
			};
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateBuffgrouptele(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.buffgrouptele;
		var new_taotientrinh = {
			...taotientrinh,
		};
		var price = Math.round((taotientrinh.slct * taotientrinh.gtmtt + Number.EPSILON) * 100) / 100;
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn buff "+taotientrinh.slct+" với giá: "+price+" Coin? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addBuffgrouptele(new_taotientrinh);
			}
		})
	}
	
	searchUidFacebook = (e) => {
		let valueS = e.target.value;
		let indexofLinkFb = valueS.lastIndexOf('facebook.com');
		if (indexofLinkFb > -1) {
			this.props.searchIdFb(valueS, e.target.name);
		} else {
			const { taotientrinh, price } = this.props.buffgrouptele;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateBuffgrouptele(new_taotientrinh);
		}
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { lgt, loaikeomem, lgtlm, slct, fb, gtmtt } = this.props.buffgrouptele.taotientrinh;
 		const { telegrambuffmembergroup_random_mo_dong, telegrambuffmembergroup_random_sv2_mo_dong, telegrambuffmembergroup_yeucau_mo_dong } = this.props.buffgrouptele;
		var price = Math.round((gtmtt * slct + Number.EPSILON) * 100) / 100;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_membergroup_sv1 = '';
		var html_membergroup_sv2 = '';
		var html_membergroup_theoyeucau = '';
		if (typeof(listDataServicesInfo.telegram_buff_membergroup_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.telegram_buff_membergroup_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.telegram_buff_membergroup_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.telegram_buff_membergroup_sv1) !== 'undefined') {
			html_membergroup_sv1 = parse(listDataServicesInfo.telegram_buff_membergroup_sv1.ghichu_sv);
			if (loaikeomem.toString() === "0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.telegram_buff_membergroup_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.telegram_buff_membergroup_sv2) !== 'undefined') {
			html_membergroup_sv2 = parse(listDataServicesInfo.telegram_buff_membergroup_sv2.ghichu_sv);
			if (loaikeomem.toString() === "2") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.telegram_buff_membergroup_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.telegram_buff_membergroup_theoyeucau) !== 'undefined') {
			html_membergroup_theoyeucau = parse(listDataServicesInfo.telegram_buff_membergroup_theoyeucau.ghichu_sv);
			if (loaikeomem.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.telegram_buff_membergroup_theoyeucau.ghichu);
			}
		}
		
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link group telegram:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lgt" name="lgt" placeholder="Nhập link group telegram" onChange={this.onChange} value={lgt} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại kéo mem:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgrouptele.price.buffgrouptele_random.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="0" type="radio" className="custom-control-input" id="loai_0" name="loaikeomem" checked={loaikeomem.toString() === "0"} disabled={this.props.buffgrouptele.price.buffgrouptele_random.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loai_0" > SV1 Random {html_membergroup_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgrouptele.price.buffgrouptele_random.prices_web} coin</span> {telegrambuffmembergroup_random_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgrouptele.price.buffgrouptele_random_sv2.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="2" type="radio" className="custom-control-input" id="loai_2" name="loaikeomem" checked={loaikeomem.toString() === "2"} disabled={this.props.buffgrouptele.price.buffgrouptele_random_sv2.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loai_2" > SV2 Random {html_membergroup_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgrouptele.price.buffgrouptele_random_sv2.prices_web} coin</span> {telegrambuffmembergroup_random_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div><br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.buffgrouptele.price.buffgrouptele_theoyeucau.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="1" type="radio" className="custom-control-input" id="loai_1" name="loaikeomem" checked={loaikeomem.toString() === "1"} disabled={this.props.buffgrouptele.price.buffgrouptele_theoyeucau.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loai_1" > Theo yêu cầu {html_membergroup_theoyeucau} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.buffgrouptele.price.buffgrouptele_theoyeucau.prices_web} coin</span> {telegrambuffmembergroup_yeucau_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				{
					loaikeomem.toString() === "1" ?
						<div className="form-group row">
							<label className="col-sm-4 col-form-label" htmlFor="">Link group telegram muốn lấy:</label>
							<div className="col-sm-8">
								<input type="text" className="form-control" id="lgtlm" name="lgtlm" placeholder="Nhập link group telegram muốn lấy" onChange={this.onChange} value={lgtlm} />
							</div>
						</div>
					: ''
				}
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Số lượng cần tăng:</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
                            <input type="number" className="form-control" onChange={this.onChange} name="slct" value={slct} onKeyPress={this.onKeyPress}/>
                        </div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<label className="mt-2">Link Facebook liên hệ (có vấn đề bên admin sẽ thông báo):</label>
					</div>
					<div className="col-md-8">
						<div className="form-group">
							<input type="text" className="form-control" id="fb" name="fb" placeholder="Nhập link facebook" onChange={(e) => {this.searchUidFacebook(e)}} value={fb}  />
                        </div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={loaikeomem.toString()} tab="telegram_buff_membergroup" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ buff <span className="bold green">{slct} member</span> với giá <span className="green bold">{price}</span> Coin</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	buffgrouptele: state.buffgrouptele,
});
const mapDispatchToProps = { updateBuffgrouptele, addBuffgrouptele, searchIdFb, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);