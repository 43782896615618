import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import store from '../store';
import Quanlytaikhoan from "./login_tool/Quanlytaikhoan";
import NapTien from "./login_tool/home/payment/naptien";
import NapTienThe from "./login_tool/home/payment/naptienthe";
import SiteAgency from "./login_tool/home/agency/siteagency";
import Support from "./login_tool/home/support/support";

import FindMyId from "./login_tool/FindMyId";
import ShieldAvatar from "./login_tool/shieldavatar";
import Tooledittext from "./login_tool/tooledittext/tooledittext";

import Buffsub from "./login_tool/Buffsub";
import Buffsub_slow from "./login_tool/itembuffsub_slow";
import Buffsub_sale from "./login_tool/itembuffsub_sale";
import Bufflikefanpage from "./login_tool/itembufflikefanpage";
import Bufflikefanpagesale from "./login_tool/itembufflikefanpagesale";
import Buffsubfanpage from "./login_tool/itembuffsubfanpage";
import RechargeCardPhone from "./login_tool/item_recharge_card_phone";
import Buy_proxy from "./login_tool/item_buy_proxy";
import Buffmatlivestream from "./login_tool/Buffmatlivestream";
import Bufflikecommentshare from "./login_tool/Bufflikecommentshare";
import Bufflikecommentshare_like from "./login_tool/Bufflikecommentshare_like";
import Bufflikecommentshare_comment from "./login_tool/Bufflikecommentshare_comment";
import Bufflikecommentshare_likecomment from "./login_tool/Bufflikecommentshare_likecomment";
import Bufflikecommentshare_share from "./login_tool/Bufflikecommentshare_share";
import Buffseedingreview from "./login_tool/Buffseedingreview";
import Buffsublikev2 from "./login_tool/Buffsublikev2";
import Buffgroup from "./login_tool/Buffgroup";
import Buffgroupsharelive from "./login_tool/Buffgroupsharelive";
import Bufflikesubcomment from "./login_tool/Bufflikesubcomment";
import Viplikeclone from "./login_tool/Viplikeclone";
import Viplikegroup from "./login_tool/Viplikegroup";
import Vipcommentthang from "./login_tool/Vipcommentthang";
import Viplikethang from "./login_tool/Viplikethang";
import Muabanclone from "./login_tool/Muabanclone";
import Facebookbot from "./login_tool/Facebookbot";
import Facebookbotv2 from "./login_tool/Facebookbotv2";
import Facebookbotlovestory from "./login_tool/Facebookbotlovestory";
import Facebookbotcmt from "./login_tool/Facebookbotcmt";
import Facebookvipcomment from "./login_tool/Facebookvipcomment";
import Facebookvipmatlivestream from "./login_tool/Facebookvipmatlivestream";
import Facebookvipmatlivestreamv2 from "./login_tool/Facebookvipmatlivestreamv2";
import Facebookvipviewvideo from "./login_tool/Facebookvipviewvideo";
import Facebooklocbanbekhongtuongtac from "./login_tool/Facebooklocbanbekhongtuongtac";
import Facebookbotreplyinboxcho from "./login_tool/Facebookbotreplyinboxcho";
import Getcookiefb from "./login_tool/Getcookiefb";
import Shopee from "./login_tool/shopee";
import Shopeetim from "./login_tool/shopeetim";
import Shopeeseedinglivestream from "./login_tool/shopeeseedinglivestream";
import Shopeeviplivestream from "./login_tool/shopeeviplivestream";
import Spamsms from "./login_tool/spamsms";
import Spamcall from "./login_tool/spamcall";
import Ripacc from "./login_tool/ripacc";
import Reportyoutube from "./login_tool/reportyoutube";
import Googlemap from "./login_tool/googlemap";
import Instagramviplike from "./login_tool/instagramviplike";
import Renamefanpage from "./login_tool/renamefanpage";
import Muafanpage from "./login_tool/muafanpage";
import Buffgrouptele from "./login_tool/buffgrouptele";
import Buffviewstory from "./login_tool/buffviewstory";
import Facebookadbreaksgioxem from "./login_tool/Facebookadbreaksgioxem";
import Facebookadbreakstuongtac from "./login_tool/Facebookadbreakstuongtac";
import Facebookadbreaksviewdexuat from "./login_tool/Facebookadbreaksviewdexuat";
import Twitterlike from "./login_tool/Twitterlike";
import Twitterfollow from "./login_tool/Twitterfollow";
import Emailtouid from "./login_tool/emailtouid";
import Thuesim from "./login_tool/thuesim";
import Muabanfanpagegroup from "./login_tool/muabanfanpagegroup";
import Spotifyfollow from "./login_tool/Spotifyfollow";
import Spotifyplay from "./login_tool/Spotifyplay";
import Soundcloudfollow from "./login_tool/Soundcloudfollow";
import Soundcloudplay from "./login_tool/Soundcloudplay";
import Soundcloudlike from "./login_tool/Soundcloudlike";
import Vimeofollow from "./login_tool/Vimeofollow";
import Vimeoview from "./login_tool/Vimeoview";

import Buffseedinglikeinstagramorder from "./login_tool/Buffseedinglikeinstagramorder";
import Buffseedingsubinstagramorder from "./login_tool/Buffseedingsubinstagramorder";
import Buffseedingcommentinstagramorder from "./login_tool/Buffseedingcommentinstagramorder";
import Buffseedingviewinstagramorder from "./login_tool/Buffseedingviewinstagramorder";

import Youtubeview from "./login_tool/youtubeview";
import Youtubegioxem from "./login_tool/youtubegioxem";
import Youtubelike from "./login_tool/youtubelike";
import Youtubedislike from "./login_tool/youtubedislike";
import Youtubetichnghesi from "./login_tool/youtubetichnghesi";
import Youtubelikecomment from "./login_tool/youtubelikecomment";
import Youtubecomment from "./login_tool/youtubecomment";
import Youtubesub from "./login_tool/youtubesub";

import SiteContentfacebookbuff from "./login_tool/siteContentfacebookbuff/sitecontent";
import SiteContentfacebookbuffsubslow from "./login_tool/siteContentfacebookbuffsubslow/sitecontent";
import SiteContentnghiemcam from "./login_tool/siteContentnghiemcam/sitecontent";
import SiteContentfacebookbuffmatlivestream from "./login_tool/siteContentfacebookbuffmatlivestream/sitecontent";
import SiteContentfacebookvip from "./login_tool/siteContentfacebookvip/sitecontent";
import SiteContentinstagram from "./login_tool/siteContentinstagram/sitecontent";
import SiteContentyoutube from "./login_tool/siteContentyoutube/sitecontent";
import SiteContentsupport from "./login_tool/siteContentsupport/sitecontent";
import SiteContentQuanlytaikhoan from "./login_tool/siteContentQuanlytaikhoan/sitecontent";
import SiteContentfacebookbot from "./login_tool/siteContentfacebookbot/sitecontent";
import SiteContentfacebookbotv2 from "./login_tool/siteContentfacebookbotv2/sitecontent";
import SiteContentfacebookbotlovestory from "./login_tool/siteContentfacebookbotlovestory/sitecontent";
import SiteContentfacebookbotcmt from "./login_tool/siteContentfacebookbotcmt/sitecontent";
import SiteContentfacebookbotreplyinboxcho from "./login_tool/siteContentfacebookbotreplyinboxcho/sitecontent";
import SiteContentmuabanclone from "./login_tool/siteContentmuabanclone/sitecontent";
import SiteContentfacebookvipcomment from "./login_tool/siteContentfacebookvipcomment/sitecontent";
import SiteContentfacebookvipmatlivestream from "./login_tool/siteContentfacebookvipmatlivestream/sitecontent";
import SiteContentfacebookbuffsublivev2 from "./login_tool/siteContentfacebookbuffsublivev2/sitecontent";
import SiteContentfacebookbuffgroup from "./login_tool/siteContentfacebookbuffgroup/sitecontent";
import SiteContentfacebookbuffgroupsharelive from "./login_tool/siteContentfacebookbuffgroupsharelive/sitecontent";
import SiteContentfacebookvipviewvideo from "./login_tool/siteContentfacebookvipviewvideo/sitecontent";
import SiteContentfacebooklocbanbekhongtuongtac from "./login_tool/siteContentfacebooklocbanbekhongtuongtac/sitecontent";
import InfoUser from "./login_tool/infoUser";
import SiteContentInfoUser from "./login_tool/siteContentInfoUser/sitecontent";
import SiteContentfacebookadbreaksviewdexuat from "./login_tool/siteContentfacebookadbreaksviewdexuat/sitecontent";

import Adminpage from "./login_tool/admin";

import Tiktokbuff from './login_tool/item_tiktok';
import Tiktokbuffcmt from './login_tool/item_tiktok_cmt';
import Tiktokbufflike from './login_tool/item_tiktok_like';
import Tiktokbuffview from './login_tool/item_tiktok_view';
import Tiktokbuffshare from './login_tool/item_tiktok_share';
import Tiktokviplike from './login_tool/item_tiktok_viplike';
import Tiktokvipview from './login_tool/item_tiktok_vipview';
import Tiktokbuffmat from './login_tool/item_tiktok_mat';
import Facebookchocbanbe from './login_tool/facebookchocbanbe';
import SiteContentFacebookchocbanbe from "./login_tool/facebookchocbanbe/rightContent";
import Facebookvipbotlive from './login_tool/facebookvipbotlive';
import SiteContentFacebookvipbotlive from "./login_tool/facebookvipbotlive/rightContent";
import Facebookbuffviewvideo from './login_tool/facebookbuffviewvideo';
import SiteContentFacebookbuffviewvideo from "./login_tool/facebookbuffviewvideo/rightContent";
import History_order from "./login_tool/history_order";
import Refund_order from "./login_tool/refund_order";

import SiteContentRechargeCardPhone from "./login_tool/item_recharge_card_phone/rightContent";
import SiteContentBuffLikeFanpage from "./login_tool/itembufflikefanpage/rightContent";
import SiteContentBuyproxy from "./login_tool/item_buy_proxy/rightContent";
import Getotp from "./login_tool/Getotp";
import { isMobile } from 'react-device-detect';
// import { getAllDataPriceFunction} from '../actions/adminPageAction';

import { getAllDataPriceFunction,getAllDataPriceFunctionFromCache,getPriceMedium } from '../actions/adminPageAction';
class Body extends Component {
  componentDidMount(){
	try {
		if (localStorage.getItem("listDataPriceAll") == null) {
			this.props.getAllDataPriceFunction();
		} else {
			var listDataPriceAll = JSON.parse(localStorage.getItem("listDataPriceAll"));
			var checktime = {checktime:0};
			checktime = listDataPriceAll[listDataPriceAll.length - 1];
			if (typeof checktime.checktime === 'undefined' || checktime.checktime == 0 || (parseInt(Date.now() / 1000) - checktime.checktime > 300)) {
				this.props.getAllDataPriceFunction();
			} else {
				this.props.getAllDataPriceFunctionFromCache();
			}
		}
	} catch(e) {
		this.props.getAllDataPriceFunction();
	}
    // this.props.getPriceMedium();
  }

  render() {
    var isOpenSidebar = this.props.auth.isOpenSidebar;
	var path_all = [
					"/tool/Bufflikecommentshare", "/tool/Bufflikecommentshare_like", "/tool/Bufflikecommentshare_comment", "/tool/Bufflikecommentshare_likecomment", "/tool/Bufflikecommentshare_share",
					"/tool/Buffsub", "/tool/buffsub_slow", "/tool/buffsublikev2", "/tool/Bufflikefanpage", "/tool/buff-seeding-review", "/tool/facebookbuffview", "/tool/buffmatlivestream",
					"/tool/vip-like-clone", "/tool/vip-comment-seeding-order", "/tool/vip-seeding-order", "/tool/facebookvipcomment", "/tool/facebookvipviewvideo", "/tool/facebookvipmatlivestream", "/tool/facebooklocbanbekhongtuongtac", "/tool/facebookchocbanbe",
					"/tool/facebookbotv2", "/tool/facebookbotcmt", "/tool/facebookbotreplyinboxcho", "/tool/facebookvipbotlive", "/tool/buy_proxy", "/tool/buy_proxy",
					"/tool/buff-seeding-sub-instagram-order", "/tool/buff-seeding-comment-instagram-order", "/tool/buff-seeding-view-instagram-order", "/tool/facebookbotlovestory", "/tool/facebookadbreaksviewdexuat",
					"/tool/vip-like-group", "/tool/buffsub_sale", "/tool/Bufflikefanpagesale", "/tool/Buffsubfanpage", "/tool/buffgroupsharelive", "/tool/buffgroup", "/tool/buffviewstory", "/tool/facebookvipmatlivestreamv2", "/tool/bufflikesubcomment",
					"/tool/facebookadbreaksgioxem", "/tool/facebookadbreakstuongtac", "/tool/buff-seeding-like-instagram-order", "/tool/instagramviplike", "/tool/youtube-view", "/tool/youtube-like", "/tool/youtube-dislike", "/tool/youtube-tichnghesi", "/tool/youtube-likecomment", "/tool/youtube-comment", "/tool/youtube-sub",
					"/tool/youtube-gioxem", "/tool/tiktokbufflike", "/tool/tiktokbuffcmt", "/tool/tiktokbuff", "/tool/tiktokbuffview", "/tool/tiktokbuffshare", "/tool/shopee", "/tool/shopeeseedinglivestream", "/tool/shopeeviplivestream", "/tool/shopeetim",
					"/tool/googlemap", "/tool/buffgrouptele", "/tool/twitterlike", "/tool/twitterfollow", "/tool/ho-tro-nhan-tin", "/tool/ho-tro-cuoc-goi", "/tool/tiktokviplike", "/tool/tiktokvipview", "/tool/tiktokbuffmat", "/tool/spotifyfollow", "/tool/spotifyplay",
					"/tool/soundcloudfollow", "/tool/soundcloudplay", "/tool/soundcloudlike", "/tool/vimeofollow", "/tool/vimeoview"];
    var path_check = window.location.pathname;
	if (window.location.pathname.length > 1) {
		path_check = window.location.pathname.replace(/\/+$/g, '');
		
		if (path_check.indexOf('/support') > -1) {
			path_all.push(path_check);
		}
	}
	var config_website = {};
	try {
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
	} catch(e) {
		// this.props.getDataBtConfigBgNav();
	}
	if (path_check === '/resetpassword') {
		store.dispatch({
			type: "LOGIN_FAIL"
		});
	}
    return (
      <main id="main-container">
        <div className="row no-gutters flex-md-10-auto">
		  <div className={path_all.indexOf(path_check) > -1 ? "col-md-9 col-lg-9 col-xl-9 order-md-0": "col-md-12 col-lg-12 col-xl-12 order-md-0" }>
            <div className="content">
              <Route exact path="/">
                <Quanlytaikhoan />
              </Route>

              <Route exact path="/admin">
                <Adminpage />
              </Route>

              <Route exact path="/tool/renamefanpage">
                <Renamefanpage />
              </Route>
              <Route exact path="/muafanpage">
                <Muafanpage />
              </Route>
              <Route exact path="/tool/tiktokbuff">
                <Tiktokbuff />
              </Route>
              <Route exact path="/tool/tiktokbuffcmt">
                <Tiktokbuffcmt />
              </Route>
              <Route exact path="/tool/tiktokbufflike">
                <Tiktokbufflike />
              </Route>
              <Route exact path="/tool/tiktokbuffview">
                <Tiktokbuffview />
              </Route>
              <Route exact path="/tool/tiktokbuffshare">
                <Tiktokbuffshare />
              </Route>
              <Route exact path="/tool/tiktokviplike">
                <Tiktokviplike />
              </Route>
              <Route exact path="/tool/tiktokvipview">
                <Tiktokvipview />
              </Route>
              <Route exact path="/tool/tiktokbuffmat">
                <Tiktokbuffmat />
              </Route>
              <Route exact path="/tool/shopee">
                <Shopee />
              </Route>
              <Route exact path="/tool/shopeetim">
                <Shopeetim />
              </Route>
              <Route exact path="/tool/shopeeseedinglivestream">
                <Shopeeseedinglivestream />
              </Route>
              <Route exact path="/tool/shopeeviplivestream">
                <Shopeeviplivestream />
              </Route>
              <Route exact path="/tool/ho-tro-nhan-tin">
                <Spamsms />
              </Route>
              <Route exact path="/tool/ho-tro-cuoc-goi">
                <Spamcall />
              </Route>
              <Route exact path="/tool/buffgrouptele">
                <Buffgrouptele />
              </Route>
              <Route exact path="/tool/buffviewstory">
                <Buffviewstory />
              </Route>
              <Route exact path="/tool/facebookadbreaksgioxem">
                <Facebookadbreaksgioxem />
              </Route>
              <Route exact path="/tool/facebookadbreakstuongtac">
                <Facebookadbreakstuongtac />
              </Route>
              <Route exact path="/tool/facebookadbreaksviewdexuat">
                <Facebookadbreaksviewdexuat />
              </Route>
              <Route exact path="/tool/twitterlike">
                <Twitterlike />
              </Route>
              <Route exact path="/tool/twitterfollow">
                <Twitterfollow />
              </Route>
              <Route exact path="/tool/spotifyfollow">
                <Spotifyfollow />
              </Route>
              <Route exact path="/tool/spotifyplay">
                <Spotifyplay />
              </Route>
              <Route exact path="/tool/soundcloudfollow">
                <Soundcloudfollow />
              </Route>
              <Route exact path="/tool/soundcloudplay">
                <Soundcloudplay />
              </Route>
              <Route exact path="/tool/soundcloudlike">
                <Soundcloudlike />
              </Route>
              <Route exact path="/tool/vimeofollow">
                <Vimeofollow />
              </Route>
              <Route exact path="/tool/vimeoview">
                <Vimeoview />
              </Route>
              <Route exact path="/tool/emailtouid">
                <Emailtouid />
              </Route>
              <Route exact path="/tool/thuesim">
                <Thuesim />
              </Route>
              <Route exact path="/muabanfanpagegroup">
				{
				  config_website.displaytabmuabanfanpagegroup == 1 ?
					<Muabanfanpagegroup />
				  : 
					<Redirect push to="/"/>
				}
              </Route>
              {/* <Route exact path="/tool/ripacc">
                <Ripacc />
              </Route> */}
              <Route exact path="/tool/reportyoutube">
                <Reportyoutube />
              </Route>
              <Route exact path="/tool/googlemap">
                <Googlemap />
              </Route>
              <Route exact path="/tool/instagramviplike">
                <Instagramviplike />
              </Route>
              <Route exact path="/tool/facebookchocbanbe">
                <Facebookchocbanbe />
              </Route>
              <Route exact path="/tool/facebookvipbotlive">
                <Facebookvipbotlive />
              </Route>
              <Route exact path="/tool/facebookbuffview">
                <Facebookbuffviewvideo />
              </Route>
              <Route exact path="/info-user">
                <InfoUser />
              </Route>
              <Route exact path="/tool/history_order">
                <History_order />
              </Route>
              <Route exact path="/tool/refund_order">
				{
					this.props.auth.user.type !== 1 ?
						<Redirect push to="/"/>
                    :
						<Refund_order />
                }
              </Route>

              {/* <Route exact path="/tool/Bufflikecommentshare">
                <Bufflikecommentshare />
              </Route> */}
			  <Route exact path="/tool/Bufflikecommentshare_like">
                <Bufflikecommentshare_like />
              </Route>
              <Route exact path="/tool/Bufflikecommentshare_comment">
                <Bufflikecommentshare_comment />
              </Route>
              <Route exact path="/tool/Bufflikecommentshare_likecomment">
                <Bufflikecommentshare_likecomment />
              </Route>
              <Route exact path="/tool/Bufflikecommentshare_share">
                <Bufflikecommentshare_share />
              </Route>
              <Route exact path="/tool/buffmatlivestream">
                <Buffmatlivestream />
              </Route>
              <Route exact path="/tool/Buffsub">
                <Buffsub />
              </Route>
              <Route exact path="/tool/Buffsub_slow">
                <Buffsub_slow />
              </Route>
              <Route exact path="/tool/Buffsub_sale">
                <Buffsub_sale />
              </Route>
              <Route exact path="/tool/Bufflikefanpage">
                <Bufflikefanpage />
              </Route>
              <Route exact path="/tool/Bufflikefanpagesale">
                <Bufflikefanpagesale />
              </Route>
              <Route exact path="/tool/Buffsubfanpage">
                <Buffsubfanpage />
              </Route>
              <Route exact path="/tool/recharge_card_phone">
                <RechargeCardPhone />
              </Route>
              <Route exact path="/tool/buy_proxy">
                <Buy_proxy />
              </Route>
              <Route exact path="/tool/buff-seeding-review">
                <Buffseedingreview />
              </Route>
              <Route exact path="/tool/buffsublikev2">
                <Buffsublikev2 />
              </Route>
              <Route exact path="/tool/buffgroup">
                <Buffgroup />
              </Route>
              <Route exact path="/tool/buffgroupsharelive">
                <Buffgroupsharelive />
              </Route>
              <Route exact path="/tool/bufflikesubcomment">
                <Bufflikesubcomment />
              </Route>
              <Route exact path="/tool/vip-like-clone">
                <Viplikeclone />
              </Route>
              <Route exact path="/tool/vip-like-group">
                <Viplikegroup />
              </Route>
              <Route exact path="/tool/vip-comment-seeding-order">
                <Vipcommentthang />
              </Route>
              <Route exact path="/tool/vip-seeding-order">
                <Viplikethang />
              </Route>
              <Route exact path="/tool/facebookbot">
                <Facebookbot />
              </Route>
              <Route exact path="/tool/facebookbotv2">
                <Facebookbotv2 />
              </Route>
			  <Route exact path="/tool/facebookbotlovestory">
				<Facebookbotlovestory />
			  </Route>
              <Route exact path="/tool/facebookbotcmt">
                <Facebookbotcmt />
              </Route>
              {/* <Route exact path="/muabanclone">
                <Muabanclone />
              </Route> */}
              <Route exact path="/tool/facebookvipcomment">
                <Facebookvipcomment />
              </Route>
              <Route exact path="/tool/facebookvipmatlivestream">
                <Facebookvipmatlivestream />
              </Route>
              <Route exact path="/tool/facebookvipmatlivestreamv2">
                <Facebookvipmatlivestreamv2 />
              </Route>
              <Route exact path="/tool/facebookvipviewvideo">
                <Facebookvipviewvideo />
              </Route>
              <Route exact path="/tool/facebooklocbanbekhongtuongtac">
                <Facebooklocbanbekhongtuongtac />
              </Route>
              <Route exact path="/tool/facebookbotreplyinboxcho">
                <Facebookbotreplyinboxcho />
              </Route>

              <Route exact path="/tool/buff-seeding-like-instagram-order">
                <Buffseedinglikeinstagramorder />
              </Route>
              <Route exact path="/tool/buff-seeding-sub-instagram-order">
                <Buffseedingsubinstagramorder />
              </Route>
              <Route exact path="/tool/buff-seeding-comment-instagram-order">
                <Buffseedingcommentinstagramorder />
              </Route>
              <Route exact path="/tool/buff-seeding-view-instagram-order">
                <Buffseedingviewinstagramorder />
              </Route>
			  
              <Route exact path="/tool/youtube-view">
                <Youtubeview />
              </Route>
              <Route exact path="/tool/youtube-gioxem">
                <Youtubegioxem />
              </Route>
              <Route exact path="/tool/youtube-like">
                <Youtubelike />
              </Route>
              <Route exact path="/tool/youtube-dislike">
                <Youtubedislike />
              </Route>
              <Route exact path="/tool/youtube-tichnghesi">
                <Youtubetichnghesi />
              </Route>
              <Route exact path="/tool/youtube-likecomment">
                <Youtubelikecomment />
              </Route>
              <Route exact path="/tool/youtube-comment">
                <Youtubecomment />
              </Route>
              <Route exact path="/tool/youtube-sub">
                <Youtubesub />
              </Route>

              <Route exact path="/tool/get-otp">
                <Getotp />
              </Route>
              <Route exact path="/tool/get-cookie">
                <Getcookiefb />
              </Route>
              <Route exact path="/naptien">
                <NapTien />
              </Route>
              <Route exact path="/naptienthe">
                <NapTienThe />
              </Route>
              <Route exact path="/siteagency">
                <SiteAgency typesitecontent={"view"} />
              </Route>
              <Route exact path={["/support", "/support/:id"]} component={Support} />

              <Route exact path="/tool/findmyid">
                <FindMyId />
              </Route>
              <Route exact path="/tool/shieldavatar">
                <ShieldAvatar />
              </Route>
              <Route exact path="/tool/edittext">
                <Tooledittext />
              </Route>
              
            </div>
          </div>
		  <div className="col-md-3 col-lg-3 col-xl-3 order-md-1">
            <div className="content" style={{padding: '0px'}}>
              <div id="side-content" className="d-md-block push">
				  {/* <Route exact path="/">
					<SiteContentQuanlytaikhoan />
				  </Route> */}
				  {/* <Route exact path={["/info-user", "/tool/shieldavatar", "/tool/edittext", "/tool/findmyid", "/tool/get-otp", "/tool/tiktokbuff", "/tool/get-cookie", "/tool/history_order", "/naptien", "/naptienthe", "/admin", "/tool/shopee", "/tool/ho-tro-nhan-tin", "/tool/ho-tro-cuoc-goi", "/tool/ripacc", "/tool/shopeeseedinglivestream", "/tool/shopeeviplivestream", "/tool/shopeetim"]}>
					<SiteContentInfoUser />
				  </Route> */}
				  
				  {/* <Route exact path="/tool/Bufflikecommentshare">
					<SiteContentfacebookbuff />
				  </Route> */}
				  <Route exact path="/tool/Bufflikecommentshare_like">
					<SiteContentfacebookbuff />
				  </Route>
				  <Route exact path="/tool/Bufflikecommentshare_comment">
					<SiteContentfacebookbuff />
				  </Route>
				  <Route exact path="/tool/Bufflikecommentshare_likecomment">
					<SiteContentfacebookbuff />
				  </Route>
				  <Route exact path="/tool/Bufflikecommentshare_share">
					<SiteContentfacebookbuff />
				  </Route>
				  <Route exact path="/tool/buffmatlivestream">
					<SiteContentfacebookbuffmatlivestream />
				  </Route>
				  <Route exact path="/tool/Buffsub">
                    <SiteContentfacebookbuff />
                  </Route>
                  <Route exact path="/tool/Buffsub_slow">
					<SiteContentfacebookbuffsubslow />
				  </Route>
				  <Route exact path="/tool/buff-seeding-review">
					<SiteContentfacebookbuff />
				  </Route>
				  <Route exact path="/tool/buffsublikev2">
					<SiteContentfacebookbuffsublivev2 />
				  </Route>
				  <Route exact path="/tool/vip-like-clone">
					<SiteContentfacebookvip typesitecontent={"vip-like-clone"} />
				  </Route>
				  <Route exact path="/tool/vip-like-group">
					<SiteContentfacebookvip typesitecontent={"vip-like-group"} />
				  </Route>
				  <Route exact path="/tool/vip-comment-seeding-order">
					<SiteContentfacebookvip typesitecontent={"vip-comment-seeding-order"} />
				  </Route>
				  <Route exact path="/tool/vip-seeding-order">
					<SiteContentfacebookvip typesitecontent={"vip-seeding-order"} />
				  </Route>
				  
				  <Route exact path="/tool/buff-seeding-view-instagram-order">
					<SiteContentinstagram typesitecontent={"view"} />
				  </Route>
				  <Route exact path="/tool/buff-seeding-sub-instagram-order">
					<SiteContentinstagram typesitecontent={"subscribe"} />
				  </Route>
				  <Route exact path="/tool/buff-seeding-comment-instagram-order">
					<SiteContentinstagram typesitecontent={"comment"} />
				  </Route>
				  
				  <Route exact path="/tool/youtube-gioxem">
					<SiteContentyoutube typesitecontent={"gioxem"} />
				  </Route>
				  <Route exact path={["/support", "/support/:id"]}>
					<SiteContentsupport />
				  </Route>
				  <Route exact path="/tool/facebookbot">
					<SiteContentfacebookbot />
				  </Route>
				  <Route exact path="/tool/facebookbotv2">
					<SiteContentfacebookbotv2 />
				  </Route>
				  <Route exact path="/tool/facebookbotlovestory">
					<SiteContentfacebookbotlovestory />
				  </Route>
				  <Route exact path="/tool/facebookbotcmt">
					<SiteContentfacebookbotcmt />
				  </Route>
				  <Route exact path="/tool/facebookbotreplyinboxcho">
					<SiteContentfacebookbotreplyinboxcho />
				  </Route>
				  {/* <Route exact path="/muabanclone">
					<SiteContentmuabanclone />
				  </Route> */}
				  <Route exact path="/tool/facebookvipcomment">
					<SiteContentfacebookvipcomment />
				  </Route>
				  <Route exact path="/tool/facebookvipmatlivestream">
					<SiteContentfacebookvipmatlivestream />
				  </Route>
				  <Route exact path="/tool/facebookvipviewvideo">
					<SiteContentfacebookvipviewvideo />
				  </Route>
				  <Route exact path="/tool/facebooklocbanbekhongtuongtac">
					<SiteContentfacebooklocbanbekhongtuongtac />
				  </Route>
                  <Route exact path="/tool/facebookchocbanbe">
                    <SiteContentFacebookchocbanbe />
                  </Route>
                  <Route exact path="/tool/facebookvipbotlive">
                    <SiteContentFacebookvipbotlive />
                  </Route>
                  <Route exact path="/tool/facebookbuffview">
                    <SiteContentFacebookbuffviewvideo />
                  </Route>
                  <Route exact path="/tool/recharge_card_phone">
                    <SiteContentRechargeCardPhone />
                  </Route>
                  <Route exact path="/tool/Bufflikefanpage">
                    <SiteContentBuffLikeFanpage />
                  </Route>
                  <Route exact path="/tool/buy_proxy">
                    <SiteContentBuyproxy />
                  </Route>
                  <Route exact path="/tool/facebookadbreaksviewdexuat">
                    <SiteContentfacebookadbreaksviewdexuat />
                  </Route>
                  <Route exact path="/tool/Buffsub_sale">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/Bufflikefanpagesale">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/Buffsubfanpage">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/buffgroupsharelive">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/buffgroup">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/buffviewstory">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/facebookvipmatlivestreamv2">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/bufflikesubcomment">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/facebookadbreaksgioxem">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/facebookadbreakstuongtac">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/buff-seeding-like-instagram-order">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/instagramviplike">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/youtube-view">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/youtube-like">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/youtube-dislike">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/youtube-tichnghesi">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/youtube-likecomment">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/youtube-comment">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/youtube-sub">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/tiktokbufflike">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/tiktokbuffcmt">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/tiktokbuff">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/tiktokbuffview">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/tiktokbuffshare">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/tiktokviplike">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/tiktokvipview">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/tiktokbuffmat">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/shopee">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/shopeeseedinglivestream">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/shopeeviplivestream">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/shopeetim">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/googlemap">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/buffgrouptele">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/twitterlike">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/twitterfollow">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/spotifyfollow">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/spotifyplay">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/soundcloudfollow">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/soundcloudplay">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/soundcloudlike">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/vimeofollow">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/vimeoview">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/ho-tro-nhan-tin">
					<SiteContentnghiemcam />
				  </Route>
                  <Route exact path="/tool/ho-tro-cuoc-goi">
					<SiteContentnghiemcam />
				  </Route>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getAllDataPriceFunction,getAllDataPriceFunctionFromCache,getPriceMedium })(Body);
