import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addFVC, updateFVC, loadPriceFVC } from '../../../actions/FacebookvipcommentActions';
import { add, updateFbVip, getIdFbVip, getConfigMoDong } from '../../../actions/FbvipActions';
import ModalSticker from './modal_sticker';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';
const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		addFVC: PropTypes.func.isRequired,
		updateFVC: PropTypes.func.isRequired,
		add: PropTypes.func.isRequired,
		updateFbVip: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalSticker: false,
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceFVC();
		this.props.getConfigMoDong('facebookvipcomment');
	};
	
    openModalSticker = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalSticker: !this.state.isOpenModalSticker,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalSticker: !this.state.isOpenModalSticker,
				id: data,
			});
		}
    }
	
	option_goicmt = (lsct) => {
		let ar_option_goicmt = [];

		var j = 100;
		if (lsct == 1) {
			j = 300;
		}
		for (let i = 5; i <= j; i += 5) {
			ar_option_goicmt.push(
				<option value={i} key={i}>
					{i} CMT
				</option>
			)
		}
		return ar_option_goicmt;
	}
	
	option_tgsd = () => {
		let ar_option_tgsd = [];

		for (let i = 1; i <= 1; i++) {
			ar_option_tgsd.push(
				<option value={i} key={i}>
					{i} Tháng
				</option>
			)
		}
		return ar_option_tgsd;
	}
	
	onChange = (e) => {
		const { taotientrinh } = this.props.Facebookvipcomment;
		if ((e.target.name === "idfb") && (e.target.value.indexOf("facebook.com") > -1)) {
			if (e.target.value !== "") {
				var matches_uid = e.target.value.match(/id=(.*?)&/);
        		if (matches_uid != null) {
					var new_taotientrinh = {
						...taotientrinh,
						"idfb": matches_uid[1]
					};
        		} else {
					var matches_uid = e.target.value.match(/id=(.*)/);
					if (matches_uid != null) {
						var new_taotientrinh = {
							...taotientrinh,
							"idfb": matches_uid[1]
						};
					} else {
						var new_taotientrinh = {
							...taotientrinh,
							[e.target.name]: e.target.value
						};
						this.props.getIdFbVip('facebookvipcomment', new_taotientrinh);
					}
        		}
			}
		} else if (e.target.name === "lsct") {
			let dataForm = this.props.Facebookvipcomment;
			var giatien = dataForm.datapricebuffComment.facebookvipcomment.prices_web;
			if (e.target.value == '1') {
				giatien = dataForm.datapricebuffComment.facebookvipcomment_sv2.prices_web;
			}
			var new_taotientrinh = {
				...taotientrinh,
				lsct: e.target.value,
				goicmt: '5',
				tgsd: '30',
				gtmtt: giatien
			};
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateFbVip('facebookvipcomment', new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Facebookvipcomment;
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user
		};
		if (taotientrinh.idfb === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'ID FB cần chạy không được để trống',
			});
			return false;
		}
		var review = [];
		var check_reviewlength = 0;
		if (taotientrinh.ndcmt.trim() !== "") {
			// var res1 = taotientrinh.ndcmt.trim().match(/[!@$%^&*;':\\/|?~"]/g);
			// var res2 = taotientrinh.ndcmt.trim().toLowerCase().match(/dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g);
			// if (res1 !== null) {
				// if (res1.length > 0) {
					// Swal.fire({
						// icon: 'error',
						// title: 'Lỗi',
						// html: 'Nội dung Review thứ 1 không được chứa ký tự đặc biệt: <span class="text-danger font-bold">' + res1[0] + '</span>',
					// });
					// return false;
				// }
			// }
			// if (res2 !== null) {
				// if (res2.length > 0) {
					// Swal.fire({
						// icon: 'error',
						// title: 'Lỗi',
						// html: 'Nội dung Review thứ 1 không được chứa ký tự đặc biệt: <span class="text-danger font-bold">' + res2[0] + '</span>',
					// });
					// return false;
				// }
			// }
			review = taotientrinh.ndcmt.trim().split('\n');
			// review.forEach(function(v, i){
				// if (v.length < 25) {
					// check_reviewlength = 1;
				// }
			// });
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				html: 'Nội dung comment không được để trống!',
			});
			return false;
		}
		// if (check_reviewlength === 1) {
			// Swal.fire({
				// icon: 'error',
				// title: 'Lỗi',
				// html: 'Nội dung của một review tối thiểu phải 25 ký tự',
			// });
			// return false;
		// }
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua Giá 1CMT / 1 tháng: "+taotientrinh.gtmtt+" Coin? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.add('facebookvipcomment', new_taotientrinh);
			}
		})
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
 		const { idfb, usernamefb, lsct, goicmt, tgsd, gioitinh, tocdocmt, gtmtt, sticker_pack } = this.props.Facebookvipcomment.taotientrinh;
 		const { facebookvipcommentclone_mo_dong, facebookvipcommentclone_sv2_mo_dong } = this.props.Facebookvipcomment;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_commentclone_sv1 = '';
		var html_commentclone_sv2 = '';
		if (typeof(listDataServicesInfo.facebook_vip_commentclone_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_vip_commentclone_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_vip_commentclone_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_vip_commentclone_sv1) !== 'undefined') {
			html_commentclone_sv1 = parse(listDataServicesInfo.facebook_vip_commentclone_sv1.ghichu_sv);
			if (lsct.toString() === "0") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_commentclone_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_vip_commentclone_sv2) !== 'undefined') {
			html_commentclone_sv2 = parse(listDataServicesInfo.facebook_vip_commentclone_sv2.ghichu_sv);
			if (lsct.toString() === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_commentclone_sv2.ghichu);
			}
		}
		
		var price = Math.round((goicmt * (tgsd/30) * gtmtt + Number.EPSILON) * 100) / 100;
		var phut = [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
		var selectPhut =(
			<Fragment>
				{
					phut.map(function(value, index){
						return(<option value={value} key={index}>{value} phút</option>)
					})
				}
			</Fragment>
		)
		var listStickerPackage = {};
		try {
			if (localStorage.getItem("listStickerPackage") != null) {
				listStickerPackage = JSON.parse(localStorage.getItem("listStickerPackage"));
			} else {
				listStickerPackage = this.props.adminpage.listStickerPackage;
			}
		} catch(e) {
			listStickerPackage = [];
		}
		var sticker = '';
		var that = this;
		if (listStickerPackage.length > 0) {
			listStickerPackage.pop();
			sticker = listStickerPackage.map(function(v, i) {
				return <Link to='/tool/facebookvipcomment' key={i} onClick={() => {that.openModalSticker(v.id_sticker_package)}} className="btn text-center p-2 mr-1 col-lg-2" style={{"border": (typeof sticker_pack[v.id_sticker_package] !== 'undefined') ? "3px solid green" : '0px solid green'}}><img src={process.env.PUBLIC_URL + '/images/stickerfb/package_sticker/'+v.id_sticker_package+'.png'}  width="50" height="50" /><br/>{v.name}</Link>
			})
		}
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID Profile cài VIP:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="idfb" name="idfb" placeholder="Vui Lòng Nhập Chính Xác IDFB" onChange={this.onChange} value={idfb} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại Server VIP:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.Facebookvipcomment.datapricebuffComment.facebookvipcomment.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChange(e)}} value="0" type="radio" className="custom-control-input" id="lsct_0" name="lsct" checked={lsct === "0"} disabled={this.props.Facebookvipcomment.datapricebuffComment.facebookvipcomment.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_0" > SV1 {html_commentclone_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.Facebookvipcomment.datapricebuffComment.facebookvipcomment.prices_web} coin/tháng</span> {facebookvipcommentclone_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.Facebookvipcomment.datapricebuffComment.facebookvipcomment_sv2.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChange(e)}} value="1" type="radio" className="custom-control-input" id="lsct_1" name="lsct" checked={lsct === "1"} disabled={this.props.Facebookvipcomment.datapricebuffComment.facebookvipcomment_sv2.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_1" > SV2 {html_commentclone_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.Facebookvipcomment.datapricebuffComment.facebookvipcomment_sv2.prices_web} coin/tháng</span> {facebookvipcommentclone_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Tên Người Dùng:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="usernamefb" name="usernamefb" placeholder="Nhập Tên Nick FB" onChange={this.onChange} value={usernamefb} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Nội Dung Comment (Mỗi nội dung 1 dòng!):</label>
					<div className="col-sm-8">
						<textarea rows="6" placeholder="Mỗi nội dung comment 1 dòng, nội dung khác nhau" className="form-control input-gray" id="ndcmt" name="ndcmt" onChange={this.onChange} ></textarea>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Chọn Gói CMT:
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<select className="form-control custom-select select-light" id="goicmt" name="goicmt" onChange={this.onChange} value={goicmt} >
								{this.option_goicmt(lsct)}
							</select>
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Thời Gian Sử Dụng:
					</label>
					<div className="col-sm-8">
						<div className="card card-orange mt-2">
							<select className="form-control custom-select select-light" id="tgsd" name="tgsd" onChange={this.onChange} value={tgsd} >
								{/* this.option_tgsd() */}
								{
									lsct == 0 ?
										<Fragment>
											<option value="15" >15 ngày</option>
										</Fragment>
									: ""
								}
								<option value="30" >30 ngày</option>
								{
									lsct == 0 ?
										<Fragment>
											<option value="60" >60 ngày</option>
											<option value="90" >90 ngày</option>
										</Fragment>
									: ""
								}
							</select>
						</div>
					</div>
				</div>
				{
					lsct == 0 ?
						<Fragment>
							<div className="form-group row mt-4">
								<label className="col-sm-4 col-form-label" htmlFor="">
									Giới Tính (Beta hiện tại chưa lọc đc nam nữ):
								</label>
								<div className="col-sm-8">
									<div className="card card-orange mt-2">
										<select className="form-control custom-select select-light" id="gioitinh" name="gioitinh" onChange={this.onChange} value={gioitinh} >
											<option value="all">Cả Nam Và Nữ</option>
											<option value="male">Chỉ Nam</option>
											<option value="female">Chỉ Nữ</option>
										</select>
									</div>
								</div>
							</div>
							<div className="form-group row mt-4">
								<label className="col-sm-4 col-form-label" htmlFor="">
									Tốc Độ CMT:
								</label>
								<div className="col-sm-8">
									<div className="card card-orange mt-2">
										<select className="form-control custom-select select-light" id="tocdocmt" name="tocdocmt" onChange={this.onChange} value={tocdocmt} >
											{selectPhut}
										</select>
									</div>
								</div>
							</div>
						</Fragment>
					: ''
				}
				{
					lsct == 1 ?
						<Fragment>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label" htmlFor="">Sticker tùy chỉnh:</label>
								<div className="col-sm-8" style={{"height": "200px", "overflow": "auto", "border": "1px solid #cecece"}}>
									{sticker}
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label" htmlFor="">Danh sách link ảnh (link1|link2|link3..):</label>
								<div className="col-sm-8">
									<input placeholder="Nhập danh sách link ảnh?" className="form-control input-gray" id="commentanh" name="commentanh" onChange={this.onChange} />
								</div>
							</div>
						</Fragment>
					: ''
				}
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="facebook_vip_commentclone" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Giá <span className="green">1CMT</span> / 1 tháng: <span className="green">{gtmtt} Coin</span></span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
				{this.state.isOpenModalSticker ? <ModalSticker openModal={this.openModalSticker} isOpenModal={this.state.isOpenModalSticker} dataid={this.state.id} /> : ''}
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookvipcomment: state.Facebookvipcomment,
});
const mapDispatchToProps = { addFVC, updateFVC, loadPriceFVC, add, updateFbVip, getIdFbVip, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);