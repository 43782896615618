import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';
import { addVLT, updateVLT, getID, loadPriceVLT } from '../../../actions/ViplikethangActions';
import { add, getIdFbVip, updateFbVip, getConfigMoDong } from '../../../actions/FbvipActions';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		addVLT: PropTypes.func.isRequired,
		updateVLT: PropTypes.func.isRequired,
		getID: PropTypes.func.isRequired,
		loadPriceVLT: PropTypes.func.isRequired,
		add: PropTypes.func.isRequired,
		getIdFbVip: PropTypes.func.isRequired,
		updateFbVip: PropTypes.func.isRequired,
	};
	
	state = {
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceVLT();
        this.props.getConfigMoDong('viplikethang');
	};
	
	option_slllnct = () => {
		let ar_option_slllnct = [];

		for (let i = 50; i <= 5000; i+=50) {
			ar_option_slllnct.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_slllnct;
	}
	
	onChange = (e) => {
		if ((e.target.name === "lhipcv") && (e.target.value.indexOf("facebook.com") > -1)) {
			if (e.target.value !== "") {
				const { taotientrinh } = this.props.Viplikethang;
				var new_taotientrinh = {
					...taotientrinh,
					[e.target.name]: e.target.value
				};
				this.props.getIdFbVip('viplikethang', new_taotientrinh);
			}
		} else {
			const { taotientrinh } = this.props.Viplikethang;
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
		}
		this.props.updateFbVip('viplikethang', new_taotientrinh);
	}
	
	onChangeLoaiSeeding = (e) => {
		let valueChange = e.target.value;
		let dataForm = this.props.Viplikethang;
		var giatien = 0;
		if (valueChange == 'viplikethang') {
			giatien = dataForm.datapricebuffLike.like_user.prices_web;
		} else if(valueChange == 'viplikethang_likevia'){
			giatien = dataForm.datapricebuffLike.like_via.prices_web;
		} else if(valueChange == 'viplikethang_speed'){
			giatien = dataForm.datapricebuffLike.like_user_speed.prices_web;
		}

		store.dispatch({
			type: 'CHANGE_DATA_FRICE_VIP_LIKE_PRO',
			payload: {
				giatien: giatien,
				type: valueChange,
				slbv: 5,
			}
		});
		// let dataPriceAll = this.props.buffsub.datapricebuffsub;
		// let getStPriceEnd = this.props.buffsub.datapricebuffsub[valueChange].prices_web;
		// if (dataForm.baohanh == 1) {
		// 	getStPriceEnd = this.props.buffsub.datapricebuffsub[valueChange].min_bh_web;
		// }

		

		// let sltang_rt = 10;
		// if (valueChange === 'comment') {
		// 	sltang_rt = 0;
		// }
		// store.dispatch({
		// 	type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
		// 	payload: {name: 'sltang', value: sltang_rt}
		// });

		// store.dispatch({
		// 	type: 'CHANGE_DATA_FORM_BUFF_SUB_TIKTOK',
		// 	payload: {name: e.target.name, value: valueChange}
		// });
	}
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Viplikethang;
		const { money, percent } = this.props.auth.user;
		const { prices_web } = this.props.Viplikethang.taotientrinh.gtmtt;
		
		
		// var final_price = taotientrinh.gtmtt * taotientrinh.slllnct * taotientrinh.slbvtmn * taotientrinh.sncmv;
		
		// if (money < final_price) {
			// Swal.fire({
				// icon: 'error',
				// title: 'Lỗi',
				// text: 'Không đủ tiền trong tài khoản',
			// });
			// return false;
		// }
		
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user
		};
		if (taotientrinh.lhipcv === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Link hoặc ID Profile cài VIP không được để trống',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn VIP Like trong "+taotientrinh.sncmv+" ngày? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.add('viplikethang', new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { lhipcv, lsct, sllnnct, slllnct, slbvtmn, sncmv, gtmtt, slbv, usernamefb } = this.props.Viplikethang.taotientrinh;
		const { facebookviplikepro_sv1_mo_dong, facebookviplikepro_sv2_mo_dong, facebookviplikepro_sv3_mo_dong } = this.props.Viplikethang;
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_likepro_sv1 = '';
		var html_likepro_sv2 = '';
		var html_likepro_sv3 = '';
		if (typeof(listDataServicesInfo.facebook_vip_likepro_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_vip_likepro_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_vip_likepro_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_vip_likepro_sv1) !== 'undefined') {
			html_likepro_sv1 = parse(listDataServicesInfo.facebook_vip_likepro_sv1.ghichu_sv);
			if (lsct.toString() === "viplikethang_speed") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_likepro_sv1.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_vip_likepro_sv2) !== 'undefined') {
			html_likepro_sv2 = parse(listDataServicesInfo.facebook_vip_likepro_sv2.ghichu_sv);
			if (lsct.toString() === "viplikethang") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_likepro_sv2.ghichu);
			}
		}
		if (typeof(listDataServicesInfo.facebook_vip_likepro_sv3) !== 'undefined') {
			html_likepro_sv3 = parse(listDataServicesInfo.facebook_vip_likepro_sv3.ghichu_sv);
			if (lsct.toString() === "viplikethang_likevia") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_vip_likepro_sv3.ghichu);
			}
		}
		
		const { prices_web } = this.props.Viplikethang.price;
		var tile_slbv = slbv*2/10;
		
		var price = Math.round(((gtmtt/30)*slllnct*sncmv*tile_slbv + Number.EPSILON) * 100) / 100;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID Profile cài VIP:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhipcv" name="lhipcv" placeholder="" onChange={this.onChange} value={lhipcv} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Tên Người Dùng hoặc Ghi Chú:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="usernamefb" name="usernamefb" onChange={this.onChange} value={usernamefb} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Loại Server VIP:</label>
					{/*<div className="col-sm-8">
						<select className="form-control" id="lsct" name="lsct" onChange={(e) => {this.onChangeLoaiSeeding(e)}}  value={lsct} >
							<option value="viplikethang">Like người thật (tốc độ chạy bình thường)</option>
							<option value="viplikethang_likevia">Like via việt và via ngoại (sẽ nhanh hơn)</option>
						</select>
					</div>*/}
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.Viplikethang.datapricebuffLike.like_user_speed.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeLoaiSeeding(e)}} value="viplikethang_speed" type="radio" className="custom-control-input" id="lsct_2" name="lsct" checked={lsct === "viplikethang_speed"} disabled={this.props.Viplikethang.datapricebuffLike.like_user_speed.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_2" > SV1 {html_likepro_sv1} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.Viplikethang.datapricebuffLike.like_user_speed.prices_web} coin</span> {facebookviplikepro_sv1_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.Viplikethang.datapricebuffLike.like_user.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeLoaiSeeding(e)}} value="viplikethang" type="radio" className="custom-control-input" id="lsct_0" name="lsct" checked={lsct === "viplikethang"} disabled={this.props.Viplikethang.datapricebuffLike.like_user.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_0" > SV2 {html_likepro_sv2} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.Viplikethang.datapricebuffLike.like_user.prices_web} coin</span> {facebookviplikepro_sv2_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
						<br/>
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.Viplikethang.datapricebuffLike.like_via.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={(e) => {this.onChangeLoaiSeeding(e)}} value="viplikethang_likevia" type="radio" className="custom-control-input" id="lsct_1" name="lsct" checked={lsct === "viplikethang_likevia"} disabled={this.props.Viplikethang.datapricebuffLike.like_via.prices_web == 0 ? 'disabled' : ''} />
							<label className="custom-control-label" htmlFor="lsct_1" > SV3 {html_likepro_sv3} <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.Viplikethang.datapricebuffLike.like_via.prices_web} coin</span> {facebookviplikepro_sv3_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className={(lsct === "viplikethang_speed" ? 'col-md-6' : 'col-md-12')}>
						<div className="form-group">
							<label className="mt-2">Số lượng like cần tăng:</label>
							<select className="form-control" id="slllnct" name="slllnct" onChange={this.onChange} value={slllnct} onKeyPress={this.onKeyPress} >
								{this.option_slllnct()}
							</select>
						</div>
					</div>
					
					{
						lsct === "viplikethang_speed" ?
							<div className="col-md-6">
								<div className="form-group">
									<label className="mt-2">Số lượng bài viết / 1 ngày:</label>
									<select className="form-control" id="slbv" name="slbv" onChange={this.onChange} value={slbv} >
										<option value="5">5 (giá x1)</option>
										<option value="10">10 (giá x2)</option>
										<option value="15">15 (giá x3)</option>
										<option value="20">20 (giá x4)</option>
										<option value="25">25 (giá x5)</option>
										<option value="30">30 (giá x6)</option>
										<option value="35">35 (giá x7)</option>
										<option value="40">40 (giá x8)</option>
										<option value="45">45 (giá x9)</option>
										<option value="50">50 (giá x10)</option>
										<option value="55">55 (giá x11)</option>
										<option value="60">60 (giá x12)</option>
										<option value="65">65 (giá x13)</option>
										<option value="70">70 (giá x14)</option>
										<option value="75">75 (giá x15)</option>
										<option value="80">80 (giá x16)</option>
										<option value="85">85 (giá x17)</option>
										<option value="90">90 (giá x18)</option>
										<option value="95">95 (giá x19)</option>
										<option value="100">100 (giá x20)</option>
									</select>
								</div>
							</div>
						: ''
					}
                </div>
				<div className="row mt-2">
					<div className="col-md-6">
						<div className="form-group">
							<label className="mt-2">Số ngày cần mua VIP:</label>
                            <select  onChange={this.onChange} value={sncmv} onKeyPress={this.onKeyPress}  id="sncmv" name="sncmv"  className="form-control">
                            	<option value="7">7</option>
                            	<option value="15">15</option>
                            	<option value="30">30</option>
                            	<option value="60">60</option>
                            	<option value="90">90</option>
								<option value="120">120</option>
								<option value="150">150</option>
								<option value="180">180</option>
                        	</select>
                        </div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<label className="mt-2">giá:</label>
							<h6 className="bold mb-0 font-14 user-discount py-2">{gtmtt} Coin/ 1 like/ 1 tháng</h6>
                        </div>
					</div>
				</div>
				<div className="form-group">
					<label className="col-form-label" htmlFor="">Ghi chú:</label>
					<div className="">
						<textarea style={{"width":"100%"}} rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				<div className="row mt-2">
				    <div className="col-12 bold">
				        <div className="card card-orange">
				        	<div className="card-body py-2">
				        		<h4 className="text-center mb-0 text-danger">Tổng tiền của gói vip sẽ = (Số lượng Like tối đa) x (Số ngày đăng ký VIP) x (Giá mặc định của hệ thống / 30) {lsct === "viplikethang_speed" ? 'x (Tỉ lệ của Số lượng bài viết / 1 ngày)' : ''}</h4>
			        		</div>
		        		</div>
				    </div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={lsct.toString()} tab="facebook_vip_likepro" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
										<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Bạn sẽ mua VIP <span className="green">{slllnct} like</span> trong <span className="green">{sncmv} ngày</span></span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Viplikethang: state.Viplikethang,
});
const mapDispatchToProps = { addVLT, updateVLT, getID, loadPriceVLT, add, getIdFbVip, updateFbVip, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);